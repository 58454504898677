import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { faCommentDots, faFloppyDisk, faNetworkWired, faTag, faXmark } from "@fortawesome/free-solid-svg-icons";

//APP
import { usePostObs } from "../../../../../../../../../../services/recomendacao/ativos/post";
import { localConfig } from "../../../../../../../../../../Configs/localConfig";
import { obterCodPorId } from "../../../../../../meta";
import { obterNomeObersavacao } from "../../meta";
import { useDeleteCompatibilidade } from "../../../../../../../../../../services/recomendacao/ativos/delete";

//COMPONENTS
import Input from "../../../../../../../../../../Components/CustomUi/input";
import TextArea from "../../../../../../../../../../Components/CustomUi/textarea";
import ButtonCustom from "../../../../../../../../../../Components/ButtonCustom";
import { Nivel } from "../nivel";
import { Inline } from "../../../../../../../../../../Components/CustomUi/inline";
import { DeleteButton } from "../../../../../../../../../../Components/CustomUi/deleteButton";
import { obterTipoPorId } from "../../../compatibilidades/meta";

//PROPS
interface Props {
    registros: any;
    onClose?: Function;
    atualizarLista?: Function;
    atualizarListaPrincipal?: Function;
    isVisualizar?: boolean;
    refetch?: Function;
    id_tipo_principal: number | null;
}

export const ModalEditar: React.FC<Props> = ({ registros, onClose, atualizarLista, isVisualizar, refetch, atualizarListaPrincipal }) => {

    //STATES
    const [obs, setObs] = useState<string>('');
    const [original_obs, setOriginal_obs] = useState<string>('');
    const [id_nivel_alerta, setId_nivel_alerta] = useState<number|null>(null);
    const [original_id_nivel_alerta, setOriginal_id_nivel_alerta] = useState<number|null>(null);
    const [openConfirm, setOpenConfirm] = useState<boolean>(false);

    //API
    const { carregando_salvar_obs, mutate_salvar_obs } =  usePostObs();
    const { carregando_deletar_compati, mutate_deletar_compati } = useDeleteCompatibilidade();

    function salvarEdicao() {
        mutate_salvar_obs({
            codConta: Number(localConfig().conta),
            id_tipo_principal: registros?.lancamento,
            id_item_principal: registros?.[obterCodPorId(registros?.lancamento)],
            cod_ativo: registros?.cod_ativo,
            cod_cultura: registros?.cod_cultura,
            cod_alerta: Number(id_nivel_alerta),
            todos_estadios: undefined,
            cod_estadios: registros?.cod_estadio ? [registros.cod_estadio] : undefined, 
            obs: obs,
            cod_observacao: registros?.cod_observacao,
            limparStates: () => onClose?.(),
            atualizarLista: () => atualizarLista?.(),
            atualizarListaPrincipal: () => atualizarListaPrincipal?.(),
        });
    }

    function deletarCompatibilidade() {
        mutate_deletar_compati({
            codConta: Number(localConfig().conta),
            cod: registros.cod,
            limparStates: () => {},
            atualizarLista: () => {refetch?.(); atualizarLista?.(); atualizarListaPrincipal?.()},
            closePopup: () => setOpenConfirm(false)
        })
    }

    useEffect(() => {
        setObs(registros?.observacao);
        setOriginal_obs(registros?.observacao);
        setId_nivel_alerta(registros?.cod_alerta);
        setOriginal_id_nivel_alerta(registros?.cod_alerta);
    }, [registros]);

    function desabilitarBtnSalvar() {
        if (carregando_salvar_obs) {
            return true;
        }
        if (isVisualizar) {
            return false;
        }
        return (original_obs === obs && original_id_nivel_alerta === id_nivel_alerta)
    }

    return (
        <div style={{display:'flex',flexDirection:'column',gap:15}}>
            
            <Inline gap={15}>
                <Input 
                    label="Tipo"
                    value={obterTipoPorId(registros?.lancamento)}
                    onChange={()=>{}}
                    placeholder="Selecione o tipo"
                    icon={faNetworkWired}
                    desabilitado
                />
                <Input 
                    label={obterTipoPorId(registros?.lancamento)}
                    value={registros?.lancamento_nome}
                    onChange={()=>{}}
                    placeholder="Selecione o tipo"
                    icon={faTag}
                    desabilitado
                />
            </Inline>
            
            <Input 
                label="Tipo de observação"
                value={obterNomeObersavacao(registros?.cod_observacao)}
                onChange={()=>{}}
                placeholder="Selecione o tipo"
                icon={faCommentDots}
                desabilitado
            />

            <Inline gap={15}>
                <Input 
                    label="Cultura"
                    value={registros.cultura_item_lancamento}
                    onChange={()=>{}}
                    placeholder="Cultura"
                    icon={faNetworkWired}
                    desabilitado
                />
                <Input 
                    label="Estádio"
                    value={registros?.item_lancamento_nome ?? 'Todos'}
                    onChange={()=>{}}
                    placeholder="Estadio"
                    icon={faTag}
                    desabilitado
                />
            </Inline>
            
            <Nivel
                id_nivel_alerta={id_nivel_alerta}
                setId_nivel_alerta={setId_nivel_alerta}
            />

            <TextArea
                label={`Observação da compatibilidade`}
                placeholder='Digite aqui a observação'
                value={obs}
                onChange={setObs}
                resize={false}
                height={70}
            />

            <Inline justifyContent={isVisualizar ? 'space-between' :"flex-end"} reverse={isVisualizar}>
                {isVisualizar ? 
                    <DeleteButton
                        open={openConfirm}
                        trigger={setOpenConfirm}
                        // onTrigger={()=>{setId_item_atual(item.cod);setNome_item_atual(item.item_lancamento_nome)}}
                        loading={carregando_deletar_compati}
                        message={`o item será excluido!`}
                        // deleteds={[registros?.item_lancamento_nome]}
                        onDelete={()=>{deletarCompatibilidade()}}
                        disabled={carregando_deletar_compati}
                        typeButtonTrigger="secondary"
                        textButtonTrigger="Excluir"
                    />
                :
                    <ButtonCustom
                        icon={<FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>}
                        onClick={()=>{onClose(false)}}
                        type="tertiary"
                        danger
                        disabled={carregando_salvar_obs || carregando_deletar_compati}
                    >
                        Cancelar
                    </ButtonCustom>
                }
                
                <ButtonCustom 
                    icon={<FontAwesomeIcon icon={faFloppyDisk}></FontAwesomeIcon>}
                    onClick={()=>{salvarEdicao()}}
                    loading={carregando_salvar_obs}
                    disabled={desabilitarBtnSalvar()}
                >
                    Salvar edição
                </ButtonCustom>
            </Inline>

        </div>
    )
}