import styled from "styled-components";

export const ContainerStart = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    box-sizing: border-box;
    padding: 5px;

    img.ContainerStart__logo {
        width: 110px;
    }

    div.ContainerStart__welcome {
        width: 100%;

        h2.CS__welcome__greeting {
            height: 25px;
            overflow: hidden;
            font-size: 19px;
            font-weight: 700;
            margin: 0px;
            color: var(--text-color);
            display: flex;

            span {
                color: var(--primary-color);
                text-transform: capitalize;
                margin-left: 5px;
                transform-origin: left;
                animation: entrance_username 1s forwards;

                @keyframes entrance_username {
                    0% {
                        transform: translateY(50px) scale(0.9) rotate(10deg);
                    }
                    65% {
                        transform: translateY(-5px) scale(0.95) rotate(0deg);
                    }
                    100% {
                        transform: translate(0) scale(1) rotate(0deg);
                    }
                }
            }
        }

        p.CS__welcome__instruction {
            font-size: 15px;
            margin: 0px;
            color: var(--text-color);
        }
    }
    
    div.ContainerStart__recents {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-top: thin solid var(--border-color);
        box-sizing: border-box;
        padding: 10px 0px;
        position: relative;

        div.CS__recents__history {
            width: 100%;
            max-height: 70px;
            overflow: hidden;
            display: flex;
            gap: 10px;
        }
    }

    div.ContainerStart__list {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-top: thin solid var(--border-color);
        box-sizing: border-box;
        padding: 10px 0px;
        transition: .4s;

        :hover {
            opacity: 1 !important;
        }

        div.ContainerStart__list__box {
            width: 100%;
            max-height: calc(100vh - 490px);
            overflow-y: auto;
            box-sizing: border-box;
            padding-right: 10px;

            a.CS__list__box__option {
                width: 100%;
                height: 60px;
                display: flex;
                align-items: center;
                gap: 10px;
                border-radius: 7px;
                transition: .4s;

                :hover {
                    background: rgba(149, 255, 197, 0.4);
                    padding-left: 7px;

                    div.CS__list__box__option__tag .CS__list__box__option__tag__arrow {
                        margin-left: 5px;
                        opacity: 1;
                    }
                    .CS__list__box__option__tag__tag {
                        opacity: 0;
                    }
                }

                div.CS__list__box__option__icon {
                    width: 30px;
                    min-width: 30px;
                    height: 30px;
                    min-height: 30px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: var(--primary-color);
                    color: #fff;
                    font-weight: 700;
                    font-size: 15px;
                    text-transform: uppercase;
                    overflow: hidden;
                    transition: .2s;

                    :hover {
                        transform: scale(1.2);
                    }

                    :is([data-client = true]) {
                        background: #83C05E;
                    }
                    
                    :is([data-logo = true]) {
                        background: transparent;
                        border: 2px solid var(--primary-color);
                    }

                    img.CS__list__box__option__icon__img {
                        width: 100%;
                    }
                }

                div.CS__list__box__option__info {
                    width: calc(100% - 90px);
                    display: flex;
                    flex-direction: column;
                    font-weight: 700;
                    font-size: 15px;
                    margin: 0;
                    padding: 0;
                    color: var(--text-color);
                    font-family: 'Poppins';
                    line-height: 17px;
                    text-transform: uppercase;

                    span {
                        font-weight: 500;
                        font-size: 13px;
                        color: var(--text-color-light);
                        text-transform: capitalize;
                    }
                }
                
                div.CS__list__box__option__tag {
                    width: 30px;
                    display: flex;
                    align-items: center;
                    position: relative;

                    .CS__list__box__option__tag__arrow {
                        color: var(--primary-color);
                        font-size: 16px;
                        margin-left: -20px;
                        opacity: 0;
                        transition: .2s;
                        transition-delay: .1s;
                    }

                    .CS__list__box__option__tag__tag {
                        position: absolute;
                        right: 0;
                        transition: .2s;
                    }
                }
            }

        }
    }
`;

export const TitleModal = styled.h2`
    font-weight: 600;
    font-size: 14px;
    color: var(--text-color-light);
`;

export const CardHistory = styled.a`
    border: thin solid var(--border-color, red);
    box-sizing: border-box;
    padding: 10px 12px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    gap: 10px;
    white-space: nowrap;

    :hover {
        background: var(--hover-color);
    }

    div.CardHistory__avatar {
        width: 27px;
        min-width: 27px;
        height: 27px;
        min-height: 27px;
        background-color: var(--primary-color);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: 700;
        overflow: hidden;
        transition: .2s;

        :hover {
            transform: scale(1.2);
        }

        :is([data-client = true]) {
            background: #83C05E;
        }
                    
        :is([data-logo = true]) {
            background: transparent;
            border: 2px solid var(--primary-color);
        }

        img.CardHistory__avatar__img {
            width: 100%;
        }
    }

    div.CardHistory__info {
        display: flex;
        flex-direction: column;
        font-weight: 700;
        text-transform: uppercase;
        color: var(--text-color);
        margin: 0px;
        /* white-space: nowrap; */

        span {
            color: var(--text-color-light);
            font-weight: 500;
            text-transform: capitalize;
            margin-top: -5px;
        }
    }
`;