/* eslint-disable react/require-default-props */
import Icon, { SearchOutlined } from '@ant-design/icons'
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Col, Input, Layout, Row, Space } from 'antd'
import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react'
import { useAccess } from '../../context/useAccess'
import { ItensPerUserLogged } from '../../services/login/permissaoUsuarioLoado'

export interface TypesViewPage {
  newHide?: boolean
  disableNew?: boolean
  title: string
  search?: boolean
  btnClick?: () => void
  inputChange?: (data: ChangeEvent) => void
  btnsExta?: ReactNode
  content?: ReactNode
  rotina?: number
  onPermission?: (data: ItensPerUserLogged) => void
  hidden?: boolean
  rotinaExtra?: number[]
  onPermissionsExtra?: (data: ItensPerUserLogged[]) => void
  nameButton?: string
  hiddeTitle?: boolean;
  cardBodyStyle?: React.CSSProperties;
  cardMainStyle?: React.CSSProperties;
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between'
}

const { Content } = Layout

const ViewPage: React.FC<TypesViewPage> = (props) => {
  const { permission } = useAccess()
  const {
    newHide = false,
    disableNew = false,
    search = false,
    title,
    btnClick,
    inputChange,
    children,
    btnsExta = null,
    rotina = 0,
    content = <></>,
    onPermission,
    hidden = false,
    rotinaExtra = [],
    onPermissionsExtra,
    nameButton = 'Novo',
    hiddeTitle = false,
    cardBodyStyle,
    cardMainStyle,
    justifyContent,
  } = props

  const [dataPermissions, setDataPermissions] = useState<ItensPerUserLogged>({
    acesso: 0,
    alteracao: 0,
    cod: 0,
    cod_perfil: 0,
    cod_rotina: 0,
    exclusao: 0,
    inclusao: 0,
    perfil_nome: '',
    rotina_nome: '',
  })

  useEffect(() => {
    let dados

    if (rotina > 0 && permission) {
      dados = permission.find((item) => item.cod_rotina === rotina)
      setDataPermissions(
        dados || {
          acesso: 0,
          alteracao: 0,
          cod: 0,
          cod_perfil: 0,
          cod_rotina: 0,
          exclusao: 0,
          inclusao: 0,
          perfil_nome: '',
          rotina_nome: '',
        }
      )
      onPermission?.(dados)

      if (rotinaExtra.length > 0) {
        const rotinas = rotinaExtra.map((cod) => {
          return permission.find((item) => item.cod_rotina === cod)
        })
        onPermissionsExtra?.(rotinas)
      }
    }
  }, [])
  return (
    <Content hidden={hidden}>
      <Col span={24} style={{ overflow: 'hidden', height: '89vh'}}>
        {!hiddeTitle &&(
          <Card
          size='small'
          title={title}
          bodyStyle={
            newHide && !btnsExta
              ? { display: 'none', overflow: 'auto', height: '100%' }
              : { display: 'block', overflow: 'auto', height: '100%' }
          }
        >
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Space style={{ width: '100%' }}>
                {!newHide ? (
                  <Button
                    disabled={disableNew}
                    hidden={
                      dataPermissions?.inclusao === 0 &&
                      localStorage.getItem('tipo-acesso') !== 'admin'
                    }
                    icon={<Icon component={() => <FaIcon icon='plus' />} />}
                    onClick={() => btnClick?.()}
                    type='primary'
                  >
                    {nameButton}
                  </Button>
                ) : null}
                {btnsExta}
                {search ? (
                  <Input
                    placeholder='Pesquisar'
                    style={{ minWidth: '150px' }}
                    onChange={(data) => inputChange(data)}
                    allowClear
                    prefix={<SearchOutlined />}
                  />
                ) : null}
              </Space>
            </Col>
          </Row>
        </Card>
        )}
        
        <Card
          style={{
            overflow: 'hidden',
            height: newHide === true && btnsExta === null ? '85vh' : '83vh',
            ...cardMainStyle
          }}
          bodyStyle={cardBodyStyle}
        >
          {children}
        </Card>
        {content}
      </Col>
    </Content>
  )
}

export default ViewPage