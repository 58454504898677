
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Button, Popconfirm } from "antd";

//COMPONENTS
import ButtonCustom from "../../../../../../../ButtonCustom";
import { Inline } from "../../../../../../../CustomUi/inline";
import React from "react";
import { gerarKey } from "../../../../../../../../utils";
import { DeleteButton } from "../../../../../../../CustomUi/deleteButton";

//TYPES
type MarkerType = {
    cod?: number;
    nome?: string;
    cod_talhao: number;
    latitude: number;
    longitude: number;
}

//PROPS
interface Props {
    tipoMonitoramento: number;
    markerDefault: MarkerType[];
    handleChangePropriedade: Function;
    handleChangeTalhao: Function;
    handleChangeAgrupamento: Function;
    handleChangeSubarea: Function;
    cod_propriedade_atual: number;
    cod_talhao_atual: number;
    cod_agrupamento: number;
    cod_subarea_atual: number | null;
    mutate_remover_pontos: Function;
    carregando_remover_pontos: boolean;
    atualizarListaPropriedades: Function;
}

export const ExcluirTodosOsPontos: React.FC<Props> = ({ tipoMonitoramento, markerDefault, handleChangePropriedade, handleChangeTalhao, 
    cod_propriedade_atual, cod_talhao_atual, mutate_remover_pontos, carregando_remover_pontos, handleChangeAgrupamento, cod_agrupamento, 
    cod_subarea_atual, handleChangeSubarea, atualizarListaPropriedades
 }) => {

    //STATES
    const [popup_confirm, setPopup_confirm] = useState<boolean>(false);

    function deletarTodosOsPontos() {
        let obter_cod_pontos = markerDefault.map(item => item.cod);

        function refetchPontosPorTipo() {
            switch (tipoMonitoramento) {
                case 1:
                    handleChangePropriedade(cod_propriedade_atual);
                    break;
                case 2:
                    handleChangeAgrupamento(cod_agrupamento);
                    break;
                case 3:
                    handleChangeTalhao({ value: cod_talhao_atual });
                    break;
                case 4:
                    handleChangeSubarea(cod_subarea_atual);
                    break;
                default:
                    handleChangePropriedade(cod_propriedade_atual);
                    break;
            }
        }

        mutate_remover_pontos({
            pontos_remover: obter_cod_pontos,
            refetchPontos: ()=>{refetchPontosPorTipo();atualizarListaPropriedades();setPopup_confirm(false)}
        })
    }

    return (
        <DeleteButton 
            title="Tem certeza que deseja deletar todos os pontos?"
            open={popup_confirm}
            trigger={setPopup_confirm}
            // onTrigger={()=>{setId_item_atual(item.cod);setNome_item_atual(item.item_lancamento_nome)}}
            loading={carregando_remover_pontos}
            message={<span>Todos os pontos <b>{tipoMonitoramento === 1 ? 'da propriedade' : 'do talhão'}</b> serão permanentemente deletados!
                        <br /> <i>*É recomendado fazer um backup dos pontos atuais <b>(exportar)</b> antes de excluir</i></span>}
            // deleteds={[nome_item_atual]}
            onDelete={()=>{deletarTodosOsPontos()}}
            disabled={carregando_remover_pontos}
            typeButtonTrigger="primary"
            textButtonTrigger="Excluir todos"
        />
        // <Popconfirm
        //     placement="topRight"
        //     title={'Tem certeza que deseja deletar todos os pontos?'}
        //     open={popup_confirm}
        //     description={
        //         <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
        //             <span>Todos os pontos <b><i></>{tipoMonitoramento === 1 ? 'da propriedade' : 'do talhão'}</i></b> serão permanentemente deletados!
        //                 <br /> <i>*É recomendado fazer um backup dos pontos atuais <b>(exportar)</b> antes de excluir</i></span>

        //             <Inline justifyContent="flex-end">

        //                 <ButtonCustom
        //                     onClick={() => { setPopup_confirm(false) }}
        //                     type="tertiary"
        //                     icon={<FontAwesomeIcon icon={faXmark} />}
        //                     disabled={carregando_remover_pontos}
        //                 >
        //                     Cancelar
        //                 </ButtonCustom>

        //                 <ButtonCustom
        //                     onClick={() => { deletarTodosOsPontos() }}
        //                     icon={<FontAwesomeIcon icon={faTrash} />}
        //                     danger
        //                     loading={carregando_remover_pontos}
        //                     disabled={carregando_remover_pontos}
        //                 >
        //                     Excluir todos
        //                 </ButtonCustom>

        //             </Inline>
        //         </div>}
        //     okButtonProps={{ style: { display: 'none' } }}
        //     cancelButtonProps={{ style: { display: 'none' } }}
        // >
        //     <Button style={{ background: 'transparent', padding: 0, margin: 0, border: 'none', boxShadow: 'none' }}>
        //         <ButtonCustom
        //             onClick={() => { setPopup_confirm(true) }}
        //             icon={<FontAwesomeIcon icon={faTrash} />}
        //             danger
        //             disabled={markerDefault?.length === 0 || carregando_remover_pontos}
        //         >
        //             Excluir todos
        //         </ButtonCustom>
        //     </Button>
        // </Popconfirm>
    )
};