import React from "react";

//APP
import { removerEngrenagem } from "../../meta";
import { TypeListaEngrenagem } from "../../../../../../../..";
import { useDeleteEngrenagem } from "../../../../../../../../../../../services/Patrimonio/DELETE";

//COMPONENTS
import { DeleteButton } from "../../../../../../../../../../../Components/CustomUi/deleteButton";

//PROPS
interface Props {
    cod_item: number;
    open_deletar: boolean;
    setOpen_deletar: React.Dispatch<React.SetStateAction<boolean>>;
    item_selecionado: number,
    setItem_selecionado: React.Dispatch<React.SetStateAction<number>>;
    lista_engrenagens_completa: TypeListaEngrenagem[],
    lista_engrenagens: TypeListaEngrenagem[],
    setLista_engrenagens: React.Dispatch<React.SetStateAction<TypeListaEngrenagem[]>>,
    quantidade_atual: string;
    is_new: boolean;
}

export const PopOverDeletar: React.FC<Props> = ({ cod_item, open_deletar, setOpen_deletar, is_new,
    item_selecionado, setItem_selecionado, lista_engrenagens_completa, setLista_engrenagens, quantidade_atual }) => {

    const { carregando_deletar_engrenagem, mudateDeletarEngrenagem } = useDeleteEngrenagem();

    return (
        <DeleteButton 
            title="Deletar"
            open={open_deletar && (cod_item === item_selecionado)}
            trigger={setOpen_deletar}
            onTrigger={()=>{setItem_selecionado(cod_item)}}
            loading={carregando_deletar_engrenagem}
            message={<span>Deletar engrenagem de <b>{quantidade_atual}</b> dentes?</span>}
            disabled={carregando_deletar_engrenagem}
            destroyTooltipOnHide
            onDelete={is_new ? () => {
                removerEngrenagem(
                    lista_engrenagens_completa,
                    setLista_engrenagens,
                    cod_item
                );
                setOpen_deletar(false);
            }
            : ()=> {
                mudateDeletarEngrenagem({
                    cod_engrenagem: cod_item,
                    removerDaLista: removerEngrenagem,
                    lista_engrenagens: lista_engrenagens_completa,
                    setLista_engrenagens: setLista_engrenagens,
                    setOpen_deletar: setOpen_deletar
                });
            }}
        />
    )
}