import { api } from '../../../api';
import { message } from 'antd';
import { UseMutationResult, useMutation } from 'react-query';


function obterTipoPrincipal(id_tipo_principal: number, id_item_principal: number, id_estadio_principal: number | number[], cultura?: number) {
    switch (id_tipo_principal) {
        case 1:
            return !!cultura ? {cod_ativo: id_item_principal, cultura} : {cod_ativo: id_item_principal}
        case 2:
            return !!cultura ? {cod_produto: id_item_principal, cultura: cultura} : {cod_produto: id_item_principal}
        case 3:
            return {grupo_quimico: id_item_principal}
        case 4:
            return {classe_agronomica: id_item_principal}
        case 5:
            return {formulacao: id_item_principal}
        case 6:
            return {cultura: id_item_principal, estadio_cultura: id_estadio_principal}
        case 7:
            return {cultura: id_item_principal, estadio_cultura: id_estadio_principal, diego: 'vasco'}
        case 8:
            return {tecnologia: id_item_principal}
        case 9:
            return {grupo: id_item_principal}
        default:
            return {cod_ativo: null}
    }
}

function obterTipoPrincipalSemEstadio(id_tipo_principal: number, id_item_principal: number) {
    switch (id_tipo_principal) {
        case 1:
            return {cod_ativo: id_item_principal}
        case 2:
            return {cod_produto: id_item_principal}
        case 3:
            return {grupo_quimico: id_item_principal}
        case 4:
            return {classe_agronomica: id_item_principal}
        case 5:
            return {formulacao: id_item_principal}
        case 6:
            return {cultura: id_item_principal}
        case 7:
            return {cultura: id_item_principal}
        case 8:
            return {tecnologia: id_item_principal}
        case 9:
            return {grupo: id_item_principal}
        default:
            return {cod_ativo: null}
    }
}

//=================================================================================>
// CADASTRAR COMPATIBILIDADE
//<=================================================================================

interface Params {
    cod?: number,
    codConta: number;
    id_tipo_principal: number;
    id_item_principal: number;
    id_estadio_principal: number | number[];
    cod_ativo: number;
    tipo_compatibilidade: number;
    cod_compatibilidade: number;
    cod_estadios: number[];
    obs: string;
    limparStates: () => void;
    atualizarLista: Function;
    atualizarListaPrincipal: Function;
}

interface Response {
    itens?: any[],
    links?: any[],
    memory?: string,
    memoryPeak?: string,
    mensagem?: string,
    pagina?: number,
    quantidadePorPagina?: number,
    timestamp?: number,
    total?: number,
}

export const usePostCompatibilidade = (): {
    carregando_salvar_compati: boolean;
    mutate_salvar_compati: (params: Params) => void;
} => {

    
    const mutation: UseMutationResult<Response, Error, Params> = useMutation(
        async (params: Params) => {
            const { codConta, id_tipo_principal, id_item_principal, tipo_compatibilidade, cod_compatibilidade, cod_estadios,
                 obs, atualizarLista, limparStates, cod, atualizarListaPrincipal, id_estadio_principal } = params;

            message.loading({ content: 'Salvando...', key: 'loading' });

            function bodyReq() {
                switch (tipo_compatibilidade) {
                    case 1: //Ingrediente ativo
                        return { ...obterTipoPrincipal(id_tipo_principal, id_item_principal, id_estadio_principal), cod_ingrediente_ativo: cod_compatibilidade, cod_tipo: 1, observacao: obs };
                    case 2: //insumo
                        return { ...obterTipoPrincipal(id_tipo_principal, id_item_principal, id_estadio_principal), cod_insumo: cod_compatibilidade, cod_tipo: 1, observacao: obs };
                    case 3: //Grupo químico
                        return { ...obterTipoPrincipal(id_tipo_principal, id_item_principal, id_estadio_principal), cod_grupo_quimico: cod_compatibilidade, cod_tipo: 1, observacao: obs, cod: cod };
                    case 4: //classe agronomica
                        return { ...obterTipoPrincipal(id_tipo_principal, id_item_principal, id_estadio_principal), cod_classe_agronomica: cod_compatibilidade, cod_tipo: 1, observacao: obs, cod: cod };
                    case 5: //formulação
                        return { ...obterTipoPrincipal(id_tipo_principal, id_item_principal, id_estadio_principal), cod_formulacao: cod_compatibilidade, cod_tipo: 1, observacao: obs, cod: cod };
                    case 6: //Cultura
                        return cod_estadios?.length > 0 
                            ? { ...obterTipoPrincipal(id_tipo_principal, id_item_principal, id_estadio_principal), cod_tipo: 1, observacao: obs, cod_cultura: cod_compatibilidade, cod_estadio: cod_estadios }
                            : { ...obterTipoPrincipal(id_tipo_principal, id_item_principal, id_estadio_principal), cod_tipo: 1, observacao: obs, cod_cultura: cod_compatibilidade };
                    case 7: //Estádio
                        return { ...obterTipoPrincipal(id_tipo_principal, id_item_principal, id_estadio_principal), cod_tipo: 1, observacao: obs, cod_cultura: cod_compatibilidade, cod_estadio: cod_estadios }
                    case 8: //Tecnologia
                        return { ...obterTipoPrincipal(id_tipo_principal, id_item_principal, id_estadio_principal), cod_tipo: 1, observacao: obs, cod_tecnologia: cod_compatibilidade };
                    case 9: //Grupo
                        return { ...obterTipoPrincipal(id_tipo_principal, id_item_principal, id_estadio_principal), cod_tipo: 1, observacao: obs, cod_grupo: cod_compatibilidade };
                    default:
                        return {};
                }
            }

            return api.post(`/recomendacao/compatibilidade?codConta=${codConta}&embed=composicao`, bodyReq() )
                .then((response: any) => {
                    message.success({ content: 'Compatibilidade salva com sucesso!', key: 'loading' });
                    limparStates();
                    atualizarLista();
                    atualizarListaPrincipal();
                    return response;
                })
                .catch((error: any) => {
                    message.error({ content: 'Falha ao salvar compatibilidade', key: 'loading', duration: 7 });
                    message.error({ content: (error?.response?.data?.error) ?? 'Falha ao salvar compatibilidade', duration: 10 });
                    throw error;
                });
        }
    );

    return { 
        carregando_salvar_compati: mutation.isLoading, 
        mutate_salvar_compati: mutation.mutate 
    };
};

//=================================================================================>
// CADASTRAR INCOMPATIBILIDADE
//<=================================================================================

interface ParamsIncompatibilidade {
    cod?: number;
    codConta: number;
    cod_ativo: number;
    id_tipo_principal: number;
    id_item_principal: number;
    id_estadio_principal: number | number[];
    tipo_compatibilidade: number;
    cod_compatibilidade: number;
    cod_estadio_cultura?: number;
    cod_estadios: number[];
    cod_alerta: number;
    obs: string;
    limparStates: () => void;
    atualizarLista: Function;
    atualizarListaPrincipal: Function;
}

interface Response {
    itens?: any[],
    links?: any[],
    memory?: string,
    memoryPeak?: string,
    mensagem?: string,
    pagina?: number,
    quantidadePorPagina?: number,
    timestamp?: number,
    total?: number
}

export const usePostIncompatibilidade = (): {
    carregando_salvar_incompati: boolean;
    mutate_salvar_incompati: (params: ParamsIncompatibilidade) => void;
} => {
    const mutation: UseMutationResult<Response, Error, ParamsIncompatibilidade> = useMutation(
        async (params: ParamsIncompatibilidade) => {
            const { codConta, tipo_compatibilidade, cod_compatibilidade, id_tipo_principal, id_item_principal,
                cod_estadios, obs, atualizarLista, atualizarListaPrincipal, limparStates, cod_alerta, id_estadio_principal } = params;

            message.loading({ content: 'Salvando...', key: 'loading' });  

            function bodyReq() {
                switch (tipo_compatibilidade) {
                    case 1: //Ingrediente ativo
                        return { ...obterTipoPrincipal(id_tipo_principal, id_item_principal, id_estadio_principal), cod_tipo: 2, observacao: obs, cod_ingrediente_ativo: cod_compatibilidade, cod_alerta: cod_alerta };
                    case 2: //insumo
                        return { ...obterTipoPrincipal(id_tipo_principal, id_item_principal, id_estadio_principal), cod_tipo: 2, observacao: obs, cod_insumo: cod_compatibilidade, cod_alerta: cod_alerta };
                    case 3: //Grupo químico
                        return { ...obterTipoPrincipal(id_tipo_principal, id_item_principal, id_estadio_principal), cod_tipo: 2, observacao: obs, cod_grupo_quimico: cod_compatibilidade, cod_alerta: cod_alerta };
                    case 4: //classe agronomica
                        return { ...obterTipoPrincipal(id_tipo_principal, id_item_principal, id_estadio_principal), cod_tipo: 2, observacao: obs, cod_classe_agronomica: cod_compatibilidade, cod_alerta: cod_alerta };
                    case 5: //formulação
                        return { ...obterTipoPrincipal(id_tipo_principal, id_item_principal, id_estadio_principal), cod_tipo: 2, observacao: obs, cod_formulacao: cod_compatibilidade, cod_alerta: cod_alerta };
                    case 6: //Cultura
                        return cod_estadios?.length > 0 
                            ? { ...obterTipoPrincipal(id_tipo_principal, id_item_principal, id_estadio_principal), cod_tipo: 2, observacao: obs, cod_cultura: cod_compatibilidade, cod_estadio: cod_estadios, cod_alerta: cod_alerta }
                            : { ...obterTipoPrincipal(id_tipo_principal, id_item_principal, id_estadio_principal), cod_tipo: 2, observacao: obs, cod_cultura: cod_compatibilidade, cod_alerta: cod_alerta };
                    case 7: //Estádio
                        return { ...obterTipoPrincipal(id_tipo_principal, id_item_principal, id_estadio_principal), cod_tipo: 2, observacao: obs, cod_cultura: cod_compatibilidade, cod_estadio: cod_estadios, cod_alerta: cod_alerta }
                    case 8: //Tecnologia
                        return { ...obterTipoPrincipal(id_tipo_principal, id_item_principal, id_estadio_principal), cod_tipo: 2, observacao: obs, cod_tecnologia: cod_compatibilidade, cod_alerta: cod_alerta };
                    case 9: //Grupo
                        return { ...obterTipoPrincipal(id_tipo_principal, id_item_principal, id_estadio_principal), cod_tipo: 2, observacao: obs, cod_grupo: cod_compatibilidade, cod_alerta: cod_alerta };
                    default:
                        return {};
                }
            }

            return api.post(`/recomendacao/compatibilidade?codConta=${codConta}&embed=composicao`, bodyReq())
                .then((response: any) => {
                    message.success({ content: 'Incompatibilidade salva com sucesso!', key: 'loading' });
                    limparStates();
                    atualizarLista();
                    atualizarListaPrincipal();
                    return response;
                })
                .catch((error: any) => {
                    message.error({ content: 'Falha ao salvar incompatibilidade', duration: 7 });
                    message.error({ content: (error?.response?.data?.error) ?? 'Falha ao salvar incompatibilidade', duration: 10 });
                    throw error;
                });
        }
    );

    return { 
        carregando_salvar_incompati: mutation.isLoading, 
        mutate_salvar_incompati: mutation.mutate 
    };
};

//=================================================================================>
// CADASTRAR PLANT BACK
//<=================================================================================

interface ParamsPlantBack {
    cod?: number,
    id_tipo_principal: number;
    id_item_principal: number;
    id_estadio_principal: number | number[];
    codConta: number;
    cod_ativo: number;
    cod_cultura: number;
    plant_back: number;
    obs: string;
    limparStates: () => void;
    atualizarLista: Function;
    atualizarListaPrincipal: Function;
}

interface Response {
    itens?: any[],
    links?: any[],
    memory?: string,
    memoryPeak?: string,
    mensagem?: string,
    pagina?: number,
    quantidadePorPagina?: number,
    timestamp?: number,
    total?: number
}

export const usePostPlantBack = (): {
    carregando_salvar_plant: boolean;
    mutate_salvar_plant: (params: ParamsPlantBack) => void;
} => {
    const mutation: UseMutationResult<Response, Error, ParamsPlantBack> = useMutation(
        async (params: ParamsPlantBack) => {
            const { codConta, obs, cod_cultura, atualizarLista, atualizarListaPrincipal, 
                limparStates, plant_back, id_tipo_principal, id_item_principal, id_estadio_principal } = params;

            message.loading({ content: 'Salvando...', key: 'loading_pb' });

            return api.post(`/recomendacao/compatibilidade?codConta=${codConta}&embed=composicao`, { 
                ...obterTipoPrincipal(id_tipo_principal, id_item_principal, id_estadio_principal), 
                cod_tipo: 4, 
                observacao: obs, 
                plant_back: plant_back,
                cod_cultura: cod_cultura 
            })
                .then((response: any) => {
                    message.success({ content: 'Plantback salva com sucesso!', key: 'loading_pb' });
                    limparStates();
                    atualizarLista();
                    atualizarListaPrincipal();
                    return response;
                })
                .catch((error: any) => {
                    message.error({ content: 'Falha ao salvar plantback', key: 'loading_pb', duration: 5 });
                    message.error({ content: (error?.response?.data?.error) ?? 'Falha ao salvar plantback', duration: 10 });
                    throw error;
                });
        }
    );

    return { 
        carregando_salvar_plant: mutation.isLoading, 
        mutate_salvar_plant: mutation.mutate 
    };
};

//=================================================================================>
// CADASTRAR DOSES
//<=================================================================================

interface ParamsDose {
    codConta: number;
    cod?: number,
    id_tipo_principal: number;
    id_item_principal: number;
    cod_ativo: number;
    cod_cultura: number;
    todos_estadios: boolean,
    cod_estadios: number[];
    dose_minima: number,
    dose_maxima: number,
    obs: string;
    limparStates: () => void;
    atualizarLista: Function;
    atualizarListaPrincipal: Function;
}

interface Response {
    itens?: any[],
    links?: any[],
    memory?: string,
    memoryPeak?: string,
    mensagem?: string,
    pagina?: number,
    quantidadePorPagina?: number,
    timestamp?: number,
    total?: number
}

export const usePostDose = (): {
    carregando_salvar_dose: boolean;
    mutate_salvar_dose: (params: ParamsDose) => void;
} => {
    const mutation: UseMutationResult<Response, Error, ParamsDose> = useMutation(
        async (params: ParamsDose) => {
            const { codConta, obs, cod_cultura, atualizarLista, atualizarListaPrincipal, 
                limparStates, cod_estadios, dose_maxima, dose_minima, todos_estadios, 
                id_tipo_principal, id_item_principal } = params;

            message.loading({ content: 'Salvando...', key: 'loading' });

            function bodyReq() {
                if (todos_estadios) {
                    return { 
                        ...obterTipoPrincipalSemEstadio(id_tipo_principal, id_item_principal),  cod_tipo: 5, observacao: obs, dose_minima: dose_minima, dose_maxima: dose_maxima, cod_cultura: cod_cultura 
                    }
                }
                return { 
                    ...obterTipoPrincipalSemEstadio(id_tipo_principal, id_item_principal), 
                    cod_tipo: 5, 
                    observacao: obs, 
                    dose_minima: dose_minima,
                    dose_maxima: dose_maxima,
                    cod_estadio: cod_estadios,
                    cod_cultura: cod_cultura 
                }
            }

            return api.post(`/recomendacao/compatibilidade?codConta=${codConta}&embed=composicao`, bodyReq())
                .then((response: any) => {
                    message.success({ content: 'Dose salva com sucesso!', key: 'loading' });
                    limparStates();
                    atualizarLista();
                    atualizarListaPrincipal();
                    return response;
                })
                .catch((error: any) => {
                    message.error({ content: 'Falha ao salvar dose', key: 'loading', duration: 5 });
                    if (!(String(error?.response?.data?.error)).startsWith('SQLSTATE')) {
                        message.error({ content: (error?.response?.data?.error) ?? 'Falha ao salvar doses', duration: 10 });
                    }
                    
                    throw error;
                });
        }
    );

    return { 
        carregando_salvar_dose: mutation.isLoading, 
        mutate_salvar_dose: mutation.mutate 
    };
};

//=================================================================================>
// CADASTRAR OBSERVAÇÕES
//<=================================================================================

interface ParamsObs {
    codConta: number;
    cod_ativo: number;
    id_tipo_principal: number;
    id_item_principal: number;
    cod_cultura: number;
    todos_estadios: boolean,
    cod_estadios: number[];
    cod_alerta: number,
    cod_observacao: number,
    obs: string;
    limparStates: () => void;
    atualizarLista: Function;
    atualizarListaPrincipal: Function;
}

interface Response {
    itens?: any[],
    links?: any[],
    memory?: string,
    memoryPeak?: string,
    mensagem?: string,
    pagina?: number,
    quantidadePorPagina?: number,
    timestamp?: number,
    total?: number
}

export const usePostObs = (): {
    carregando_salvar_obs: boolean;
    mutate_salvar_obs: (params: ParamsObs) => void;
} => {
    const mutation: UseMutationResult<Response, Error, ParamsObs> = useMutation(
        async (params: ParamsObs) => {
            const { codConta, obs, cod_cultura, atualizarLista, atualizarListaPrincipal, 
                cod_observacao, limparStates, cod_estadios, cod_alerta, todos_estadios, 
                id_tipo_principal, id_item_principal } = params;

            message.loading({ content: 'Salvando...', key: 'loading' });

            function bodyReq() {
                if (todos_estadios) {
                    return { 
                        ...obterTipoPrincipalSemEstadio(id_tipo_principal, id_item_principal), cod_observacao: cod_observacao, cod_tipo: 3, observacao: obs, cod_alerta: cod_alerta, cod_cultura: cod_cultura 
                    }
                }
                return { 
                    ...obterTipoPrincipalSemEstadio(id_tipo_principal, id_item_principal), 
                    cod_tipo: 3, 
                    cod_observacao: cod_observacao,
                    observacao: obs, 
                    cod_alerta: cod_alerta,
                    cod_estadio: cod_estadios,
                    cod_cultura: cod_cultura 
                }
            }

            return api.post(`/recomendacao/compatibilidade?codConta=${codConta}&embed=composicao`, bodyReq())
                .then((response: any) => {
                    message.success({ content: 'Observação salva com sucesso!', key: 'loading' });
                    limparStates();
                    atualizarLista();
                    atualizarListaPrincipal();
                    return response;
                })
                .catch((error: any) => {   
                    message.error({ content: 'Falha ao salvar observação', key: 'loading', duration: 5 });
                    message.error({ content: (error?.response?.data?.error?.[0]?.msg?.[0]) ?? 'Falha ao salvar observação', duration: 10 });
   
                    if (!(error?.response?.data?.error ?? '').startWith('SQLSTATE')) {
                        message.error({ content: (error?.response?.data?.error) ?? 'Falha ao salvar observação', duration: 10 });
                    }
                    
                    throw error;
                });
        }
    );

    return { 
        carregando_salvar_obs: mutation.isLoading, 
        mutate_salvar_obs: mutation.mutate 
    };
};