import styled from 'styled-components'

interface BackgroundProps {
  background: string
}

export const ContainerCards = styled.div<BackgroundProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 20px;
  background-size: cover; /* Faz a imagem cobrir todo o container */
  background-position: center; /* Centraliza a imagem */
  background-repeat: no-repeat; /* Evita repetição */

  .area__container__cards {
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 15px;
  }
`

export const AreaCard = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;

  .card__left {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    border: thin solid var(--border-color);
    border-radius: 5px;
    background-color: #ffff;
  }

  /* .card__right {
    width: 92%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    border: thin solid #b7eb8f;
    border-radius: 5px;
    background-color: #f6ffed;
    color: #389e0d;
  } */

  .header__card {
    width: 100%;

    h3 {
      text-transform: capitalize;
      font-size: 12px;
      margin: 0;
      padding: 0;
    }
  }

  .img__mensagem {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: start;
    justify-content: flex-start;

    .image-container {
      position: relative;
      width: 130px;
      height: 100%;
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;
    }


    .image-container img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      transition: transform 0.3s ease;
      
      border-radius: 5px;
    }

  
    .image-container:hover img {
      transform: scale(1.1);
    }

    .image-container .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    .image-container .overlay i {
      color: white;
      font-size: 24px;
    }

    .image-container:hover .overlay {
      opacity: 1;
    }
  }
`
