import styled from 'styled-components'

export const Overlay = styled.section`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2000;
  display: flex;
  flex-direction: column;
`

export const MediaContainer = styled.div`
  width: 100%;
  height: calc(100vh - 110px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 15px;

  :is([data-single='true']) {
    justify-content: center;
    height: 100vh;
  }

  .MediaContainer__slide {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    color: #fff;
    font-size: 16px;
  }

  .MediaContrainer__media {
    width: 100%;
    max-width: 800px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    .MC__media__img {
      width: 90%;
      z-index: 2;
      border-radius: 5px;
    }
    .MC__media__img_ambient {
      width: 92%;
      position: absolute;
      filter: blur(5px);
      z-index: 1;
    }
  }

  :is([data-single='true']) {
    button.MediaContainer__navigator {
      display: none;
    }
  }

  button.MediaContainer__navigator {
    font-size: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: 0.2s;
    border-radius: 3px;
    min-height: 50px;
    max-height: 100%;

    :hover {
      .MC__navigator__arrow {
        transition: 0.2s;
        transform: scale(1.2);
      }
    }

    .MC__navigator__arrow {
      transition: 0.2s;
      background: transparent;
      color: #fff;
      padding: 20px;
      border-radius: 7px;
      font-size: 40px;
    }
  }
`

export const CarrouselContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 110px;

  :is([data-single='true']) {
    display: none;
  }

  div.CarrouselContainer__carrousel {
    background: #fff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 7px;
    padding: 10px;

    button.CC__carrousel__item {
      width: 70px;
      height: 70px;
      border-radius: 5px;
      overflow: hidden;
      border: none;
      background: red;
      display: flex;
      padding: 0;
      cursor: pointer;
      overflow: hidden;

      :is([data-active='true']) {
        border: 5px solid var(--primary-color);
      }

      img.CC__carrousel__item__thumbnail {
        width: 100%;
        height: 100%;
        transition: 0.3s;
      }

      :hover {
        img.CC__carrousel__item__thumbnail {
          transform: scale(1.2);
        }
      }
    }
  }
`
