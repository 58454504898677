import { Collapse } from 'antd'
import React, { useState } from 'react'
import { Box } from '../../../../../Components/CustomUi/box'
import { HeaderDrawer } from '../../../../../Components/CustomUi/headerGaveta'
import { BodyDrawer } from './styled'
import { TitleH4 } from '../../../../../Components/CustomUi/titleH4'
import Input from '../../../../../Components/CustomUi/input'
import TextArea from '../../../../../Components/CustomUi/textarea'
import ButtonCustom from '../../../../../Components/ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFloppyDisk,
  faGear,
  faGlobe,
  faLock,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { removerAcentos } from '../../../../../utils'
import { deleteRotinaEdit, postRotina, putRotinaEdit } from '../../../../../services/rotina'
import { Inline } from '../../../../../Components/CustomUi/inline'
import ModalCadastrarRotina from '../modal-cadastro-rotinas'
import Checkbox from '../../../../../Components/CustomUi/checkbox'
import { Select } from '../../../../../Components/CustomUi/Select'
import { tipoUso } from '../../meta'
import { Notification } from '../../../../../Components/notification'

interface TypeRotinas {
  dados: any[]
  dadosModulos: any[]
  cod_modulo: number
  nome_modulo: string
  atualizar?: () => void
  fechar?: () => void
}

const AccordionRotinas: React.FC<TypeRotinas> = ({
  dados,
  cod_modulo,
  nome_modulo,
  atualizar,
  fechar,
  dadosModulos,
}) => {
  const { Panel } = Collapse

  const [titulo, setTitulo] = useState('')
  const [descricao, setDescricao] = useState('')
  const [buscar, setBuscar] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingDeletar, setLoadingDeletar] = useState(false)
  const [abrir, setAbrir] = useState(false)

  //SELECT
  const [nomeTipoUso, setNomeTipoUso] = useState('')
  const [codTipoUso, setcodTipoUso] = useState<number>()
  const [openSelectTipo, setOpenSelectTipo] = useState(false)
  const [nomeModulo, setNomeModulo] = useState('')
  const [codModulo, setcodModulo] = useState<number>()
  const [openSelectModulo, setOpenSelectModulo] = useState(false)

  //CHECK
  const [checkAcesso, setCheckAcesso] = useState(false)
  const [checkAlteracao, setCheckAlteracao] = useState(false)
  const [checkInclusao, setCheckInclusao] = useState(false)
  const [checkExclusao, setCheckExclusao] = useState(false)

  const rotinasModulos = dados.filter((item) => item.cod_modulo === cod_modulo)

  function handleSelectRotina(cod) {
    const filterRotina = rotinasModulos.find((item) => item.cod === parseFloat(cod[0]))
    setTitulo(filterRotina?.nome)
    setDescricao(filterRotina?.descricao === undefined ? '' : filterRotina?.descricao)
    setNomeModulo(filterRotina?.modulo_nome)
    setcodModulo(filterRotina?.cod_modulo)
    const nomeUso = tipoUso.filter((item) => item.cod === filterRotina?.cod_uso)
    setNomeTipoUso(nomeUso[0]?.nome)
    setcodTipoUso(filterRotina?.cod_uso)
    setCheckAcesso(filterRotina?.acesso === 1 ? true : false)
    setCheckAlteracao(filterRotina?.alteracao === 1 ? true : false)
    setCheckInclusao(filterRotina?.inclusao === 1 ? true : false)
    setCheckExclusao(filterRotina?.exclusao === 1 ? true : false)
  }

  function buscarPermissoes(buscar_nome: string) {
    const palavraSemAcentos = removerAcentos(buscar_nome)
    return function (item) {
      const nomeSemAcentos = removerAcentos(String(item?.nome))
      return nomeSemAcentos.toLowerCase().includes(palavraSemAcentos.toLowerCase()) || !buscar_nome
    }
  }

  async function deletarRotina(cod) {
    setLoadingDeletar(true)
    const response = await deleteRotinaEdit(cod, setLoadingDeletar)
    atualizar()
  }

  function validar(cod) {
    if (!titulo) {
      Notification({
        message: 'Preencha o campo nome',
        type: 'error',
      })
      setLoading(false)
      return null
    }
    if (!nomeTipoUso) {
      Notification({
        message: 'Preencha o campo tipo acesso',
        type: 'error',
      })
      setLoading(false)
      return null
    }

    if (!checkAcesso && !checkAlteracao && !checkInclusao && !checkExclusao) {
      Notification({
        message: 'Selecione pelo menos um tipo de uso',
        type: 'error',
      })
      setLoading(false)
      return null
    }

    onFinishRotina(cod)
  }

  async function onFinishRotina(cod) {
    setLoading(true)
    const data = {
      nome: titulo,
      descricao: descricao,
      acesso: checkAcesso === true ? 1 : 0,
      alteracao: checkAlteracao === true ? 1 : 0,
      inclusao: checkInclusao === true ? 1 : 0,
      exclusao: checkExclusao === true ? 1 : 0,
      cod_uso: codTipoUso,
      cod_modulo: codModulo,
    }
    const resp = await putRotinaEdit(data, cod, setLoading, fechar)
    atualizar()
  }

  return (
    <>
      <HeaderDrawer>{nome_modulo}</HeaderDrawer>
      <BodyDrawer>
        <Box>
          <TitleH4 style={{ color: 'var(--text-color-light)' }}>Permissões</TitleH4>

          <Inline alignItems='center'>
            <Input placeholder='Buscar permissões' value={buscar} onChange={setBuscar} />
            <ButtonCustom icon={<FontAwesomeIcon icon={faPlus} />} onClick={() => setAbrir(true)}>
              Novo
            </ButtonCustom>
          </Inline>

          <Collapse accordion onChange={handleSelectRotina} destroyInactivePanel>
            {rotinasModulos.filter(buscarPermissoes(buscar)).map((item) => (
              <Panel header={`${item.cod} ${item.nome}`} key={item.cod}>
                <div className='form__edit__desc'>
                  <Select
                    icon={faGear}
                    placeholder='Selecione o módulo'
                    label='Módulo'
                    value={nomeModulo}
                    onChange={setNomeModulo}
                    onChangeID={setcodModulo}
                    open={openSelectModulo}
                    trigger={setOpenSelectModulo}
                    items={dadosModulos}
                    descricaoItem={'nome'}
                    idItem={'cod_modulo'}
                  />
                  <Select
                    icon={faGlobe}
                    placeholder='Selecione o tipo de acesso'
                    label='Tipo acesso'
                    value={nomeTipoUso}
                    onChange={setNomeTipoUso}
                    onChangeID={setcodTipoUso}
                    open={openSelectTipo}
                    trigger={setOpenSelectTipo}
                    items={tipoUso}
                    descricaoItem={'nome'}
                    idItem={'cod'}
                  />
                  <Input
                    placeholder='Digite o nome'
                    onChange={setTitulo}
                    value={titulo}
                    label='Nome'
                    icon={faLock}
                  />

                  <TextArea
                    value={descricao}
                    onChange={setDescricao}
                    label='Descrição'
                    maxLength={250}
                  />
                  <div className='campo__check'>
                    <div className='check'>
                      <label style={{ marginBottom: '5px' }}>Tipo de uso</label>
                      <Checkbox isChecked={checkAcesso} trigger={setCheckAcesso} label='Acesso' />
                      <Checkbox
                        isChecked={checkAlteracao}
                        trigger={setCheckAlteracao}
                        label='Alteração'
                      />
                      <Checkbox
                        isChecked={checkInclusao}
                        trigger={setCheckInclusao}
                        label='Inclusão'
                      />
                      <Checkbox
                        isChecked={checkExclusao}
                        trigger={setCheckExclusao}
                        label='Exclusão'
                      />
                    </div>
                  </div>
                  <div className='campo__salvar__deletar'>
                    <ButtonCustom
                      onClick={() => validar(item.cod)}
                      icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                      loading={loading}
                      disabled={loading}
                      type='primary'
                    >
                      Salvar
                    </ButtonCustom>
                    <ButtonCustom
                      danger
                      type='secondary'
                      loading={loadingDeletar}
                      disabled={loadingDeletar}
                      icon={<FontAwesomeIcon icon={faTrash} />}
                      onClick={() => deletarRotina(item.cod)}
                    >
                      Deletar
                    </ButtonCustom>
                  </div>
                </div>
              </Panel>
            ))}
          </Collapse>
          <ModalCadastrarRotina
            abrir={abrir}
            setAbrir={setAbrir}
            atualizar={atualizar}
            dadosModulos={dadosModulos}
          />
        </Box>
      </BodyDrawer>
    </>
  )
}

export default AccordionRotinas