import React, { useEffect, useState } from 'react'
import ViewPage from '../../../Components/ViewPage'

import { Button, message, Space, Table } from 'antd'
import {
  ItensSharedFilesUser,
  delArquivoCompartilhado,
  getSharedFilesUser,
} from '../../../services/SharedFiles'
import { Container } from './styles'

import Icon, { ReloadOutlined } from '@ant-design/icons'
import { faDownLong, faTrash } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import { orderBy } from 'lodash'
import { pagination } from '../../../Components/Default'
import Icons from '../../../Components/Icons'
import { filterTable, tryError } from '../../../services/Afins'
import { ItensFilesUser, getFilesUser } from '../../../services/SharedFiles/users'
import { api } from '../../../services/api'

const SharedFiles: React.FC = () => {
  const [sharedFiles, setSharedFiles] = useState<ItensSharedFilesUser[]>([])
  const [sharedFilesFilter, setSharedFilesFilter] = useState([])
  const [users, setUsers] = useState<ItensFilesUser[]>([])
  const [loading, setLoading] = useState(false)

  const listSharedFiles = async () => {
    setLoading(true)
    const resp = await getSharedFilesUser({ pagination: -1 })

    const dadosAtt = resp.data.itens.map((item) => {
      const tipo = item.nome.split('.').pop()

      return {...item, tipo: tipo}
    })
    setSharedFiles(dadosAtt)
    setSharedFilesFilter(dadosAtt)
    setLoading(false)
  }

  const listUsers = async () => {
    const resp = await getFilesUser({ ordem: 'create_time', pagination: -1 })
    setUsers(resp.data.itens)
  }

  const handleDownload = async (cod: number, fileName) => {
    try {
      const resp = await api.get(`/compartilhamento-arquivo/${cod}/visualizar-arquivo`, {
        responseType: 'blob',
      })

      const href = URL.createObjectURL(resp.data)

      const link = document.createElement('a')

      link.href = href

      link.download = fileName

      document.body.appendChild(link)

      link.click()

      document.body.removeChild(link)

      URL.revokeObjectURL(href)
    } catch (error) {
      tryError(error)
    }
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    if (Object.values(filters).every(f => f === null)) {
      setSharedFilesFilter(sharedFiles);
  } else {
    setSharedFilesFilter(extra.currentDataSource);
  }
};

  async function handleTrase(cod) {
    message.loading('Deletando...', 999999)
    const resp = await delArquivoCompartilhado(cod)

    listSharedFiles()
  }

  useEffect(() => {
    listUsers()
    listSharedFiles()
  }, [])

  return (
    <Container>
      <ViewPage
        newHide
        title='ttivos® Drive'
        btnClick={() => {}}
        btnsExta={
          <Button
            type='primary'
            onClick={listSharedFiles}
            loading={loading}
            icon={<ReloadOutlined />}
          >
            Atualizar
          </Button>
        }
        content={<></>}
      >
        <Table
          loading={loading}
          dataSource={orderBy(
            sharedFiles,
            (data) => {
              return dayjs(data.create_time).unix()
            },
            'desc'
          )}
          size='small'
          onChange={handleTableChange}
          pagination={pagination}
          scroll={{ y: 'calc(100vh - 370px)' }}
          bordered
          columns={[
            {
              title: 'Data',
              sorter: (data) => dayjs(data.create_time).unix(),
              dataIndex: 'create_time',
              render: (data: number) => dayjs(data).format('DD/MM/YYYY HH:mm:ss'),
            },
            {
              title: 'Usuário',
              dataIndex: 'usuario_nome',
              render: (data) => data,
            },
            { title: 'Descrição', dataIndex: 'descricao' },
            {
              title: 'Arquivo',
              dataIndex: 'nome',
              ...filterTable(false, true, 'tipo', sharedFiles),
            },
            {
              title: 'Ações',
              dataIndex: 'cod',
              width: 95,
              align: 'center',
              render: (cod: number, record) => (
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Space>
                    <Button
                      shape='circle'
                      type='default'
                      onClick={() => handleDownload(cod, record.arquivo)}
                      icon={<Icon component={() => <Icons icon={faDownLong} />} />}
                    />
                  </Space>
                  <Space>
                    <Button
                      shape='circle'
                      type='default'
                      onClick={() => handleTrase(cod)}
                      icon={<Icon component={() => <Icons icon={faTrash} />} />}
                      style={{ backgroundColor: 'red', border: 'none' }}
                    />
                  </Space>
                </div>
              ),
            },
          ]}
          footer={() => (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'right',
              }}
            >
              <b>Total: {sharedFilesFilter.length}</b>
            </div>
          )}
        />
      </ViewPage>
    </Container>
  )
}

export default SharedFiles