import { AxiosResponse } from 'axios'
import { api } from '../api'

import { localConfig } from '../../Configs/localConfig'
import { Dayjs } from 'dayjs'
import { Notification } from '../../Components/notification'

export interface TypeGetSafra {
  itens: [SafraItens]
}

export interface SafraItens {
  total_area_sub_areas: string
  total_sub_areas: number
  total_propriedades?: number
  cod?: number
  cod_conta?: number
  cod_cliente?: number
  cod_cultura?: number
  nome?: string
  data_inicio?: string | Dayjs
  data_final?: string | Dayjs
  cultura?: {
    cod?: number
    nome?: string
    cod_tipo?: number
    outros_tipos?: number
    cod_categoria?: number
    geral?: number
  }
  total_area_talhoes?: string
  total_talhoes?: number
}

interface TypeQuery {
  codConta?: number | string
  codCliente?: number | string
  cod?: number
  pagination?: number | -1
  order?: 'data_inicio' | 'nome'
}

export const getSafra = async (params: TypeQuery) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetSafra> = await api.get('/safra', {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
      ...params,
    },
  })
  return response
}

export const getSafraEstatisticas = async () => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetSafra> = await api.get(
    `/safra?codConta=${config.conta}&codCliente=${config.codCliente}&embed=estatisticas`
  )

  return response
}

export const getSafraConsultoria = async () => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetSafra> = await api.get(
    `/safra?codConta=${config.conta}&pagination=-1`
  )

  return response
}

export const postSafra = async (data, resetarForm, atualizar, messageDestroy, desabilitar) => {
  const config = localConfig()

  const response: AxiosResponse<TypeGetSafra> = await api
    .post(`/safra?codConta=${config.conta}&codCliente=${config.codCliente}`, data)
    .then((): any => {
      Notification({
        message: 'Safra cadastrada com Sucesso',
        type: 'success',
      })
      resetarForm?.()
      atualizar()
      messageDestroy()
      desabilitar = true
    })
    .catch((error) => {
      Notification({
        message: `${error?.response?.data?.error?.[0]?.msg ?? 'Erro ao cadastrar safra!'}`,
        type: 'error',
      })
    })

  return response
}

export const putSafra = async (codSafra, data, atualizarPut, ativar) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso
  api.defaults.headers['Content-Type'] = 'application/json'

  const response: AxiosResponse<TypeGetSafra> = await api
    .put(`/safra/${codSafra}?codConta=${config.conta}&codCliente=${config.codCliente}`, data)

    .then((): any => {
      Notification({
        message: 'Safra atualizada com Sucesso',
        type: 'success',
      })
      atualizarPut?.()
    })
    .catch((error) => {
      console.log('error', error)
      Notification({
        message: `${error?.response?.data?.error}`,
        type: 'error',
        duration: 5,
      })
    })

  return response
}

export const delSafra = async (cod) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetSafra> = await api.delete(
    `/safra/${cod}?codConta=${config.conta}&codCliente=${config.codCliente}`
  )

  return response
}
