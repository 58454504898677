import dayjs from 'dayjs';
import ReactDOM from 'react-dom';
import { faEye, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { FixedSizeList as List } from 'react-window';

//STYLES
import { Circle, ModalDatas } from "./styled";

//APP
import { listPragas__Cores__Dados } from '../tabelaPragas/meta';
import { TypeDetailsMonitoramento } from '../../../../features/Monitoramento/Monitoramento.dataDetails';

//COMPONENTS
import ButtonCustom from '../../../ButtonCustom';
import { Render } from '../../../CustomUi/render';
import { TitleH3 } from '../../../CustomUi/titleH3';
import { RowItem } from '../../../CustomUi/table/rowItem';
import { Detalhes } from '../detalhes';
import { RowTable } from '../../../CustomUi/table/rowTable';
import { BodyTable } from '../../../CustomUi/table/bodyTable';
import { HeaderTable } from '../../../CustomUi/table/headerTable';
//PROPS
interface Props {
    nome_praga: string;
    nome_variacao: string;
    list_datas: any[];
    nome_talhao: string;
    praga_selecionada: listPragas__Cores__Dados[];
    onClose: Function;
    setCalorSelect: Function;
    handleClickBadge: Function;
    handleAssembleHeatMap: Function;
    dataDetails: TypeDetailsMonitoramento;
    origem: 'Por talhão' | 'Todos';
    encolher: boolean;
    setVisibleDrawerPonto: React.Dispatch<React.SetStateAction<boolean>>;
    visibleChangeBooking: boolean;
    setOpen_tabela_datas: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TabelaDatas: React.FC<Props> = ({ onClose, praga_selecionada, handleAssembleHeatMap, handleClickBadge, 
    setCalorSelect, list_datas, nome_talhao, nome_praga, dataDetails, nome_variacao, origem, encolher, 
    setVisibleDrawerPonto, visibleChangeBooking, setOpen_tabela_datas }) => {

    //STATES
    const [buscar_data, setBuscar_data] = useState<string>('');
    const [ocultar_analise, setOcultar_analise] = useState<boolean>(true);
    const [open_modal_detalhes, setOpen_modal_detalhes] = useState<boolean>(false);
    const [data_selecionada, setData_selecionada] = useState<string>('');
    const [praga_selecionada_processada, setPraga_selecionada_processada] = useState([]);
    const [abriu_modal_automaticamente, setAbriu_modal_automaticamente] = useState<boolean>(false);

    function gerarMapaCalor(dados_v:any[], chave: string, key: number) {
        let isTodos: boolean = origem==='Todos';
        if (dados_v.length > 0) {
            handleAssembleHeatMap( [{ monitoramento: dados_v }], isTodos, Number(key) )
            handleClickBadge(chave)
            setCalorSelect(chave)
        }
    }


    useEffect(() => {
        let popular_datas = praga_selecionada.map((item, index) => {
            return {
                ...item,
                data: dayjs(list_datas?.[index]).format('DD/MM/YYYY')
            };
        });
    
        if (ocultar_analise) {
            popular_datas = popular_datas.filter(item => item.media !== 'NaN');
        }
    
        if (buscar_data) {
            const buscarData = (buscar_nome: string) => (item: { data: string }) =>
                String(item.data).toLowerCase().includes(buscar_nome.toLowerCase()) || !buscar_nome;
    
            popular_datas = popular_datas.filter(buscarData(buscar_data));
        }
    
        setPraga_selecionada_processada(popular_datas);
    }, [ocultar_analise, buscar_data, praga_selecionada, list_datas]);
    
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (praga_selecionada && praga_selecionada.length > 0) {
                let qtde_praga_com_dados = praga_selecionada.filter(item => item.media !== 'NaN');
                if (qtde_praga_com_dados.length === 1 && qtde_praga_com_dados[0].valor) {
                    gerarMapaCalor(qtde_praga_com_dados[0].valor, qtde_praga_com_dados[0].key, qtde_praga_com_dados?.[0]?.cod_talhao);
                    setData_selecionada(dayjs(qtde_praga_com_dados[0].key_atributo).format('DD/MM/YYYY'));
                    setOpen_modal_detalhes(true);
                    setAbriu_modal_automaticamente(true);
                }
            }
        }, 100); 
    
        return () => clearTimeout(timeoutId); 
    }, [praga_selecionada]);

    useEffect(() => {
        if (open_modal_detalhes===false && praga_selecionada && praga_selecionada.length > 0 && abriu_modal_automaticamente) {
            let qtde_praga_com_dados = praga_selecionada.filter(item => item.media !== 'NaN');
            if (qtde_praga_com_dados.length === 1 && qtde_praga_com_dados?.[0].valor) {
                setOpen_tabela_datas(false);
            }
        }
    }, [open_modal_detalhes])
    
    
    const Row = ({ index, style }) => {

        let { cor, data, media, resultado_minimo, resultado_media, resultado_maximo, valor, key, cod_talhao } = praga_selecionada_processada?.[index] ?? {};
        let isSemDado = media==='NaN';
 
        return (
            <div style={style}>
                <RowTable 
                    onDoubleClick={()=>{
                        gerarMapaCalor(valor, key, cod_talhao);
                        setData_selecionada(data);
                        setOpen_modal_detalhes(true);
                    }}
                >
                    <RowItem width={65} fontSize={13} style={{justifyContent:'center'}}>
                        <Circle style={{background: !isSemDado ? cor : undefined}} />
                    </RowItem>
                    <RowItem width={114} fontSize={13} style={{opacity: isSemDado ? 0.5 : undefined}}>
                        {data}
                    </RowItem>
                    <RowItem width={90} fontSize={13} style={{opacity: isSemDado ? 0.5 : undefined}}>
                        {isSemDado ? '0.0' : resultado_minimo}
                    </RowItem>
                    <RowItem width={88.5} fontSize={13} gap={5} style={{flexWrap: "wrap", padding: '0px 5px', opacity: isSemDado ? 0.5 : undefined}} >
                        {isSemDado ? '0.0' : resultado_media}
                    </RowItem>
                    <RowItem width={89} fontSize={13} gap={5} style={{flexWrap: "wrap", padding: '0px 5px', opacity: isSemDado ? 0.5 : undefined}} >
                        {isSemDado ? '0.0' : resultado_maximo}
                    </RowItem>
                    <RowItem width={107}>
                        <ButtonCustom 
                            onClick={()=>{
                                gerarMapaCalor(valor, key, cod_talhao);
                                setData_selecionada(data);
                                setOpen_modal_detalhes(true);
                            }}
                            icon={<FontAwesomeIcon icon={faEye} />}
                            disabled={isSemDado}
                        >
                            Abrir
                        </ButtonCustom>
                    </RowItem>
                </RowTable>
            </div>
        );
    }

    function nomeVariacao():string {
        if (nome_variacao) return `${(nome_variacao)}`;
        return '';
    }

    return ReactDOM.createPortal(

        <ModalDatas data-encolher={encolher} data-ocultar={visibleChangeBooking}>
            <div className='ModalDatas__header'>
                
                <h4 className='MD__header__title'><span className='MD__header__title__light'>{nome_talhao} </span> 》 {nome_praga} ({nome_variacao})</h4>

                <ButtonCustom 
                    type='tertiary'
                    onClick={()=>{onClose()}}
                    icon={<FontAwesomeIcon icon={faXmark} />}
                    danger
                />

            </div>

            <div className='ModalDatas__content'>
                {/* <Input 
                    width={180}
                    value={mascaraData(buscar_data)}
                    onChange={setBuscar_data}
                    placeholder='__/__/____'
                /> */}

                {/* <Switcher 
                    label="Ocultar monitoramentos sem resultado"
                    isChecked={ocultar_analise}
                    trigger={setOcultar_analise}
                />  */}

                <TitleH3>Monitoramentos</TitleH3>

                <HeaderTable 
                    itens={[
                        {valor: 'N.C', width: 65},
                        {valor: 'Data', width: 115},
                        {valor: 'Mínimo', width: 89},
                        {valor: 'Média', width: 89},
                        {valor: 'Máximo', width: 89},
                        {valor: 'Ações', width: 78}
                    ]}
                />

                <BodyTable style={{
                    marginTop: -10,
                }}>
                    <List
                        height={353}
                        itemCount={ (praga_selecionada_processada ?? []).length }
                        itemSize={55}
                        width={'100%'}
                        useIsScrolling
                    >
                        {Row}
                    </List>
                </BodyTable>
                
            </div>

            <Render condition={open_modal_detalhes && !encolher}>
                <Detalhes 
                    nome_talhao={nome_talhao}
                    nome_praga={`${nome_praga} ${nomeVariacao()}`}
                    data_selecionada={data_selecionada}
                    onClose={()=>{setOpen_modal_detalhes(false);setVisibleDrawerPonto(false)}}
                    dataDetails={dataDetails}
                    visibleChangeBooking={visibleChangeBooking}
                />
            </Render>

        </ModalDatas>,

        document.getElementById('portal')
    );
}