import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, message, Popconfirm, Typography } from "antd";
import { faCircleArrowRight, faDownLeftAndUpRightToCenter, faFloppyDisk, faUpRightAndDownLeftFromCenter, faXmark } from "@fortawesome/free-solid-svg-icons";

//STYLES
import { PainelSafra } from "./style";

//APP
import { TalhaoItens } from "../../../../services/Talhao";
import { TalhaoCultivaveisItens } from "../../../../services/TalhaoSafra";
import { gerarKey, leadZero, removerAcentos } from "../../../../utils";

//COMPONENTS
import Input from "../../../CustomUi/input";
import ShapeTalhao from "../../../CustomUi/shapeTalhao";
import ButtonCustom from "../../../ButtonCustom";
import { Inline } from "../../../CustomUi/inline";
import { TitleH2 } from "../../../CustomUi/titleH2";
import { InfoBox } from "../../../CustomUi/infoBox";
import { Switcher } from "../../../CustomUi/Switcher";
import { LineDivider } from "../../../CustomUi/LineDivider";

//TYPES
type TypeDataTalhao = {
    alert?: boolean;
    talhao: string;
    area?: number;
    ativo: boolean;
    coord: | { lat: number, lng: number }[] | google.maps.LatLng;
}

//PROPS
interface Props {
    list: TypeDataTalhao[];
    handleChangeEditNameTalhao: Function;
    handleToogleSwitchTalhao: Function;
    handleChangeCheckBoxSelectAll: Function;
    handleClickListPolygon: Function;
    listSelected: number;
    setListSelected: React.Dispatch<React.SetStateAction<number|null>>;
    qtd_selecionados: number;
    onFinish: Function;
    onClose: Function;
    setPolygonData: Function;
    list_talhoes: TalhaoCultivaveisItens[] | TalhaoItens[];
    tituloPainel?: string;
}

export const PainelTalhoesPraSafra: React.FC<Props> = ({ list, handleChangeEditNameTalhao, handleToogleSwitchTalhao, 
    handleChangeCheckBoxSelectAll, handleClickListPolygon, listSelected, setListSelected, qtd_selecionados, onFinish, 
    onClose, setPolygonData, list_talhoes, tituloPainel }) => {

    //STATES
    const [check_all, setCheck_all] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [shrink, setShrink] = useState<boolean>(false);
    const [confirmClose, setConfirmClose] = useState<boolean>(false);
    const [qtd_alerts, setQtd_alerts] = useState<number>(0);
    const [popup_ignore, setPopup_ignore] = useState<boolean>(false);

    //FUNCTION
    function searchTalhao(buscar_nome: string) {
        const ativoSemAcentos = removerAcentos(buscar_nome);
        return function (item) {
          const nomeSemAcentos = removerAcentos(String(item?.talhao))
          return nomeSemAcentos.toLowerCase().includes(ativoSemAcentos.toLowerCase()) || !buscar_nome
        }
    };

    function unCheckAll() {
        let uncheck = list.map((item)=> {
            return {
                ...item,
                ativo: false
            }
        });
        setPolygonData(uncheck);
    }

    function validateNames() {

        let findRecord = []
        let counter = 0;

        list.forEach((poly) => {

            list_talhoes.forEach((record) => {
                if (poly.talhao === record.nome && poly.ativo) {
                poly.alert = true
                counter += 1
                }
            })
        
            findRecord = [...findRecord, poly]
        })
          
        if (counter > 0) {
            let count_qtd_alerts = list.filter(item=> item.alert);
            setQtd_alerts(count_qtd_alerts.length);
            setPopup_ignore(true);
        }
        else {
            saveTalhoes();
        }

    }

    function saveTalhoes() {
        let remove_alerts = list.filter(item=> item.alert === false || item.alert === undefined);
        if (remove_alerts.length === 0) {
            message.warning({
                content: 'Os talhões selecionados já foram importados anteriormente! Verifique na página "Talhões de Safra" que eles já estão listados. Caso não estejam, por favor, entre em contato com o suporte.', 
                duration: 10, 
                style:{width:550, alignSelf:'center', marginLeft:'40%'}
            });
            return null;
        }
        onFinish(remove_alerts);
    }

    useEffect(() => {
        setListSelected(null)
    }, [search])

    useEffect(() => {
        if (check_all) {
            handleChangeCheckBoxSelectAll(true);
        }
    }, [check_all]);

    useEffect(() => {
        if (qtd_selecionados < list.length) {
            setCheck_all(false);
        }
        if (!check_all && qtd_selecionados === list.length) {
            setCheck_all(true);
        }
    }, [qtd_selecionados]);
    
    useEffect(() => {

        function substituirCoordenadas(texto) {
            return texto.replace(/latitude|longitude/gi, function(match) {
                if (match.toLowerCase() === "latitude") {
                    return "lat";
                } else if (match.toLowerCase() === "longitude") {
                    return "lng";
                }
            });
        }
        
        let new_talhoes = list_talhoes.map((item)=> {
            let stringfy = JSON.stringify(item?.kml?.coordenadas)
            return {
                nome: item.nome,
                coord: substituirCoordenadas(stringfy)
            }
        });

        list.forEach((poly) => {

            new_talhoes.forEach((item) => {
                let parser = JSON.stringify(poly.coord);
                if (poly.talhao === item.nome && item.coord == parser) {
                    poly.ativo = false
                }
            })
        
        })

    }, [list_talhoes])

    return (
        <PainelSafra data-shrink={shrink}>
            <div style={{display:'flex',justifyContent:'space-between'}}>
                <Popconfirm
                    placement="bottomRight"
                    title={`Deseja realmente fechar a importação?`}
                    open={confirmClose}
                    description={
                    <div style={{display:'flex',flexDirection:'column',gap:15}}>
                        {/* <span>D</span> */}
                        <Inline justifyContent="flex-end">

                            <ButtonCustom 
                                onClick={()=>{setConfirmClose(false)}}
                                type="tertiary"
                                danger
                                icon={<FontAwesomeIcon icon={faXmark} />}
                            >
                                Cancelar
                            </ButtonCustom>

                            <ButtonCustom 
                                value="Deletar"
                                onClick={()=>{setConfirmClose(false);onClose();message?.destroy()}}
                                icon={<FontAwesomeIcon icon={faCircleArrowRight} /> }
                                danger
                            >
                                Sim, fechar
                            </ButtonCustom>

                        </Inline>
                    </div>}
                    okButtonProps={{style: {display:'none'}}}
                    cancelButtonProps={{style: {display:'none'}}}
                >
                    <Button style={{background:'transparent', padding:0, margin:0, border:'none', boxShadow:'none'}}>
                        <ButtonCustom
                            onClick={() => { setConfirmClose(true) }}
                            danger
                            type="tertiary"
                            style={{ marginLeft: -10, alignSelf: 'flex-start' }}
                            icon={<FontAwesomeIcon icon={faXmark} />}
                        >
                            Fechar
                        </ButtonCustom>
                    </Button>
                </Popconfirm>
                <ButtonCustom
                    onClick={()=>{setShrink(!shrink)}}
                    type={shrink ? 'primary' : "tertiary"}
                    icon={<FontAwesomeIcon icon={shrink ? faUpRightAndDownLeftFromCenter : faDownLeftAndUpRightToCenter} />}
                    style={{marginLeft: -15, alignSelf: 'flex-start'}}
                /> 
            </div>
            

            <TitleH2>{tituloPainel ?? 'Importar talhões para safra'}</TitleH2>

            {/* <LineDivider size={2}/> */}

            <InfoBox
                items={[
                    { title: 'Qtde de talhões', value: leadZero(list.length) },
                    { title: 'Selecionados', value: leadZero(qtd_selecionados) },
                ]}
                gap={20}
            />

            <LineDivider size={1} />

            {/* <LineDivider size={1} /> */}

            {/* <Label>Talhões:</Label> */}
            
            <Input
                value={search}
                onChange={setSearch}
                placeholder="Buscar talhão"
            />
            
            <Switcher
                isChecked={check_all}
                trigger={setCheck_all}
                label="Selecionar todos os talhões"
                task={check_all ? ()=>unCheckAll() : ()=>{}}
            />

            <div className="PainelSafra__containerList">
                {(list??[]).filter(searchTalhao(search)).map((item, index)=> {
                    let isSelected = listSelected === index;
                    return (
                        <div 
                            className="PainelSafra__containerList__option" 
                            key={gerarKey(index)} 
                            role="none"
                            onClick={()=>{handleClickListPolygon(index, false)}}
                            style={{background: isSelected ? 'rgba(147 255 171 / 0.5)' : undefined, borderColor:isSelected?'rgba(147 255 171 / 0.5)':undefined }}
                        >
                            {/* @ts-ignore */}
                            <ShapeTalhao coordinates={item?.coord} fill={item?.ativo ? '#70ce9f' : '#9b9b9b'}/>

                            <div className="PainelSafra__containerList__option__info">
                                {item.alert && (
                                    <span className="PS__CL__option__info__alert">
                                        Esse nome já existe!
                                    </span>
                                )}
                                
                                <Typography.Text
                                    editable={{
                                    onChange: (nome) => handleChangeEditNameTalhao(nome, item, index),
                                    }}
                                    className="PS__CL__option__info__name"
                                >
                                    {item?.talhao}
                                </Typography.Text>
                                    
                                <span className="PS__CL__option__info__area">
                                    {item?.area} ha
                                </span>
                            </div>

                            <Switcher
                                isChecked={item.ativo}
                                trigger={()=>{}}
                                task={()=>{handleToogleSwitchTalhao(item.ativo, index, item.talhao, item.coord)}}
                            />
                        </div>
                    )
                })}
                
            </div>

            <LineDivider size={1} />


            <Popconfirm
                placement="topRight"
                title={`Descartar talhões com nomes iguais?`}
                open={popup_ignore}
                description={
                <div style={{display:'flex',flexDirection:'column',gap:15}}>
                    <span>Existem <b>{leadZero(qtd_alerts)}</b> {qtd_alerts===1 ? 'talhão' : 'talhões'} com o nome já cadastrado, Deseja <b>descartar</b> os duplicados e continuar?</span>
                    <Inline justifyContent="flex-end">

                        <ButtonCustom 
                            onClick={()=>{setPopup_ignore(false)}}
                            type="tertiary"
                            danger
                            icon={<FontAwesomeIcon icon={faXmark} />}
                        >
                            Cancelar
                        </ButtonCustom>

                        <ButtonCustom 
                            value="Deletar"
                            onClick={()=>{setPopup_ignore(false);saveTalhoes()}}
                            icon={<FontAwesomeIcon icon={faCircleArrowRight} /> }
                        >
                            Continuar
                        </ButtonCustom>

                    </Inline>
                </div>}
                okButtonProps={{style: {display:'none'}}}
                cancelButtonProps={{style: {display:'none'}}}
            >
                <Button style={{background:'transparent', padding:0, margin:0, border:'none', boxShadow:'none',alignSelf:'flex-start'}}>
                    <ButtonCustom
                        onClick={()=>{validateNames()}}
                        icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                        style={{alignSelf:'flex-start'}}
                        disabled={qtd_selecionados === 0}
                    >
                        Salvar
                    </ButtonCustom>
                </Button>
            </Popconfirm>

        </PainelSafra>
    )
};