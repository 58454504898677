import styled from "styled-components";
import { faPagelines } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Empty, Modal, Tag } from "antd";
import { useState, useRef, useEffect, Fragment } from "react";
import { faAdd, faChartArea, faCommentDots, faFloppyDisk, faPenToSquare } from "@fortawesome/free-solid-svg-icons";

//STYLES
import { BodyDrawer } from "../../styled";
const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    box-sizing: border-box;
    padding: 15px;
`;

//APP
import { ListaObs } from "../..";
import { gerarKey } from "../../../../../../../../utils";
import { usePostObs } from "../../../../../../../../services/recomendacao/ativos/post";
import { localConfig } from "../../../../../../../../Configs/localConfig";
import { obterNomeObersavacao } from "./meta";
import { codNivelAlerta, nivelAlerta } from "../incompatibilidades/meta";
import { useGetEstadiosByCultura, useGetRecomendacaoAtivosCadastrados, useGetRecomendacaoCultura } from "../../../../../../../../services/recomendacao/ativos/novo-index";

//COMPONENTS
import Input from "../../../../../../../../Components/CustomUi/input";
import TextArea from "../../../../../../../../Components/CustomUi/textarea";
import Carregando from "../../../../../../../../Components/CustomUi/loading";
import ButtonCustom from "../../../../../../../../Components/ButtonCustom";
import { Box } from "../../../../../../../../Components/CustomUi/box";
import { Nivel } from "./components/nivel";
import { Inline } from "../../../../../../../../Components/CustomUi/inline";
import { Select } from "../../../../../../../../Components/CustomUi/Select";
import { TitleH4 } from "../../../../../../../../Components/CustomUi/titleH4";
import { Warning } from "../../../../../../../../Components/CustomUi/warning";
import { RowItem } from "../../../../../../../../Components/CustomUi/table/rowItem";
import { RowTable } from "../../../../../../../../Components/CustomUi/table/rowTable";
import { Switcher } from "../../../../../../../../Components/CustomUi/Switcher";
import { BodyTable } from "../../../../../../../../Components/CustomUi/table/bodyTable";
import { obterValor } from "../compatibilidades/meta";
import { ModalEditar } from "./components/editar";
import { HeaderTable } from "../../../../../../../../Components/CustomUi/table/headerTable";
import { SelectMulti } from "../../../../../../../../Components/CustomUi/SelectMulti";
import { Selecionados } from "./components/selecionados";
import { useDeleteCompatibilidade } from "../../../../../../../../services/recomendacao/ativos/delete";
import React from "react";
import { DeleteButton } from "../../../../../../../../Components/CustomUi/deleteButton";

//PROPS
interface Props {
    id_tipo_principal: number | null;
    id_item_principal: number | null;
    estadios_selecionados_principal: {descr:string,id:number}[];
    openDropdownTipoObs: boolean,
    setOpenDropdownTipoObs: React.Dispatch<React.SetStateAction<boolean>>;
    nome_tipo_obs: string;
    setNome_tipo_obs: React.Dispatch<React.SetStateAction<string>>;
    id_tipo_obs: number | null;
    setId_tipo_obs: React.Dispatch<React.SetStateAction<number | null>>;
    openDropdownCulturas: boolean,
    setOpenDropdownCulturas: React.Dispatch<React.SetStateAction<boolean>>;
    estadios_selecionados_obs: {descr: string, id: number, key?: number}[];
    setEstadios_selecionados_obs: React.Dispatch<React.SetStateAction<{descr: string, id: number, key?: number}[]>>;
    todos_estadios_obs: boolean,
    setTodos_estadios_obs: React.Dispatch<React.SetStateAction<boolean>>;
    openDropdownEstadio: boolean;
    setOpenDropdownEstadio: React.Dispatch<React.SetStateAction<boolean>>;
    id_nivel_alerta: number;
    setId_nivel_alerta: React.Dispatch<React.SetStateAction<number>>;
    obs_obs: string;
    setObs_obs: React.Dispatch<React.SetStateAction<string>>;
    lista_obs_obs: ListaObs[];
    setLista_obs_obs: React.Dispatch<React.SetStateAction<any>>;
    nome_cultura_obs: string;
    setNome_cultura_obs: React.Dispatch<React.SetStateAction<string>>;
    id_cultura_obs: number | null;
    setId_cultura_obs: React.Dispatch<React.SetStateAction<number | null>>;
    id_ativo_selecionado: number;
    atualizarListaPrincipal: Function;
}

export const Observacoes: React.FC<Props> = (props) => {

    //REFs
    const refMulti = useRef<any>(null);

    const { openDropdownTipoObs, setOpenDropdownTipoObs, nome_tipo_obs, setNome_tipo_obs, 
        setId_tipo_obs, openDropdownCulturas, setOpenDropdownCulturas, id_tipo_obs,
        estadios_selecionados_obs, setEstadios_selecionados_obs, todos_estadios_obs, 
        setTodos_estadios_obs, openDropdownEstadio, setOpenDropdownEstadio, 
        id_nivel_alerta, setId_nivel_alerta, obs_obs, setObs_obs, 
        lista_obs_obs, setLista_obs_obs, nome_cultura_obs, setNome_cultura_obs, 
        id_cultura_obs, setId_cultura_obs, id_ativo_selecionado, atualizarListaPrincipal, 
        id_tipo_principal, id_item_principal } = props;

    const lista: {id:number,descr:string}[] = [
        {id: 1, descr: 'pH de calda'},
        {id: 2, descr: 'umidade do solo'},
        {id: 3, descr: 'umidade relativa do ar'},
        {id: 4, descr: 'temperatura mínima'},
        {id: 5, descr: 'temperatura máxima'},
        {id: 6, descr: 'aplicação de calcário recente'},
        {id: 7, descr: 'necessidade de pré-mistura'},
        {id: 8, descr: 'teor de argila'},
        {id: 9, descr: 'teor de Matéria Orgânica'},
        {id: 10, descr: 'carry-over'},
        {id: 11, descr: 'Outros'}
    ];

    function limparStates() {
        setId_tipo_obs(null);
        setNome_tipo_obs('');
        setId_cultura_obs(null);
        setNome_cultura_obs('');
        setEstadios_selecionados_obs([]);
        setEstadios_ja_selecionados([]);
        setLista_obs_obs([]);
        setObs_obs('');
    }

    //STATES
    const [search, setSearch] = useState<string>('');
    const [estadios_ja_selecionados, setEstadios_ja_selecionados] = useState([]);
    const [openConfirm, setOpenConfirm] = useState<boolean>(false);
    const [nome_item_atual, setNome_item_atual] = useState<string>('');
    const [id_item_atual, setId_item_atual] = useState<number | null>(null);
    const [modalEditar, setModalEditar] = useState<boolean>(false);
    const [dados_editar, setDados_editar] = useState<any>();

    //API
    const { data_cadastrados, refetch_data_cadastrados, carregando_cadastrados } = useGetRecomendacaoAtivosCadastrados(id_ativo_selecionado, id_tipo_principal);
    const { data_cultura, carregando_cultura } = useGetRecomendacaoCultura(true);
    const { data_estadios, refetch_estadios, carregando_estadios } = useGetEstadiosByCultura(id_cultura_obs);
    //API  - POST
    const { carregando_salvar_obs, mutate_salvar_obs } =  usePostObs();
    const { carregando_deletar_compati, mutate_deletar_compati } = useDeleteCompatibilidade();

    function salvarObs() {
        mutate_salvar_obs({
            codConta: Number(localConfig().conta),
            cod_ativo: id_ativo_selecionado,
            id_tipo_principal: id_tipo_principal,
            id_item_principal: id_item_principal,
            cod_cultura: id_cultura_obs,
            cod_alerta: Number(id_nivel_alerta),
            todos_estadios: todos_estadios_obs,
            cod_estadios: estadios_selecionados_obs.map((item => item.id)) ?? [],
            obs: obs_obs,
            cod_observacao: id_tipo_obs,
            limparStates: () => limparStates(),
            atualizarLista: () => refetch_data_cadastrados(),
            atualizarListaPrincipal: () => atualizarListaPrincipal()
        });
        if (!todos_estadios_obs) {
            for (const item of lista_obs_obs) {
                mutate_salvar_obs({
                    codConta: Number(localConfig().conta),
                    cod_ativo: id_ativo_selecionado,
                    id_tipo_principal: id_tipo_principal,
                    id_item_principal: id_item_principal,
                    cod_cultura: id_cultura_obs,
                    cod_observacao: id_tipo_obs,
                    cod_alerta: Number(item.nivel),
                    todos_estadios: todos_estadios_obs,
                    cod_estadios: (item.estadios).map((item => item.id)) ?? [],
                    obs: item.obs,
                    limparStates: () => limparStates(),
                    atualizarLista: () => refetch_data_cadastrados(),
                    atualizarListaPrincipal: () => atualizarListaPrincipal()
                });
            } 
        } 

        setTodos_estadios_obs(false);
    }

    function deletarCompatibilidade() {
        mutate_deletar_compati({
            codConta: Number(localConfig().conta),
            cod: id_item_atual,
            limparStates: () => limparStates(),
            atualizarLista: () => refetch_data_cadastrados(),
            closePopup: () => setOpenConfirm(false)
        })
    }

    useEffect(() => {
        if (id_cultura_obs !== null) {
            refetch_estadios();
        }
    }, [id_cultura_obs])

    function addListaObs() {
        setEstadios_ja_selecionados(estadios_ja_selecionados=>[ ...estadios_ja_selecionados, ...estadios_selecionados_obs]);

        setLista_obs_obs(lista_obs_obs=>[...lista_obs_obs, {
            key: Date.now(),
            estadios: estadios_selecionados_obs,
            nivel: id_nivel_alerta,
            obs: obs_obs
        }]);

        setEstadios_selecionados_obs([]);
        setObs_obs('');
    }

    useEffect(() => {
      return () => {
        setEstadios_ja_selecionados([])
      }
    }, [])
    

    function removerDaListaObs(key: number) {
        let remove = lista_obs_obs.filter(item=> item.key === key);
        let restante = lista_obs_obs.filter(item=> item.key !== key);
        let IDs_removidos = (remove?.[0]?.estadios ?? []).map(item=> item.id);
        let remover_estadios_ja_selec = estadios_ja_selecionados?.filter(estadio => !IDs_removidos.includes(estadio.id));


        setEstadios_ja_selecionados(remover_estadios_ja_selec);
        setLista_obs_obs(restante);
    }

    function buscarCadastro(buscarItem) {
        return function(item) {              
            return String(item.cultura_item_lancamento?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
            String(item.estadio_nome?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
            String(item.item_lancamento_nome?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
            !buscarItem;
        }
    }
    function filtrarObservacoes() {
        return function(item) {              
            return item.cod_tipo === 3
        }
    }

    /**Desabilita a seleção de estádio que já foram adicionados no cadastro */
     function desabilitarEstadiosSelecionados() {
        return (data_cadastrados?.itens ?? [])?.filter(filtrarObservacoes())?.filter(item=>item.cod_estadio !== null)?.map(item=> item?.['cod_estadio']) ?? [];
    }

    function disabilitarBtnAdd(): boolean {
        if (estadios_selecionados_obs.length === 0) {
            return true;
        }
        if (id_tipo_obs===null || id_cultura_obs===null) {
            return true;
        }

        return false;
    }

    function disabledSaveButton(): boolean {
        if (id_tipo_obs===null || id_cultura_obs===null) {
            return true;
        }

        return obs_obs.length === 0;
    }

    /**Desabilita os tipos que já foram adicionados no cadastro */
    function desabilitarSelecionados() {

        /**se for do tipo cultura e se estiver com a flag `todos_estadios`. (assume que se a cultura estiver com `cod_estadio === null`, é todos os estadios) */
        let apenas_culturas = (data_cadastrados?.itens ?? [])?.filter(filtrarObservacoes())?.filter(item=>item.cod_cultura!==null) ?? [];
        let apenas_todos_estadios = (apenas_culturas ?? [])?.filter(item=>item.cod_estadio===null) ?? [];
        let apenas_tipo_obs = (apenas_todos_estadios ?? [])?.filter(item=> item.cod_observacao === id_tipo_obs);
        let culturas = (apenas_tipo_obs ?? [])?.map(item=> item?.['cod_cultura']) ?? [];

        return culturas;
    }

    function obterQtdEstadiosSelecionados(cod_cultura: number) {
        let apenas_culturas = (data_cadastrados?.itens ?? [])?.filter(filtrarObservacoes())?.filter(item=>item.cod_cultura!==null) ?? [];
        let cultura_selecionadas = (apenas_culturas ?? [])?.filter((item)=> item.cod_cultura === cod_cultura && item.cod_observacao === id_tipo_obs);
        return (cultura_selecionadas ?? [])?.length;
    };

    function todosEstadiosJaForamAdd() {
        if (obterQtdEstadiosSelecionados(id_cultura_obs) === 0 || (data_estadios?.itens ?? []).length === 0) {
            return false;
        }
        return ( id_cultura_obs !== null && (data_estadios?.itens ?? []).length === obterQtdEstadiosSelecionados(id_cultura_obs)) ?? false;
    }

    function addTodosEstadiosManualmente() {
        if (todos_estadios_obs) {
            setEstadios_selecionados_obs([]);
            for (const item of (data_estadios?.itens ?? [])) {
                setEstadios_selecionados_obs(estadios_selecionados_obs=> [...estadios_selecionados_obs, {
                    descr: item?.nome,
                    id: item?.cod,
                    key: item?.cod 
                }])
            }
        }
    }

    useEffect(() => {
        addTodosEstadiosManualmente();
    }, [todos_estadios_obs]);
    
    useEffect(() => {
        if (estadios_selecionados_obs.length < (data_estadios?.itens ?? []).length) {
            setTodos_estadios_obs(false)
        }
    }, [estadios_selecionados_obs])
        
    return (
        <Fragment>
        <Container>
            <Box gap={15}>

                <Select
                    label='Selecione o tipo de observação'
                    placeholder='Selecione o tipo de observação'
                    value={nome_tipo_obs}
                    onChange={setNome_tipo_obs}
                    onChangeID={setId_tipo_obs}
                    open={openDropdownTipoObs}
                    trigger={setOpenDropdownTipoObs}
                    items={lista ?? []}
                    icon={faCommentDots}
                    maxHeight={250}
                    idItem={'id'}
                    descricaoItem='descr'
                    atual={nome_tipo_obs}
                />

                <Select
                    label='Selecione a cultura'
                    placeholder='Selecione a cultura'
                    value={nome_cultura_obs}
                    onChange={setNome_cultura_obs}
                    onChangeID={setId_cultura_obs}
                    open={openDropdownCulturas}
                    trigger={setOpenDropdownCulturas}
                    items={data_cultura?.itens ?? []}
                    icon={faPagelines}
                    maxHeight={250}
                    idItem={'cod'}
                    descricaoItem='nome'
                    loading={carregando_cultura}
                    atual={nome_cultura_obs}
                    disabledItens={desabilitarSelecionados()}
                />
                
                {obterQtdEstadiosSelecionados(id_cultura_obs) === 0 &&(
                    <Switcher
                        label='Válida para todos os estádios'
                        isChecked={todos_estadios_obs} 
                        trigger={setTodos_estadios_obs}
                    />
                )}

                {todosEstadiosJaForamAdd() && (
                    <Warning type='info'>
                        Todos os estádios dessa cultura já foram adicionados!
                    </Warning>
                )}
                 
                {(!todosEstadiosJaForamAdd()) &&(
                    <SelectMulti 
                        label='Selecione os estádios'
                        selecteds={estadios_selecionados_obs}
                        onChange={setEstadios_selecionados_obs}
                        placeholder='Selecione os estádios'
                        open={openDropdownEstadio}
                        trigger={setOpenDropdownEstadio}
                        maxHeight={250}
                        items={data_estadios?.itens ?? []}
                        descricaoItem='nome'
                        idItem='cod'
                        icon={faChartArea}
                        removeItens={estadios_ja_selecionados}
                        disabled={(data_estadios?.itens)?.length === estadios_ja_selecionados?.length}
                        refProp={refMulti}
                        keyProp={'multi_estadios_obs'}
                        loading={carregando_estadios}
                        disabledItens={desabilitarEstadiosSelecionados()}
                    />
                )}
                
                {!todosEstadiosJaForamAdd() &&<>
                    <Nivel
                        id_nivel_alerta={id_nivel_alerta}
                        setId_nivel_alerta={setId_nivel_alerta}
                    />
                    <TextArea
                        label={`Observação da compatibilidade`}
                        placeholder='Digite aqui a observação'
                        value={obs_obs}
                        onChange={setObs_obs}
                        resize={false}
                        height={70}
                    />

                    {!todos_estadios_obs &&(
                        <ButtonCustom
                            icon={<FontAwesomeIcon icon={faAdd} />}
                            onClick={()=>{addListaObs()}}
                            style={{alignSelf: 'flex-start'}}
                            type="tertiary"
                            disabled={disabilitarBtnAdd()}
                        >
                            Adicionar outra observação
                        </ButtonCustom>
                    )}
                </>}          

                {(lista_obs_obs.length > 0 && !todos_estadios_obs) && (
                    <Selecionados
                        lista_obs={lista_obs_obs}
                        removerDaListaObs={removerDaListaObs}
                        disabled={todos_estadios_obs}
                        cod_alerta={id_nivel_alerta}
                    />
                )}

            </Box>

            <ButtonCustom 
                icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                onClick={()=>{salvarObs()}}
                style={{alignSelf: 'flex-start'}}
                loading={carregando_salvar_obs}
                disabled={carregando_salvar_obs || disabledSaveButton()}
            >
                Salvar Observação
            </ButtonCustom>

        </Container>

        <BodyDrawer>

            <Box>

                <TitleH4 style={{color: 'var(--text-color-light)'}}>Doses Cadastradas</TitleH4>

                <Input
                    placeholder="Buscar cultura ou estádio"
                    value={search}
                    onChange={setSearch}
                />

                {(data_cadastrados?.itens ?? []).filter(buscarCadastro(search)).filter(filtrarObservacoes()).length === 0
                ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`Nenhuma dose cadastrada`} />
                : <>
                    {carregando_cadastrados &&(
                        <Carregando
                            legenda="Carregando lista"
                            justifyContent="center"
                            animation
                            height={100}
                        />
                    )}

                    <HeaderTable
                        itens={[
                            {valor: 'Cultura (Estádio)', width: 340},
                            {valor: 'Tipo obs', width: 210},
                            {valor: 'Nível de alerta', width: 130},
                            {valor: 'Opções', width: 120}
                        ]}
                    />
                    <BodyTable style={{marginTop: -10, height: (data_cadastrados?.itens ?? []).filter(buscarCadastro(search)).filter(filtrarObservacoes())?.length < 7 ? 'auto' : 400}}>
                        {(data_cadastrados?.itens ?? []).filter(buscarCadastro(search)).filter(filtrarObservacoes()).map((item, index)=> {
                            return (
                                <RowTable key={gerarKey(index)}>
                                    <RowItem width={340}>
                                        {item?.cultura_item_lancamento} ({item?.item_lancamento_nome ?? 'Todos'})
                                    </RowItem>
                                    <RowItem width={210}>
                                        {obterNomeObersavacao(item?.cod_observacao)}
                                    </RowItem>
                                    <RowItem width={130}>
                                        <Tag color={codNivelAlerta(item?.cod_alerta)[0]} ><span style={{color:codNivelAlerta(item?.cod_alerta)[1]}}>{nivelAlerta(item?.cod_alerta)}</span></Tag>
                                    </RowItem>
                                    <RowItem width={((data_cadastrados?.itens ?? []).filter(buscarCadastro(search)).filter(filtrarObservacoes()) ?? [])?.length > 7 ? 116 : 120} gap={10}>
                                        <ButtonCustom
                                            onClick={()=>{setDados_editar(item);setModalEditar(true)}}
                                            icon={<FontAwesomeIcon icon={faPenToSquare} />}
                                            type="tertiary"
                                        >
                                        </ButtonCustom>

                                        <DeleteButton 
                                            key={gerarKey(index)}
                                            open={openConfirm && id_item_atual===item.cod}
                                            trigger={setOpenConfirm}
                                            onTrigger={()=>{setId_item_atual(item.cod);setNome_item_atual(item.cultura_item_lancamento)}}
                                            loading={carregando_deletar_compati}
                                            message={`o item ${nome_item_atual} será excluido!`}
                                            deleteds={[nome_item_atual]}
                                            onDelete={()=>{deletarCompatibilidade()}}
                                            disabled={carregando_deletar_compati}
                                        />

                                    </RowItem>
                                </RowTable>
                            )
                        })}
                    </BodyTable>
                </>
                }
            </Box>
            
        </BodyDrawer>

        <Modal
            title={`Editar ${obterValor(dados_editar)}`}
            width={750}
            open={modalEditar} 
            onOk={()=>{}} 
            onCancel={()=>{setModalEditar(false)}}
            footer={false}
        >
            <ModalEditar
                registros={dados_editar}
                onClose={()=>{setModalEditar(false)}}
                atualizarLista={()=>{refetch_data_cadastrados()}}
                id_tipo_principal={id_tipo_principal}
            />
        </Modal>

        </Fragment>
    )
};