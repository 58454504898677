import React, { useEffect, useState } from 'react'
import { Container } from './styled'
import PageContainer from '../../../Components/pageContainer'
import { TitleH1 } from '../../../Components/CustomUi/titleH1'
import { BodyTable } from '../../../Components/CustomUi/table/bodyTable'
import { Render } from '../../../Components/CustomUi/render'
import Carregando from '../../../Components/CustomUi/loading'
import { Drawer } from 'antd'
import ButtonCustom from '../../../Components/ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faXmark } from '@fortawesome/free-solid-svg-icons'
import { getRotinaEdit } from '../../../services/rotina'
import { RowTable } from '../../../Components/CustomUi/table/rowTable'
import { RowItem } from '../../../Components/CustomUi/table/rowItem'
import { HeaderTable } from '../../../Components/CustomUi/table/headerTable'
import Input from '../../../Components/CustomUi/input'
import { NavegacaoDrawer } from '../../app/recomendacao/novoCompatibilidadeAtivo/components/gavetaCadastrar/styled'
import AccordionRotinas from './components/accordion-rotinas'
import { removerAcentos } from '../../../utils'

const EditPermissoes: React.FC = () => {
  const [dadosRotina, setDadosRotina] = useState([])
  const [nomesModulos, setNomesModulos] = useState([])
  const [nomeModulo, setNomeModulo] = useState('')
  const [buscar, setBuscar] = useState('')
  const [codModulo, setCodModulo] = useState<number>()
  const [loading, setLoading] = useState(true)
  const [pagination, setPagination] = useState<number>(15)
  const [page, setPage] = useState<number>(1)
  const [open, setOpen] = useState(false)

  async function listarPermissoes() {
    const response = await getRotinaEdit({})
    const modulo_nomes = [
      ...new Map(
        response.data.itens.map((item) => [
          item.modulo_nome,
          { cod_modulo: item.cod_modulo, nome: item.modulo_nome },
        ])
      ).values(),
    ].sort((a, b) => a.nome.localeCompare(b.nome))
    setNomesModulos(modulo_nomes)
    setDadosRotina(response.data.itens)
    setLoading(false)
  }

  function fecharDrawer() {
    setOpen(false)
  }

  function abrirDrawer(cod, nome) {
    setOpen(true)
    setCodModulo(cod)
    setNomeModulo(nome)
  }

  function buscarModulos(buscar_nome: string) {
    const palavraSemAcentos = removerAcentos(buscar_nome)
    return function (item) {
      const nomeSemAcentos = removerAcentos(String(item?.nome))
      return nomeSemAcentos.toLowerCase().includes(palavraSemAcentos.toLowerCase()) || !buscar_nome
    }
  }

  useEffect(() => {
    listarPermissoes()
  }, [])

  return (
    <Container>
      <PageContainer>
        <TitleH1>Permissões</TitleH1>

        <Input placeholder='Buscar módulos' value={buscar} onChange={setBuscar} />

        <HeaderTable
          itens={[
            { id: 1, valor: 'Módulos', width: 'calc(100% - 120px)' },
            { id: 5, valor: 'Ações', width: 120 },
          ]}
        />

        <BodyTable
          paginate={nomesModulos.length}
          onChangeItensPage={setPagination}
          onChangePage={setPage}
          current_page={page}
          setCurrent_page={setPage}
          style={{
            marginTop: -10,
            height: nomesModulos.length < 8 ? '100%' : 'calc(100vh - 280px)',
            position: 'relative',
            overflowX: 'hidden',
          }}
        >
          <Render condition={true}>
            {nomesModulos.filter(buscarModulos(buscar)).map((item) => {
      
              return (
                <RowTable key={item.cod_modulo} onDoubleClick={() => abrirDrawer(item.cod_modulo, item.nome)}>
                  <RowItem fontSize={13}>{item.nome}</RowItem>
                  <RowItem width={115}>
                    <ButtonCustom
                      onClick={() => abrirDrawer(item.cod_modulo, item.nome)}
                      icon={<FontAwesomeIcon icon={faEdit} />}
                    >
                      Editar
                    </ButtonCustom>
                  </RowItem>
                </RowTable>
              )
            })}
          </Render>

          <Render condition={loading}>
            <Carregando
              animation
              justifyContent='center'
              legenda='Buscando módulos...'
              size={'large'}
              height={350}
            />
          </Render>
        </BodyTable>
      </PageContainer>

      <Drawer
        width={800}
        open={open}
        onClose={fecharDrawer}
        closable={false}
        bodyStyle={{ padding: 0 }}
        headerStyle={{ padding: '10px 10px' }}
        destroyOnClose
        title={
          <NavegacaoDrawer>
            <ButtonCustom
              danger
              icon={<FontAwesomeIcon icon={faXmark} />}
              type='tertiary'
              onClick={fecharDrawer}
              style={{ marginLeft: -10 }}
            >
              Fechar
            </ButtonCustom>
          </NavegacaoDrawer>
        }
      >
        <AccordionRotinas
          dados={dadosRotina}
          cod_modulo={codModulo}
          nome_modulo={nomeModulo}
          atualizar={listarPermissoes}
          dadosModulos={nomesModulos}
          fechar={fecharDrawer}
        />
      </Drawer>
    </Container>
  )
}

export default EditPermissoes