import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { faFloppyDisk, faNetworkWired, faTag, faXmark } from "@fortawesome/free-solid-svg-icons";

//APP
import { localConfig } from "../../../../../../../../../../Configs/localConfig";
import { obterTipoPorId } from "../../meta";
import { usePostCompatibilidade } from "../../../../../../../../../../services/recomendacao/ativos/post";
import { useDeleteCompatibilidade } from "../../../../../../../../../../services/recomendacao/ativos/delete";
import { obterIdCompatibilidadePorId } from "../../../incompatibilidades/meta";
import { obterCodLancamento, obterCodPorId, obterValorLancamento } from "../../../../../../meta";

//COMPONENTS
import Input from "../../../../../../../../../../Components/CustomUi/input";
import TextArea from "../../../../../../../../../../Components/CustomUi/textarea";
import ButtonCustom from "../../../../../../../../../../Components/ButtonCustom";
import { Inline } from "../../../../../../../../../../Components/CustomUi/inline";
import { DeleteButton } from "../../../../../../../../../../Components/CustomUi/deleteButton";

//PROPS
interface Props {
    registros: any;
    onClose?: Function;
    atualizarLista?: Function;
    atualizarListaPrincipal?: Function;
    isVisualizar?: boolean;
    refetch?: Function;
    id_tipo_principal: number | null;
}

export const ModalEditar: React.FC<Props> = ({ registros, id_tipo_principal, onClose, atualizarLista, isVisualizar, refetch, atualizarListaPrincipal }) => {

    //STATES
    const [obs, setObs] = useState<string>('');
    const [original_obs, setOriginal_obs] = useState<string>('');
    const [openConfirm, setOpenConfirm] = useState<boolean>(false);

    //API
    const { carregando_salvar_compati, mutate_salvar_compati } = usePostCompatibilidade();
    const { carregando_deletar_compati, mutate_deletar_compati } = useDeleteCompatibilidade();

    function salvarEdicao() {
        mutate_salvar_compati({
            codConta: Number(localConfig().conta),
            cod: registros?.cod,
            id_tipo_principal: registros?.lancamento,
            id_item_principal: registros?.[obterCodPorId(registros?.lancamento)],
            id_estadio_principal: registros?.estadio_cultura,
            cod_ativo: registros?.cod_ativo,
            tipo_compatibilidade: registros?.item_lancamento,
            cod_compatibilidade: obterIdCompatibilidadePorId(registros?.item_lancamento, registros),
            cod_estadios: registros?.cod_estadio ? [registros.cod_estadio] : undefined,
            obs: obs,
            limparStates: () => onClose?.(),
            atualizarLista: () => atualizarLista?.(),
            atualizarListaPrincipal: () => atualizarListaPrincipal?.(),
        });
    }

    function deletarCompatibilidade() {
        mutate_deletar_compati({
            codConta: Number(localConfig().conta),
            cod: registros.cod,
            limparStates: () => {},
            atualizarLista: () => {refetch?.(); atualizarLista?.(); atualizarListaPrincipal?.()},
            closePopup: () => setOpenConfirm(false)
        })
    }

    useEffect(() => {
        setObs(registros?.observacao);
        setOriginal_obs(registros?.observacao);
    }, [registros]);

    function desabilitarBtnSalvar() {
        if (carregando_salvar_compati) {
            return true;
        }
        if (isVisualizar) {
            return false;
        }
        return (original_obs === obs)
    }
    
    return (
        <div style={{display:'flex',flexDirection:'column',gap:15}}>

            <Inline gap={15}>
                <Input 
                    label="Tipo"
                    value={obterTipoPorId(registros?.[obterCodLancamento(registros.lancamento, id_tipo_principal)])}
                    onChange={()=>{}}
                    placeholder="Selecione o tipo"
                    icon={faNetworkWired}
                    desabilitado
                />
                <Input 
                    label={obterTipoPorId(registros?.[obterCodLancamento(registros.lancamento, id_tipo_principal)])}
                    value={registros?.[obterValorLancamento(registros.lancamento, id_tipo_principal)]}
                    onChange={()=>{}}
                    placeholder="Selecione o tipo"
                    icon={faTag}
                    desabilitado
                />
            </Inline>
            
            {(registros.item_lancamento !== 6 && registros.item_lancamento !== 7) &&(
                <Inline gap={15}>
                    <Input 
                        label="Tipo"
                        value={obterTipoPorId(registros?.item_lancamento)}
                        onChange={()=>{}}
                        placeholder="Selecione o tipo"
                        icon={faNetworkWired}
                        desabilitado
                    />
                    <Input 
                        label={obterTipoPorId(registros?.item_lancamento)}
                        value={registros?.item_lancamento_nome}
                        onChange={()=>{}}
                        placeholder="Selecione o tipo"
                        icon={faTag}
                        desabilitado
                    />
                </Inline>
            )}

            {registros.cod_cultura !== null &&(
                <Inline gap={15}>
                    <Input 
                        label="Cultura"
                        value={registros.cultura_nome}
                        onChange={()=>{}}
                        placeholder="Selecione o tipo"
                        icon={faNetworkWired}
                        desabilitado
                    />
                    <Input 
                        label="Estádio"
                        value={registros?.estadio_nome ?? 'Todos'}
                        onChange={()=>{}}
                        placeholder="Selecione o tipo"
                        icon={faTag}
                        desabilitado
                    />
                </Inline>
            )}
            

            <TextArea
                label={`Observação da compatibilidade`}
                placeholder='Digite aqui a observação'
                value={obs}
                onChange={setObs}
                resize={false}
                height={70}
            />

            <Inline justifyContent={isVisualizar ? 'space-between' :"flex-end"} reverse={isVisualizar}>
                {isVisualizar ? 
                    <DeleteButton
                        open={openConfirm}
                        trigger={setOpenConfirm}
                        // onTrigger={()=>{setId_item_atual(item.cod);setNome_item_atual(item.item_lancamento_nome)}}
                        loading={carregando_deletar_compati}
                        message={`o item será excluido!`}
                        // deleteds={[registros?.item_lancamento_nome]}
                        onDelete={()=>{deletarCompatibilidade()}}
                        disabled={carregando_deletar_compati}
                        typeButtonTrigger="secondary"
                        textButtonTrigger="Excluir"
                    />
        
                :
                    <ButtonCustom
                        icon={<FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>}
                        onClick={()=>{onClose(false)}}
                        type="tertiary"
                        danger
                        disabled={carregando_salvar_compati}
                    >
                        Cancelar
                    </ButtonCustom>
                }
                
                <ButtonCustom 
                    icon={<FontAwesomeIcon icon={faFloppyDisk}></FontAwesomeIcon>}
                    onClick={()=>{salvarEdicao()}}
                    loading={carregando_salvar_compati}
                    disabled={desabilitarBtnSalvar()}
                >
                    Salvar edição
                </ButtonCustom>
            </Inline>

        </div>
    )
};