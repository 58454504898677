import styled from "styled-components";
import { ListaObs } from "../..";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, useRef } from "react";
import { Button, Empty, Modal, Popconfirm, Tag } from "antd";
import { faAdd, faChartArea, faFloppyDisk, faNetworkWired, faPenToSquare, faTag, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";

//APP
import { gerarKey } from "../../../../../../../../utils";
import { localConfig } from "../../../../../../../../Configs/localConfig";
import { usePostIncompatibilidade } from "../../../../../../../../services/recomendacao/ativos/post";
import { obterCodItem, obterTipoPorId } from "../compatibilidades/meta";
import { buscarCadastro, codNivelAlerta, disabilitarBtnAdd, disabilitarTextArea, filtrarIncompatibilidade, nivelAlerta, obterValor } from "./meta";
import { useGetRecomendacaoTipoInsumos, useGetRecomendacaoIngredientesAtivos, 
         useGetRecomendacaoGrupoQuimico, useGetRecomendacaoCultura, useGetRecomendacaoTecnologia, 
         useGetEstadiosByCultura,
         useGetRecomendacaoAtivosCadastrados,
         useGetRecomendacaoClasseAgronomica,
         useGetRecomendacaoFormulacao,
         useGetRecomendacaoGrupo} from "../../../../../../../../services/recomendacao/ativos/novo-index";
import { useDeleteCompatibilidade } from "../../../../../../../../services/recomendacao/ativos/delete";

//STYLES
import { BodyDrawer } from "../../styled";
const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

//COMPONENTS
import Input from "../../../../../../../../Components/CustomUi/input";
import TextArea from "../../../../../../../../Components/CustomUi/textarea";
import Carregando from "../../../../../../../../Components/CustomUi/loading";
import ButtonCustom from "../../../../../../../../Components/ButtonCustom";
import { Box } from "../../../../../../../../Components/CustomUi/box";
import { Nivel } from "./components/nivel";
import { Select } from "../../../../../../../../Components/CustomUi/Select";
import { Inline } from "../../../../../../../../Components/CustomUi/inline";
import { RowItem } from "../../../../../../../../Components/CustomUi/table/rowItem";
import { Warning } from "../../../../../../../../Components/CustomUi/warning";
import { TitleH4 } from "../../../../../../../../Components/CustomUi/titleH4";
import { Switcher } from "../../../../../../../../Components/CustomUi/Switcher";
import { RowTable } from "../../../../../../../../Components/CustomUi/table/rowTable";
import { BodyTable } from "../../../../../../../../Components/CustomUi/table/bodyTable";
import { HeaderTable } from "../../../../../../../../Components/CustomUi/table/headerTable";
import { SelectMulti } from "../../../../../../../../Components/CustomUi/SelectMulti";
import { ModalEditar } from "./components/editar";
import { Selecionados } from "./components/selecionados";
import { InfoCultura } from "./components/infoCultura";
import React from "react";
import { DeleteButton } from "../../../../../../../../Components/CustomUi/deleteButton";

//TYPOS
type Tipos = {
    descricao: string;
    cod_tipo: number;
};
//PROPS
interface Props {
    id_tipo_principal: number | null;
    id_item_principal: number | null;
    nome_item_principal: string;
    estadios_selecionados_principal: {descr:string,id:number}[];

    nome_tipo_selecionado: string;
    setNome_tipo_selecionado: React.Dispatch<React.SetStateAction<string>>;
    id_tipo_selecionado: number | null;
    id_ativo_selecionado: number | null;
    setId_tipo_selecionado: React.Dispatch<React.SetStateAction<number|null>>;
    id_insumo_selecionado: number | null;
    setId_insumo_selecionado: React.Dispatch<React.SetStateAction<number|null>>;
    openDropdownTipo: boolean;
    setOpenDropdownTipo: React.Dispatch<React.SetStateAction<boolean>>;
    nome_insumo_selecionado: string;
    setNome_insumo_selecionado: React.Dispatch<React.SetStateAction<string>>;
    openDropdownInsumo: boolean;
    setOpenDropdownInsumo: React.Dispatch<React.SetStateAction<boolean>>;
    openDropdownEstadio: boolean;
    setOpenDropdownEstadio: React.Dispatch<React.SetStateAction<boolean>>;
    obs: string;
    setObs: React.Dispatch<React.SetStateAction<string>>;
    estadios_selecionados: {descr: string, id: number, key?: number}[];
    setEstadios_selecionados: React.Dispatch<React.SetStateAction<{descr: string, id: number, key?: number}[]>>;
    lista_obs: ListaObs[];
    setLista_obs: React.Dispatch<React.SetStateAction<any>>;
    todos_estadios: boolean;
    setTodos_estadios: React.Dispatch<React.SetStateAction<boolean>>;
    id_nivel_de_incompatibilidade: number;
    setId_nivel_de_incompatibilidade: React.Dispatch<React.SetStateAction<number>>;
    atualizarListaPrincipal: Function;
}

export const Incompatibilidades: React.FC<Props> = (props: Props) => {

    //REFs
    const refDeletarBtn = useRef<any>(null);

    //CONST
    const tipos: Tipos[] = [
        {descricao: 'Ingrediente ativo', cod_tipo: 1},
        {descricao: 'Insumo (produto comercial)', cod_tipo: 2},
        {descricao: 'Grupo químico', cod_tipo: 3},
        {descricao: 'Classe agronômica', cod_tipo: 4},
        {descricao: 'Formulação', cod_tipo: 5},
        {descricao: 'Cultura', cod_tipo: 6},
        // {descricao: 'Estadio cultura', cod_tipo: 7},
        {descricao: 'Biotecnologia', cod_tipo: 8},
        {descricao: 'Grupo', cod_tipo: 9}
    ];
    
    //PROPS
    const { nome_tipo_selecionado, setNome_tipo_selecionado, openDropdownTipo, 
        setOpenDropdownTipo, nome_insumo_selecionado, setNome_insumo_selecionado, 
        openDropdownInsumo, setOpenDropdownInsumo, obs, setObs, 
        setId_tipo_selecionado, setId_insumo_selecionado, id_tipo_selecionado, 
        openDropdownEstadio, setOpenDropdownEstadio, estadios_selecionados, 
        setEstadios_selecionados, id_ativo_selecionado, id_insumo_selecionado, 
        lista_obs, setLista_obs, todos_estadios, setTodos_estadios, 
        id_nivel_de_incompatibilidade, setId_nivel_de_incompatibilidade, 
        atualizarListaPrincipal, id_tipo_principal, id_item_principal, estadios_selecionados_principal, 
        nome_item_principal
    } = props;

    function limparStates() {
        setNome_tipo_selecionado('');
        setId_tipo_selecionado(null);
        setNome_insumo_selecionado('');
        setId_tipo_selecionado(null);
        setObs('');
        setLista_obs([]);
        setEstadios_selecionados([]);
        setEstadios_ja_selecionados([]);
    }

    //STATES
    const [search, setSearch] = useState<string>('');
    const [estadios_ja_selecionados, setEstadios_ja_selecionados] = useState([]);
    const [openConfirm, setOpenConfirm] = useState<boolean>(false);
    const [nome_item_atual, setNome_item_atual] = useState<string>('');
    const [id_item_atual, setId_item_atual] = useState<number | null>(null);
    const [modalEditar, setModalEditar] = useState<boolean>(false);
    const [dados_editar, setDados_editar] = useState<any>();

    //API - GET
    const { data_insumos, refetch_insumos, isFetched_insumos, carregando_insumos } = useGetRecomendacaoTipoInsumos();
    const { data_ingredi_ativos, refetch_data_ingredi_ativos, isFetched_ingredi_ativos, carregando_ingredi_ativos } = useGetRecomendacaoIngredientesAtivos();
    const { data_grupo_quimico, refetch_grupo_quimico, isFetched_grupo_quimico, carregando_grupo_quimico } = useGetRecomendacaoGrupoQuimico();
    const { data_cultura, refetch_cultura, isFetched_cultura, carregando_cultura } = useGetRecomendacaoCultura();
    const { data_tecnologia, refetch_tecnologia, isFetched_tecnologia, carregando_tecnologia } = useGetRecomendacaoTecnologia();
    const { data_estadios, refetch_estadios } = useGetEstadiosByCultura(id_insumo_selecionado);
    const { data_classe_agro, refetch_classe_agro, isFetched_tclasse_agro, carregando_classe_agro } = useGetRecomendacaoClasseAgronomica();
    const { data_formulacao, refetch_formulacao, isFetched_formulacao, carregando_formulacao } = useGetRecomendacaoFormulacao();
    const { data_grupo, refetch_grupo, isFetched_grupo, carregando_grupo } = useGetRecomendacaoGrupo();
    const { data_cadastrados, refetch_data_cadastrados, carregando_cadastrados } = useGetRecomendacaoAtivosCadastrados(id_ativo_selecionado, id_tipo_principal);
    //API -POST
    const { carregando_salvar_incompati, mutate_salvar_incompati } = usePostIncompatibilidade();
    const { carregando_deletar_compati, mutate_deletar_compati } = useDeleteCompatibilidade();

    function salvarIncompatibilidade() {
        if (id_tipo_selecionado!==6) {
            mutate_salvar_incompati({
                codConta: Number(localConfig().conta),
                id_tipo_principal: id_tipo_principal,
                id_item_principal: id_item_principal,
                id_estadio_principal: estadios_selecionados_principal?.map(item => item.id),
                cod_ativo: id_ativo_selecionado,
                tipo_compatibilidade: id_tipo_selecionado,
                cod_compatibilidade: id_insumo_selecionado,
                cod_estadios: estadios_selecionados.map((item => item.id)) ?? [],
                cod_alerta: id_nivel_de_incompatibilidade,
                obs: obs,
                limparStates: () => limparStates(),
                atualizarLista: () => refetch_data_cadastrados(),
                atualizarListaPrincipal: () => atualizarListaPrincipal()
            });
        }
        else {
            mutate_salvar_incompati({
                codConta: Number(localConfig().conta),
                id_tipo_principal: id_tipo_principal,
                id_item_principal: id_item_principal,
                id_estadio_principal: estadios_selecionados_principal?.map(item => item.id),
                cod_ativo: id_ativo_selecionado,
                tipo_compatibilidade: id_tipo_selecionado,
                cod_compatibilidade: id_insumo_selecionado,
                cod_estadios: estadios_selecionados.map((item => item.id)) ?? [],
                cod_alerta: id_nivel_de_incompatibilidade,
                obs: obs,
                limparStates: () => limparStates(),
                atualizarLista: () => refetch_data_cadastrados(),
                atualizarListaPrincipal: () => atualizarListaPrincipal()
            });
            for (const item of lista_obs) {
                mutate_salvar_incompati({
                    codConta: Number(localConfig().conta),
                    id_tipo_principal: id_tipo_principal,
                    id_item_principal: id_item_principal,
                    id_estadio_principal: estadios_selecionados_principal?.map(item => item.id),
                    cod_ativo: id_ativo_selecionado,
                    tipo_compatibilidade: id_tipo_selecionado,
                    cod_compatibilidade: id_insumo_selecionado,
                    cod_estadios: (item.estadios).map((item => item.id)) ?? [],
                    cod_alerta: id_nivel_de_incompatibilidade,
                    obs: item.obs,
                    limparStates: () => limparStates(),
                    atualizarLista: () => refetch_data_cadastrados(),
                    atualizarListaPrincipal: () => atualizarListaPrincipal()
                });
            }
        }
    };

    function deletarCompatibilidade() {
        mutate_deletar_compati({
            codConta: Number(localConfig().conta),
            cod: id_item_atual,
            limparStates: () => limparStates(),
            atualizarLista: () => refetch_data_cadastrados(),
            closePopup: () => setOpenConfirm(false)
        })
    }

    useEffect(() => {
        const refetchData = (condition, refetchFunction) => {
            if (condition) {
                refetchFunction();
            }
        };
    
        refetchData(id_tipo_selecionado === 1 && !isFetched_ingredi_ativos, refetch_data_ingredi_ativos);
        refetchData(id_tipo_selecionado === 2 && !isFetched_insumos, refetch_insumos);
        refetchData(id_tipo_selecionado === 3 && !isFetched_grupo_quimico, refetch_grupo_quimico);
        refetchData(id_tipo_selecionado === 4 && !isFetched_tclasse_agro, refetch_classe_agro);
        refetchData(id_tipo_selecionado === 5 && !isFetched_formulacao, refetch_formulacao);
        refetchData(id_tipo_selecionado === 6 && !isFetched_cultura, refetch_cultura);
        refetchData(id_tipo_selecionado === 8 && !isFetched_tecnologia, refetch_tecnologia);
        refetchData(id_tipo_selecionado === 9 && !isFetched_grupo, refetch_grupo);
        
    }, [id_tipo_principal, id_ativo_selecionado, id_tipo_selecionado]);

    useEffect(() => {
        setEstadios_ja_selecionados([]);
    }, [id_insumo_selecionado])
    

    useEffect(() => {
        if (id_ativo_selecionado !== null) {
            refetch_data_cadastrados();
        }
    }, [id_ativo_selecionado])
    

    useEffect(() => {
        if (id_insumo_selecionado !== null) {
            refetch_estadios()
        }
    }, [id_insumo_selecionado]);
    

    function obterListaTipo() {
        switch (id_tipo_selecionado) {
            case 1:
                return data_ingredi_ativos?.itens ?? [];
            case 2:
                return data_insumos?.itens ?? [];
            case 3:
                return data_grupo_quimico?.itens ?? [];
            case 4:
                return data_classe_agro?.itens ?? [];
            case 5:
                return data_formulacao?.itens ?? [];
            case 6:
                return data_cultura?.itens ?? [];
            case 8:
                return data_tecnologia?.itens ?? [];
            case 9:
                return data_grupo?.itens ?? [];
            default:
                return [];
        }
    }

    function addListaObs() {
        setEstadios_ja_selecionados(estadios_ja_selecionados=>[ ...estadios_ja_selecionados, ...estadios_selecionados]);

        setLista_obs(lista_obs=>[...lista_obs, {
            key: Date.now(),
            estadios: estadios_selecionados,
            nivel: id_nivel_de_incompatibilidade,
            obs: obs
        }]);

        setEstadios_selecionados([]);
        setObs('');
    }

    function removerDaListaObs(key: number) {
        let remove = lista_obs.filter(item=> item.key === key);
        let restante = lista_obs.filter(item=> item.key !== key);
        let IDs_removidos = (remove?.[0]?.estadios ?? []).map(item=> item.id);
        let remover_estadios_ja_selec = estadios_ja_selecionados?.filter(estadio => !IDs_removidos.includes(estadio.id));


        setEstadios_ja_selecionados(remover_estadios_ja_selec);
        setLista_obs(restante);
    }

    /**Desabilita os tipos que já foram adicionados no cadastro */
    function desabilitarSelecionados() {
        /**se não for tipo cultura */
        if (id_tipo_selecionado !== 6 && id_tipo_selecionado !== 7 && id_tipo_principal !== 6 && id_tipo_principal !== 7) {
            return (data_cadastrados?.itens ?? [])?.filter(filtrarIncompatibilidade())?.map(item=> item?.[obterCodItem(id_tipo_selecionado)]) ?? [];
        }

        /**se for do tipo cultura e se estiver com a flag `todos_estadios`. (assume que se a cultura estiver com `cod_estadio === null`, é todos os estadios) */
        let apenas_culturas = (data_cadastrados?.itens ?? [])?.filter(filtrarIncompatibilidade())?.filter(item=>item.cod_cultura!==null) ?? [];
        let apenas_todos_estadios = (apenas_culturas ?? [])?.filter(item=>item.cod_estadio===null) ?? [];
        let culturas = (apenas_todos_estadios ?? [])?.map(item=> item?.['cod_cultura']) ?? [];

        return culturas;
    }

    /**Desabilita a seleção de estádio que já foram adicionados no cadastro */
    function desabilitarEstadiosSelecionados() {
        return (data_cadastrados?.itens ?? [])?.filter(filtrarIncompatibilidade())?.filter(item=>item.cod_estadio !== null)?.map(item=> item?.['cod_estadio']) ?? [];
    }

    function obterQtdEstadiosSelecionados(cod_cultura: number) {
        let apenas_culturas = (data_cadastrados?.itens ?? [])?.filter(filtrarIncompatibilidade())?.filter(item=>item.cod_cultura!==null) ?? [];
        let cultura_selecionadas = (apenas_culturas ?? [])?.filter((item)=> item.cod_cultura === cod_cultura);
        return (cultura_selecionadas ?? [])?.length;
    }

    function desabilitarBtnSalvar(): boolean {
        if (nome_tipo_selecionado == '' || nome_insumo_selecionado == '') {
            return true;
        }
        if (id_tipo_selecionado === 6 && estadios_selecionados?.length === 0 && !todos_estadios) {
            return true;
        }

        return false;
    }

    function todosEstadiosJaForamAdd() {
        if (obterQtdEstadiosSelecionados(id_insumo_selecionado) === 0 || (data_estadios?.itens ?? []).length === 0) {
            return false;
        }
        return ( id_insumo_selecionado !== null && (data_estadios?.itens ?? []).length === obterQtdEstadiosSelecionados(id_insumo_selecionado)) ?? false;
    }

    function addTodosEstadiosManualmente() {
        if (todos_estadios) {
            setEstadios_selecionados([]);
            for (const item of (data_estadios?.itens ?? [])) {
                setEstadios_selecionados(estadios_selecionados=> [...estadios_selecionados, {
                    descr: item?.nome,
                    id: item?.cod,
                    key: item?.cod 
                }])
            }
        }
    }

    useEffect(() => {
        addTodosEstadiosManualmente();
    }, [todos_estadios]);
    
    useEffect(() => {
        if (estadios_selecionados.length < (data_estadios?.itens ?? []).length) {
            setTodos_estadios(false)
        }
    }, [estadios_selecionados]);


    function desabilitarCultura() {
        if (id_tipo_principal===6 && id_tipo_selecionado===6) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        if (id_tipo_principal===6 && id_tipo_selecionado===6) {
            setId_insumo_selecionado(id_item_principal);
            setNome_insumo_selecionado(nome_item_principal);
        }
        else {
            setId_insumo_selecionado(null);
            setNome_insumo_selecionado('');
        }
    }, [id_tipo_principal, id_item_principal, id_tipo_selecionado])
    
    
    return (
        <Container>
            <BodyDrawer style={{borderBottom: 'thin solid var(--border-color)'}}>
                    
                <div className='BodyDrawer__box' style={{borderColor: '#ff4d4f'}}>
                    <Warning type='danger'>
                        Incompatibilidade
                    </Warning>

                    
                    <Inline>
                        <Select
                            label='Selecione o tipo'
                            placeholder='Selecione o tipo'
                            value={nome_tipo_selecionado}
                            onChange={setNome_tipo_selecionado}
                            onChangeID={setId_tipo_selecionado}
                            open={openDropdownTipo}
                            trigger={setOpenDropdownTipo}
                            items={tipos}
                            icon={faNetworkWired}
                            maxHeight={250}
                            idItem={'cod_tipo'}
                            descricaoItem='descricao'
                            atual={nome_tipo_selecionado}
                            task={()=>{setNome_insumo_selecionado('');setId_insumo_selecionado(null)}}
                        />
                        <Select 
                            label={`Selecione o ${nome_tipo_selecionado!=='' ? nome_tipo_selecionado : 'item'}`}
                            value={nome_insumo_selecionado}
                            onChange={setNome_insumo_selecionado}
                            onChangeID={setId_insumo_selecionado}
                            placeholder='Selecione o tipo'
                            open={openDropdownInsumo}
                            trigger={setOpenDropdownInsumo}
                            maxHeight={250}
                            items={obterListaTipo()}
                            icon={faTag}
                            idItem={'cod'}
                            descricaoItem='nome'
                            loading={carregando_insumos || carregando_ingredi_ativos || carregando_grupo_quimico || carregando_cultura || carregando_tecnologia || carregando_classe_agro || carregando_formulacao || carregando_grupo}
                            disabled={nome_tipo_selecionado==='' || desabilitarCultura()}
                            disabledItens={desabilitarSelecionados()}
                            atual={nome_insumo_selecionado}
                            allowClear={!desabilitarCultura()}
                        />
                    </Inline>

                    {id_tipo_selecionado === 6 &&<>

                        {todosEstadiosJaForamAdd() && (
                            <Warning type='info'>
                                Todos os estádios dessa cultura já foram adicionados!
                            </Warning>
                        )}
                    
                        {/* se já tiver estadios cadastrado na cultura, remove a opção de selecionar todos, para que os novos não sobrepoiam os já cadastrados*/}
                        {obterQtdEstadiosSelecionados(id_insumo_selecionado) === 0 &&(
                            <Switcher 
                                label='Válido para todos os estádios'
                                isChecked={todos_estadios}
                                trigger={setTodos_estadios}
                            />
                        )}
                             
                        {(!todosEstadiosJaForamAdd()) &&(
                            <SelectMulti 
                                label='Selecione os estádios'
                                selecteds={estadios_selecionados}
                                onChange={setEstadios_selecionados}
                                placeholder='Selecione os estádios'
                                open={openDropdownEstadio}
                                trigger={setOpenDropdownEstadio}
                                maxHeight={250}
                                items={data_estadios?.itens ?? []}
                                descricaoItem='nome'
                                idItem='cod'
                                icon={faChartArea}
                                removeItens={estadios_ja_selecionados}
                                disabled={(data_estadios?.itens)?.length === estadios_ja_selecionados?.length}
                                disabledItens={desabilitarEstadiosSelecionados()}
                            />
                        )}
                        
                    </>}

                    
                    
                    {!todosEstadiosJaForamAdd() &&<>
                    
                        <Nivel 
                            id_nivel_de_incompatibilidade={id_nivel_de_incompatibilidade}
                            setId_nivel_de_incompatibilidade={setId_nivel_de_incompatibilidade}
                        />

                        <TextArea
                            label={`Observação da compatibilidade`}
                            placeholder='Digite aqui a observação'
                            value={obs}
                            onChange={setObs}
                            resize={false}
                            height={70}
                            disabled={disabilitarTextArea(id_tipo_selecionado, todos_estadios, estadios_selecionados, data_estadios)}
                        />

                        {(id_tipo_selecionado === 6 && !todos_estadios) &&
                            <ButtonCustom 
                                icon={<FontAwesomeIcon icon={faAdd} />}
                                onClick={()=>{addListaObs()}}
                                style={{alignSelf: 'flex-start'}}
                                type="tertiary"
                                disabled={disabilitarBtnAdd(estadios_selecionados, data_estadios)}
                            >
                                Adicionar outra observação
                            </ButtonCustom>
                        }

                        {(id_tipo_selecionado === 6 && lista_obs.length > 0 && !todos_estadios) && (
                            <Selecionados 
                                lista_obs={lista_obs}
                                removerDaListaObs={removerDaListaObs}
                                disabled={todos_estadios}
                            />
                        )}

                        <ButtonCustom 
                            icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                            onClick={()=>{salvarIncompatibilidade()}}
                            style={{alignSelf: 'flex-start'}}
                            loading={carregando_salvar_incompati}
                            disabled={carregando_salvar_incompati ? true : desabilitarBtnSalvar()}
                        >
                            Salvar incompatibilidade
                        </ButtonCustom>

                    </>}
                    
                </div>
                
            </BodyDrawer>

            <BodyDrawer>    

                <Box>
                    <TitleH4 style={{color: 'var(--text-color-light)'}}>Incompatibilidades Cadastradas</TitleH4>

                    <Input 
                        placeholder="Buscar incompatibilidade"
                        value={search}
                        onChange={setSearch}
                    />

                    {(data_cadastrados?.itens ?? []).filter(buscarCadastro(search)).filter(filtrarIncompatibilidade()).length === 0
                    ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`Nenhuma incompatibilidade cadastrada`} />
                    : <>
                        
                        {carregando_cadastrados &&(
                            <Carregando
                                legenda="Carregando lista"
                                justifyContent="center"
                                animation
                                height={100}
                            />
                        )} 

                        <HeaderTable
                            itens={[
                                {valor: 'Icompatibilidade', width: 280},
                                {valor: 'Nivel', width: 120},
                                {valor: 'Tipo', width: 280},
                                {valor: 'Opções', width: 120}
                            ]}
                        />


                    <BodyTable style={{marginTop: -10, height: (data_cadastrados?.itens ?? []).filter(buscarCadastro(search)).filter(filtrarIncompatibilidade())?.length < 7 ? 'auto' : 400}}>
                        {(data_cadastrados?.itens ?? []).filter(buscarCadastro(search)).filter(filtrarIncompatibilidade()).map((item, index)=> {
                            return (
                                <RowTable key={gerarKey(index)}>
                                    <RowItem width={280}>
                                        <span>
                                        {item.item_lancamento_nome}
                                            <InfoCultura 
                                                item={item}
                                            />
                                        </span>
                                        
                                    </RowItem>
                                    <RowItem width={120}>
                                        <Tag color={codNivelAlerta(item?.cod_alerta)[0]} ><span style={{color:codNivelAlerta(item?.cod_alerta)[1]}}>{nivelAlerta(item?.cod_alerta)}</span></Tag>
                                    </RowItem>
                                    <RowItem width={280}>
                                        {obterTipoPorId(item.item_lancamento)}
                                    </RowItem>
                                    <RowItem width={(data_cadastrados?.itens ?? []).filter(buscarCadastro(search)).filter(filtrarIncompatibilidade())?.length > 7 ? 116 : 121} gap={10}>
                                        <ButtonCustom
                                            onClick={()=>{setDados_editar(item);setModalEditar(true)}}
                                            icon={<FontAwesomeIcon icon={faPenToSquare} />}
                                            type="tertiary"
                                        >
                                        </ButtonCustom>

                                        <DeleteButton 
                                            key={gerarKey(index)}
                                            open={openConfirm && id_item_atual===item.cod}
                                            trigger={setOpenConfirm}
                                            onTrigger={()=>{setId_item_atual(item.cod);setNome_item_atual(item.item_lancamento_nome)}}
                                            loading={carregando_deletar_compati}
                                            message={`o item ${nome_item_atual} será excluido!`}
                                            deleteds={[nome_item_atual]}
                                            onDelete={()=>{deletarCompatibilidade()}}
                                            disabled={carregando_deletar_compati}
                                        />

                                        
                                    </RowItem>
                                </RowTable>
                            )
                        })}
                    </BodyTable>
                    </>
                    }
                </Box>
                
            </BodyDrawer>

            <Modal
                title={`Editar ${dados_editar?.item_lancamento_nome}`}
                width={750}
                open={modalEditar}
                onOk={()=>{}} 
                onCancel={()=>{setModalEditar(false)}}
                footer={false}
            >
                <ModalEditar
                    registros={dados_editar}
                    onClose={()=>{setModalEditar(false)}}
                    atualizarLista={()=>{refetch_data_cadastrados()}}
                    id_tipo_principal={id_tipo_principal}
                />
            </Modal>

        </Container>
    )
}