import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDownLeftAndUpRightToCenter,
  faUpRightAndDownLeftFromCenter,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'

//STYLES
import { PainelProps } from './styles'

//APP
import { leadZero } from '../../../../../utils'
import { TalhaoCultivaveisItens } from '../../../../../services/TalhaoSafra'

//COMPONENTS
import styled from 'styled-components'
import ButtonCustom from '../../../../../Components/ButtonCustom'
import { Switcher } from '../../../../../Components/CustomUi/Switcher'
import { LineDivider } from '../../../../../Components/CustomUi/LineDivider'
import { TabelaPropiedades } from '../tabela'
import { Select } from '../../../../../Components/CustomUi/Select'
import { SafraItens } from '../../../../../services/Safra'
import { localConfig } from '../../../../../Configs/localConfig'
import { Render } from '../../../../../Components/CustomUi/render'
import { Inline } from '../../../../../Components/CustomUi/inline'
import { GerarExcel } from '../../../../../Components/GerarExcel'
import GeneratePdfButton from '../../../../../Components/relatorio-component'
import {
  gerarEstruturaPDF,
  gerarEstruturaTabela,
  gerarEstruturaTabelaSecundaria,
  gerarEstruturaTotaisPDF,
} from './meta'

//PROPS
interface Props {
  exibir_area_talhao: boolean
  setExibir_area_talhao: React.Dispatch<React.SetStateAction<boolean>>
  exibir_nome_talhao: boolean
  setExibir_nome_talhao: React.Dispatch<React.SetStateAction<boolean>>
  ocultar_pontos_obs?: boolean
  setOcultar_pontos_obs?: React.Dispatch<React.SetStateAction<boolean>>
  onClose: Function
  dados_propriedades: TalhaoCultivaveisItens[]
  distinguir_cor: boolean
  setDistinguir_cor: React.Dispatch<React.SetStateAction<boolean>>
  tamanhoPropriedade: number
  margem?: number
  list_safra?: SafraItens[]
  handleVisualizarPropri?: (cod_propriedade: number, info: { key: string }, cod: string) => void
  isCadastroPropriedade?: boolean
}

export const Painel1Propriedade: React.FC<Props> = ({
  exibir_area_talhao,
  setExibir_area_talhao,
  ocultar_pontos_obs,
  setOcultar_pontos_obs,
  exibir_nome_talhao,
  setExibir_nome_talhao,
  onClose,
  dados_propriedades,
  distinguir_cor,
  setDistinguir_cor,
  tamanhoPropriedade,
  margem,
  list_safra = [],
  handleVisualizarPropri,
  isCadastroPropriedade,
}) => {
  const qtd_talhoes: number = dados_propriedades?.length ?? 0

  //STATES
  const [shrink, setShrink] = useState<boolean>(false)
  //STATES - SELECIONAR SAFRA
  const [safra_selecionada, setSafra_selecionada] = useState<string>('Padrão')
  const [open_safra, setOpen_safra] = useState<boolean>(false)

  useEffect(() => {
    return () => {
      setExibir_nome_talhao(false)
      setExibir_area_talhao(false)
      setDistinguir_cor(false)
    }
  }, [])

  function visualizarPropriedade(cod_atual_e_nome: number[] | string[]) {
    if (String(cod_atual_e_nome?.[1]) === 'Padrão') {
      let info = { key: '0', value: 0, children: 'Talhões da propriedade' }
      handleVisualizarPropri(Number(cod_atual_e_nome?.[0]), info, '')
      return
    }

    let info = {
      key: String(Number(cod_atual_e_nome?.[0])),
      value: Number(cod_atual_e_nome?.[0]),
      children: 'Talhões da propriedade',
    }

    handleVisualizarPropri(Number(cod_atual_e_nome?.[0]), info, '')
  }

  return (
    <PainelProps data-shrink={shrink} style={{ top: margem, left: margem }}>
      <div style={{ width: '100%', justifyContent: 'space-between', display: 'flex' }}>
        <ButtonCustom
          onClick={() => {
            onClose()
          }}
          type='tertiary'
          danger
          icon={<FontAwesomeIcon icon={faXmark} />}
          style={{ marginLeft: -15, alignSelf: 'flex-start' }}
        >
          Fechar
        </ButtonCustom>
        <ButtonCustom
          onClick={() => {
            setShrink(!shrink)
          }}
          type={shrink ? 'primary' : 'tertiary'}
          icon={
            <FontAwesomeIcon
              icon={shrink ? faUpRightAndDownLeftFromCenter : faDownLeftAndUpRightToCenter}
            />
          }
          style={{ marginLeft: -15, alignSelf: 'flex-start' }}
        />
      </div>

      <Render condition={isCadastroPropriedade}>
        <Select
          label='Visualizar safra'
          placeholder='Selecione a safra'
          value={undefined}
          onChange={setSafra_selecionada}
          onChangeExtra={visualizarPropriedade}
          extraItem={['cod', 'nome']}
          descricaoItem='nome'
          idItem='cod'
          items={[{ cod: 0, nome: 'Padrão' }, ...list_safra]}
          open={open_safra}
          trigger={setOpen_safra}
          atual={safra_selecionada}
          allowClear={false}
        />
      </Render>

      <LineDivider />

      <div className='PainelProps__info'>
        <ItemInfo
          title='Propriedade'
          value={`${String(dados_propriedades?.[0]?.propriedade_nome)?.toLowerCase()}`}
        />
        <ItemInfo
          title='Área total'
          // value={`${tamanhoPropriedade} ha`}
          value={`${Number(tamanhoPropriedade).toFixed(2)} ha`}
        />
        <ItemInfo title='Talhões' value={String(leadZero(qtd_talhoes))} />
      </div>

      <LineDivider />

      <TabelaPropiedades dados_propriedades={dados_propriedades} distinguir_cor={distinguir_cor} />

      <LineDivider />

      <Switcher
        isChecked={exibir_nome_talhao}
        trigger={setExibir_nome_talhao}
        label='Exibir nome dos talhões'
      />
      <Switcher
        isChecked={exibir_area_talhao}
        trigger={setExibir_area_talhao}
        label='Exibir área dos talhões'
      />
      <Switcher
        isChecked={distinguir_cor}
        trigger={setDistinguir_cor}
        label='Distinguir cor em cada talhão'
      />
      <Switcher
        isChecked={ocultar_pontos_obs}
        trigger={setOcultar_pontos_obs}
        label='Ocultar pontos de observação'
      />

      <LineDivider />

      <Inline>
        <GerarExcel
          titulo='Relatório de talhões da propriedade'
          titulo_secundario={String(dados_propriedades?.[0]?.propriedade_nome)}
          nome_do_arquivo='relatório_talhões_da_propriedade'
          usuario={JSON.parse(localStorage.getItem('Itens'))?.[0]?.email?.toLowerCase()}
          width_coluna={30}
          tabela={gerarEstruturaTabela(dados_propriedades)}
          tabela_secundaria={gerarEstruturaTabelaSecundaria(dados_propriedades)}
          disabled={dados_propriedades.length <= 0}
        />

        <GeneratePdfButton
          title={'Relatório de talhões da propriedade'}
          titleSecundario={String(dados_propriedades?.[0]?.propriedade_nome)}
          dados={gerarEstruturaPDF(dados_propriedades)}
          totais={gerarEstruturaTotaisPDF(dados_propriedades)}
          dadosPropri={[]}
          disable={dados_propriedades.length <= 0}
        />
      </Inline>
    </PainelProps>
  )
}

// -----------------------------------------------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------------------------------------

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  box-sizing: border-box;
  padding: 5px;

  p.ItemInfo__item__title {
    margin: 0;
    font-size: 13px;
    color: var(--text-color-light);
    font-weight: 500;
  }
  span.ItemInfo__item__value {
    margin: 0;
    font-size: 14px;
    color: var(--text-color);
    font-weight: 600;
    line-height: 15px;
  }
`

//PROPS
interface PropsItemInfo {
  title: string
  value: string
}

const ItemInfo: React.FC<PropsItemInfo> = ({ title, value }) => {
  return (
    <Item>
      <p className='ItemInfo__item__title'>{title}</p>
      <span
        className='ItemInfo__item__value'
        style={{
          textTransform: title === 'Propriedade' ? 'capitalize' : 'none',
          whiteSpace: title === 'Área total' ? 'nowrap' : 'normal',
        }}
      >
        {value}
      </span>
    </Item>
  )
}
