import React, { useEffect, useState } from 'react'
import pdfMake from 'pdfmake/build/pdfmake.js'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ButtonCustom from '../ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faPrint, faXmark } from '@fortawesome/free-solid-svg-icons'
import { imgFaixa, imgFaixaInvert, imgLogo } from '../../utils/base64-Imgs'
import { useAccess } from '../../context/useAccess'
import { Modal } from 'antd'
import { CustomModal } from './styled'

// Integrando as fontes do pdfMake
pdfMake.vfs = pdfFonts.pdfMake.vfs

// Definir fontes padrão
pdfMake.fonts = {
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf',
  },
}

type RelatorioProps = {
  dados?: any[]
  title?: string
  totais?: any[]
  dadosPropri?: any[]
  disable?: boolean
  titleSecundario?: string
}

const GeneratePdfButton: React.FC<RelatorioProps> = ({
  dados,
  dadosPropri,
  title,
  totais,
  disable,
  titleSecundario,
}) => {
  const dadosUser = JSON.parse(localStorage.getItem('Itens'))
  const { consultoria } = useAccess()

  const [base64Image, setBase64Image] = useState(null)
  const [pdfUrl, setPdfUrl] = useState(null)
  const [pdf, setPdf] = useState(null)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(null)
  const imageUrl = consultoria?.logo_url

  useEffect(() => {
    let headers = new Headers()
    headers.append('Cache-Control', 'no-cache')
    const convertImageToBase64 = async (url) => {
      try {
        const response = await fetch(url, { mode: 'cors', headers: headers })

        if (!response.ok) {
          console.log(`Erro: ${response.statusText}`)
        }

        const blob = await response.blob()
        const reader = new FileReader()

        reader.onloadend = () => {
          setBase64Image(reader.result)
        }

        reader.readAsDataURL(blob)
      } catch (error) {
        console.error('Error:', error)
        setBase64Image(null)
      }
    }

    convertImageToBase64(imageUrl)
  }, [imageUrl])

  const generatePDF = () => {
    setLoading(true)
    const documentDefinition = {
      pageSize: 'A4',
      pageMargins: [40, 40, 40, 60],
      content: [
        {
          text: title,
          alignment: 'center',
          bold: true,
          fontSize: 13,
        },

        base64Image
          ? {
              image: base64Image,
              width: 90,
              margin: [0, 0, 0, 3],
            }
          : { text: '', width: 90, margin: [0, 0, 0, 15] },

        { text: '' },
        {
          image: imgFaixa,
          width: 515,
          height: 5,
        },
        { text: '' },
        {
          text: consultoria?.linha1,
          fontSize: 6,
          margin: [0, 5, 0, 0],
        },
        {
          text: consultoria?.linha2,
          fontSize: 6,
          margin: [0, 2, 0, 10],
        },
        titleSecundario
          ? {
              text: titleSecundario,
              margin: [0, 10, 0, 10],
              fontSize: 11,
              alignment: 'center',
              bold: true,
            }
          : null,
        dadosPropri ? dadosPropri : null,
        { text: '', margin: [0, 5] },
        ...dados,
        { text: '', margin: [0, 5] },
        ...totais,
      ],
      footer: function (currentPage, pageCount) {
        return {
          stack: [
            { text: '' },
            {
              image: imgFaixaInvert,
              width: 515,
              height: 5,
            },
            { text: '' },
            {
              columns: [
                {
                  width: '*',
                  stack: [
                    {
                      image: imgLogo,
                      width: 50,
                      margin: [0, 3, 0, 0],
                    },
                  ],
                  alignment: 'left',
                },

                {
                  width: '*',
                  stack: [
                    {
                      text: `Gerado por: ${dadosUser[0]?.nome.toLowerCase()} - ${
                        dadosUser[0]?.email
                      }`,
                      bold: true,
                      alignment: 'center',
                      fontSize: 6,
                    },
                    {
                      text: `${new Date().toLocaleString()}`,
                      italics: true,
                      alignment: 'center',
                      fontSize: 6,
                    },
                  ],
                  margin: [0, 3, 0, 0],
                  alignment: 'center',
                },
                {
                  width: '*',
                  text: `${currentPage} de ${pageCount}`,
                  alignment: 'right',
                  fontSize: 7,
                  margin: [0, 3, 0, 0],
                },
              ],
            },
            {
              width: '*',
              text: `Licenciado para: ${consultoria?.razao_social} ${consultoria?.cpf_cnpj}`,
              alignment: 'left',
              fontSize: 5,
              margin: [0, 3, 0, 0],
            },
          ],
          margin: [40, 10, 40, 10],
        }
      },
    }
    setPdf(documentDefinition)
    // Gerar o PDF
    pdfMake.createPdf(documentDefinition).getDataUrl((dataUrl) => {
      setPdfUrl(dataUrl)
      setOpen(true)
    })

    setLoading(false)
  }

  const baixarPDF = () => {
    pdfMake.createPdf(pdf).download(title)
  }

  return (
    <div>
      <ButtonCustom
        type='secondary'
        icon={<FontAwesomeIcon icon={faPrint} />}
        onClick={generatePDF}
        disabled={disable}
        loading={loading}
      >
        Imprimir
      </ButtonCustom>
      {pdfUrl && (
        <CustomModal
          width={'70%'}
          open={open}
          onCancel={() => setOpen(false)}
          footer={
            // <div>
            //   <ButtonCustom onClick={baixarPDF} icon={<FontAwesomeIcon icon={faDownload} />}>
            //     Baixar
            //   </ButtonCustom>
            // </div>
            null
          }
          style={{ top: '0' }}
          bodyStyle={{ height: 'calc(100vh - 64px)' }}
          closeIcon={
            <ButtonCustom
              danger
              icon={<FontAwesomeIcon icon={faXmark} />}
              type='tertiary'
              onClick={() => setOpen(false)}
            >
              Fechar
            </ButtonCustom>
          }
        >
          <iframe
            src={pdfUrl}
            title='PDF Preview'
            width='100%'
            height='97%'
            style={{ border: '1px solid #ccc', marginTop: '20px' }}
          ></iframe>
        </CustomModal>
      )}
    </div>
  )
}

export default GeneratePdfButton
