import React, { useEffect, useState } from 'react'
import Input from '../../../CustomUi/input'
import {
  faCaretRight,
  faCircle,
  faFilter,
  faPen,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Select } from '../../../CustomUi/Select'
import Modal from 'antd/lib/modal/Modal'
import FormTimeline from '../form-timeline'
import { ContainerTime } from './styled'
import { deleteLog, getLogs } from '../../../../services/timeLine'
import Carregando from '../../../CustomUi/loading'
import { cores, dadosFilter } from './meta'
import { Tag } from 'antd'
import dayjs from 'dayjs'
import { decodeHtml } from '../../../../services/Afins'
import TextArea from '../../../CustomUi/textarea'
import secureLocalStorage from 'react-secure-storage'

const Timeline: React.FC = () => {
  const [openFilter, setOpenFilter] = useState(false)
  const [openForm, setOpenForm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataLogs, setDataLogs] = useState([])
  const [tempDataLogs, setTempDataLogs] = useState([])
  const [filtros, setFiltros] = useState([])
  const [tipoFilter, setTipoFilter] = useState(0)
  const [update, setUpdate] = useState(0)
  const [tipoNomeFilter, setTipoNomeFilter] = useState('')
  const [versionSelect, setVersionSelect] = useState(null)
  const [nomeBusca, setNomeBusca] = useState('')

  function abrirForm() {
    setOpenForm(true)
  }

  function substituirAsc(str: string) {
    let remove = str?.replace(/quot;|&amp;/g, '"')

    return remove ?? str
  }

  function handlerClick(id) {
    setVersionSelect((version) => (version === id ? null : id))
  }

  const getLog = async () => {
    const response = await getLogs({
      embed: 'logs',
    })
    setDataLogs(response.data.itens)
    setTempDataLogs(response.data.itens)
    setLoading(false)
  }

  const handleDelete = async (cod) => {
    const response = await deleteLog(cod)
    getLog()
    setLoading(true)
  }

  const handleCancel = () => {
    setOpenForm(false)
  }

  const handleSearch = (data) => {
    setNomeBusca(data)
    const dados = dataLogs
    const nome = data.toLowerCase()

    setTimeout(() => {
      const response = dados.filter((info) => info.titulo.toLowerCase().indexOf(nome) !== -1)
      setTempDataLogs(response)
    }, 500)
  }

  function handleFilter(cod) {
    setTipoFilter(cod)
    const dados = dataLogs
    setTimeout(() => {
      if (cod !== null) {
        const response = dados.filter((item) => item.tipo === cod)
        setTempDataLogs(response)
      } else {
        setTempDataLogs(dados)
      }
    }, 500)
  }

  function handleUpdate(cod) {
    setUpdate(cod)
    setOpenForm(true)
  }

  useEffect(() => {
    if (openForm === false) {
      setLoading(true)
      setUpdate(0)
      getLog()
    }
  }, [openForm])

  useEffect(() => {
    getLog()
    if ('https://ttivos.app' === process.env.REACT_APP_LINK) {
      const filter = dadosFilter.filter((item) => item.nome !== 'Teste' && item.nome !== 'Doc')
      setFiltros(filter)
    } else {
      setFiltros(dadosFilter)
    }
    setLoading(true)
  }, [])
  
  return (
    <ContainerTime>
      <h1 className='header-title'>Histórico de versões</h1>
      <div className='div-header-busca'>
        <Input
          width={'77%'}
          placeholder='Buscar título'
          value={nomeBusca}
          onChange={(e) => handleSearch(e)}
        />
        <Select
          width={'21%'}
          value={tipoNomeFilter}
          onChange={(e) => setTipoNomeFilter(e)}
          onChangeID={(e) => handleFilter(e)}
          open={openFilter}
          trigger={setOpenFilter}
          items={filtros}
          descricaoItem='nome'
          idItem='cod'
          placeholder='Filtro'
          icon={faFilter}
        ></Select>
      </div>
      <div className='div-principal'>
        {loading === true ? (
          <div className='loading'>
            <Carregando />
          </div>
        ) : null}
        {tempDataLogs.map((item) => {
          return (
            <div className='div-historico' key={item.cod}>
              <div className='div-historico-principal'>
                <div className='version-line'>
                  <Tag className='version' color={cores[item.tipo]}>
                    {item.versao}
                  </Tag>
                  <div className='line'></div>
                </div>
                <div
                  onClick={() => handlerClick(item.cod)}
                  className='area-info'
                  data-open={item.cod === versionSelect}
                  data-id={item.tipo}
                >
                  <div className='header-info'>
                    <div className='nome-tag'>
                      <h3 className='title-log'>{decodeHtml(item.titulo)}</h3>
                      <Tag className='tag' color={cores[item.tipo]}>
                        {item.tipo_nome}
                      </Tag>
                      {versionSelect === item.cod && (
                        <pre className='descricao'>{decodeHtml(item.descricao)}</pre>
                      )}
                    </div>
                    <div className='campo-polygon-trash'>
                      <FontAwesomeIcon icon={faCaretRight} className='polygon' />
                      <div
                        className='campo_acoes'
                        hidden={secureLocalStorage.getItem('acesso-admin') !== 1}
                      >
                        <FontAwesomeIcon
                          icon={faPen}
                          className='edit-btn'
                          data-open={item.cod === versionSelect}
                          onClick={() => handleUpdate(item.cod)}
                        />
                        <FontAwesomeIcon
                          icon={faTrash}
                          className='trash-btn'
                          data-open={item.cod === versionSelect}
                          onClick={() => handleDelete(item.cod)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='nome-autor'>
                    <p className='p-nome'>{item.usuario_nome}</p>
                    <FontAwesomeIcon icon={faCircle} className='circulo-nome-autor' />
                    <p className='p-nome'>{dayjs(item.create_time).format('DD/MM/YYYY')}</p>
                    <p className='p-nome'>{item.branch}</p>
                  </div>
                </div>
              </div>
              {item.logs.map((rec) => {
                return (
                  <div className='log-adicionar-div' key={rec.cod}>
                    <div className='circle-log'>
                      <Tag
                        color={cores[rec?.tipo === null ? item.tipo : rec.tipo]}
                        className='circle'
                      ></Tag>
                      <div className='line'></div>
                    </div>
                    <div className='area-descricao-new'>
                      <pre
                        className='descricao-new'
                        data-open={item.cod === versionSelect}
                        data-id={rec?.tipo === null ? item.tipo : rec.tipo}
                      >
                        {substituirAsc(decodeHtml(rec.descricao))}
                      </pre>
                      {rec.tipo === null ? null : (
                        <Tag className='tag' color={cores[rec.tipo]}>
                          {rec.tipo_nome}
                        </Tag>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
      <button
        className='btn-cadastrar'
        onClick={abrirForm}
        hidden={secureLocalStorage.getItem('acesso-admin') !== 1}
      >
        +
      </button>
      <Modal
        onCancel={() => setOpenForm(!openForm)}
        footer={null}
        width={600}
        open={openForm}
        closeIcon={false}
        title={false}
        destroyOnClose
      >
        <FormTimeline
          fecharModal={handleCancel}
          update={update}
          dadosLogs={dataLogs}
        ></FormTimeline>
      </Modal>
    </ContainerTime>
  )
}

export default Timeline
