
import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';

//APP
import { useLogic } from '../../../context/useLogic';
import { tryError } from '../../../services/Afins';
import { PermissoesType } from '../../../services/perfil';
import { getMoniNivelControleItem, MoniNivelItemControleItens } from '../../../services/monitoramento/nivelControleItens';

//COMPONENTS
import ViewPage from '../../../Components/ViewPage';
import DataMonitoramento from '../../../Components/monitoramento/novo-index';
import { TitleH1 } from '../../../Components/CustomUi/titleH1';
import { Container } from './styles';
import { TabelaMapaDeCalor } from './components/tabela';
import { getTalhaoCultivavel } from '../../../services/TalhaoSafra';
import { getPropriedade, PropriedadeItens } from '../../../services/Propriedades';

const Monitoramento: React.FC = () => {
  const { checkSafra } = useLogic()

  const history = useHistory()

  const [permission, setPermission] = useState<PermissoesType>();//NOSONAR
  const [visibleDrawer, setVisibleDrawer] = useState(false)
  const [dataPropriedade, setDataPropriedade] = useState<PropriedadeItens[]>([])
  const [propriedadeSelect, setPropriedadeSelect] = useState<PropriedadeItens>(null)
  const [dataNivelControle, setDataNivelControle] = useState<MoniNivelItemControleItens[]>([])
  const [loading, setLoading] = useState(false)
  const codSafra = localStorage.getItem('codSafra')

  const listPropriedade = async () => {
    try {
      setLoading(true)
      const codSafra = localStorage.getItem('codSafra')
      const codCliente = null
      const resp = await getPropriedade('', '', codCliente, codSafra)
      const dados = resp.data.itens

      const dadosFiltrados = dados.filter((item) => item.status === 1)

      setDataPropriedade(dadosFiltrados)

      setLoading(false)
    } catch (error) {
      tryError(error)
    }
  }

  const listNivelControle = async () => {
    const resp = await getMoniNivelControleItem({ pagination: -1 })
    setDataNivelControle(resp.data.itens)
  }

  const addTalhaoPro = async (cod) => {
    const filtrarCod = dataPropriedade.find((item) => item.cod === cod)
    const dados = filtrarCod

    const pro = dados
    const res = await getTalhaoCultivavel({ codPropriedade: pro.cod, embed: 'sub_area' })
    const cultivavel = res.data.itens
    message.loading('Gerando mapa...')

    let subArea = []

    cultivavel.forEach((rec) => {
      subArea = [...subArea, ...rec.sub_area]

      return subArea
    })

    if (subArea.length > 0) {
      cultivavel.push(...subArea)
    }

    for (const item of cultivavel) {
      const tal = item

      if (tal.cod_talhao !== null) {
        tal.isSubArea = true
      } else {
        tal.isSubArea = false
      }

      // @ts-ignore
      tal.kml.coordenadas = tal.kml.coordenadas.map((item) => {
        return { lat: item.latitude, lng: item.longitude }
      })
    }

    pro.talhao = cultivavel

    setPropriedadeSelect(dados)

    message.destroy()
  }

  const handleClickNew = () => {
    setVisibleDrawer(true)
  }

  const handleClickPropriedade = (cod) => {
    message.loading('Aguarde...', 999999)
    setVisibleDrawer(true)
    addTalhaoPro(cod)
    message.destroy()
  }

  const handleCloseDrawer = (bol: boolean) => {
    setVisibleDrawer(bol)
    setPropriedadeSelect(null)
  }
  useEffect(() => {
    listPropriedade()

    listNivelControle()

    const idDaSafra = checkSafra('/monitoramento/mapadecalor')

    if (!idDaSafra) {
      history.push('/home')
    }
  }, [])

  useEffect(() => {
    setLoading(true)
    listPropriedade()
  }, [codSafra])

  return (
    <Container>
      <ViewPage
        rotina={73}
        newHide
        onPermission={setPermission}
        title='Mapa de calor'
        btnClick={() => handleClickNew()}
        content={
          <DataMonitoramento
            propriedade={propriedadeSelect}
            nivelControle={dataNivelControle}
            visible={visibleDrawer}
            onClose={handleCloseDrawer}
            bookApp={0}
            resumir={false}
          />
        }
        hiddeTitle
        cardBodyStyle={{padding: 15}}
        cardMainStyle={{height: '87.5vh'}}
      >
        <TitleH1>Mapa de calor detalhado</TitleH1>

        <TabelaMapaDeCalor 
          list_propriedades={dataPropriedade}
          handleClickPropriedade={handleClickPropriedade}
          loading={loading}
        />

      </ViewPage>
    </Container>
  )
}

export default Monitoramento
