import React from 'react'
import Input from '../../../CustomUi/input'
import {
  faBicycle,
  faFilm,
  faShirt,
  faShoePrints,
  faTags,
  faUser,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons'
import { Select } from '../../../CustomUi/Select'
import { tamanhoCamisas } from './meta'
import { apenasNumeros } from '../../../../utils'
interface PropsUsuario {
  camisa?: string
  setCamisa?: React.Dispatch<React.SetStateAction<string>>
  openCamisa?: boolean
  setOpenCamisa?: React.Dispatch<React.SetStateAction<boolean>>
  camiseta?: string
  setCamiseta?: React.Dispatch<React.SetStateAction<string>>
  openCamiseta?: boolean
  setOpenCamiseta?: React.Dispatch<React.SetStateAction<boolean>>
  calcado?: string
  setCalcado?: React.Dispatch<React.SetStateAction<string>>
  calca?: string
  setCalca?: React.Dispatch<React.SetStateAction<string>>
  hobby1?: string
  setHooby1?: React.Dispatch<React.SetStateAction<string>>
  hobby2?: string
  setHooby2?: React.Dispatch<React.SetStateAction<string>>
}

const FormUsuario: React.FC<PropsUsuario> = ({
  camisa,
  setCamisa,
  camiseta,
  setCamiseta,
  openCamisa,
  setOpenCamisa,
  openCamiseta,
  setOpenCamiseta,
  calcado,
  setCalcado,
  calca,
  setCalca,
  hobby1,
  setHooby1,
  hobby2,
  setHooby2,
}) => {
  return (
    <div className='line'>
      <div className='campo__tamanhos'>
        <div className='header__tamanhos'>
          <h3>Tamanhos</h3>
        </div>
        <Input
          value={camisa}
          onChange={setCamisa}
          label='Camisa'
          placeholder='Digite o tamanho da camisa'
          icon={faShirt}
        />
        <Input
          value={camiseta}
          onChange={setCamiseta}
          label='Camiseta'
          placeholder='Digite o tamanho da camiseta'
          icon={faShirt}
        />

        <Input
          label='Calçado'
          icon={faShoePrints}
          placeholder='Digite o número do calçado'
          value={apenasNumeros(calcado)}
          onChange={setCalcado}
          width={'100%'}
          height={'30%'}
          maxLength={2}
        />
        <Input
          label='Calça'
          icon={faTags}
          placeholder='Digite o número da calça'
          value={calca}
          onChange={setCalca}
          width={'100%'}
          height={'30%'}
          maxLength={2}
        />
      </div>

      <Input
        label='Hobby principal'
        icon={faBicycle}
        placeholder='Digite seu hobby principal'
        value={hobby1}
        onChange={setHooby1}
        width={'100%'}
        height={'30%'}
      />
      <Input
        label='Hobby secundário'
        icon={faFilm}
        placeholder='Digite seu hobby secundário'
        value={hobby2}
        onChange={setHooby2}
        width={'100%'}
        height={'30%'}
      />
    </div>
  )
}

export default FormUsuario
