import React, { useEffect, useState } from 'react'
import lupaSearch from '../../../assets/svg/lupaBranca.svg'
import { localConfig } from '../../../Configs/localConfig'

import { FontAwesomeIcon as FontA } from '@fortawesome/react-fontawesome'

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Alert, Avatar, Button, Col, Form, Input, Modal, Space, Table, message } from 'antd'
import { AxiosResponse } from 'axios'
import { TypesEquipe, delEquipe, getEquipe, postAddEquipe } from '../../../services/Equipe'

import { Notification } from '../../../Components/notification'

import FormEquipe from '../../../Components/equipe'

import { pagination } from '../../../Components/Default'
import ViewPage from '../../../Components/ViewPage'
import { IconAlert } from '../../../Components/iconsAlert'
import { useAccess } from '../../../context/useAccess'
import { filterTable } from '../../../services/Afins'
import { getConsultaEmail } from '../../../services/ConsultaEmailUsuario'
import { ItensPerUserLogged } from '../../../services/login/permissaoUsuarioLoado'
import { Container } from './styled'
import NovaEquipe from '../../../Components/nova-equipe'
import dayjs from 'dayjs'
import GeneratePdfButton from '../../../Components/relatorio-component'
import { getPerfil } from '../../../services/perfil'

interface EmailFoundType {
  cod: number
  nome: string
  email: string
  cod_equipe: number | null
}

const Equipe: React.FC = () => {
  const [form] = Form.useForm()
  const [loadingTable, setLoadingTable] = useState(false)
  const [visibleForm, setVisibleForm] = useState(false)
  const [callOpen, setCallOpen] = useState(false)
  const [updateUpdateEquipe, setUpdateUpdateEquipe] = useState<number>()
  const [permissions, setPermissions] = useState<ItensPerUserLogged>()
  const [permissionsExtra, setPermissionsExtra] = useState<any>([])
  const [modalEmail, setModalEmail] = useState(false)
  const [email, setEmail] = useState('')
  const [emailNovo, setEmailNovo] = useState('')

  const [rows, setRows] = useState([])
  const [rowsRelative, setRowsRelative] = useState([])
  const [emailStatus, setEmailStatus] = useState(false)
  const [equipeGeral, setEquipeGeral] = useState([])
  const [dadosEstatisticas, setDadosEstatisticas] = useState([])
  const [dadosResultado, setDadosResultado] = useState([])
  const [listarPerfils, setListarPerfils] = useState([])
  const [emailFound, setEmailFound] = useState<EmailFoundType[]>({} as EmailFoundType[])
  const tipoAcesso = localStorage.getItem('tipo-acesso')

  const listarEquipe = async () => {
    try {
      setLoadingTable(true)
      const response: AxiosResponse<TypesEquipe> = await getEquipe({
        pagination: -1,
        status: 1,
        embed: `usuario`,
      })

      const listPerfis = await getPerfil({})
      setListarPerfils(listPerfis.data.itens)

      const dados = response.data.itens.map((rec) => {
        rec.nome_perfil_conta =
          rec.cod_perfil_conta !== null
            ? listPerfis.data.itens.find((item) => item.cod === rec.cod_perfil_conta).nome
            : null
        rec.nome_perfil_cliente_geral =
          rec.cod_perfil_cliente_geral !== null
            ? listPerfis.data.itens.find((item) => item.cod === rec.cod_perfil_cliente_geral).nome
            : null

        return rec
      })
      gerarTabela(response.data.itens)
      gerarTabela2([], response.data.itens.length)
      setRows(dados)
      setRowsRelative(dados)
      if (response.status === 200) {
        setLoadingTable(false)
      }
      setEquipeGeral(dados)
    } catch (error) {
      return error
    }
  }

  function gerarTabela(dados) {
    const data = dados?.map((item) => {
      return [
        { text: item?.usuario_nome, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'left' },
        { text: item?.email, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'left' },
        {
          text: item?.usuario[0]?.nascimento
            ? dayjs(item?.usuario[0]?.nascimento).format('DD/MM/YYYY')
            : '',
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'center',
        },
      ]
    })

    const table = [
      {
        table: {
          headerRows: 1,
          widths: ['*', '*', 130],
          body: [
            [
              { text: 'Nome', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Email', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Data de nascimento', style: 'tableHeader', fontSize: 9, alignment: 'center' },
            ],
            ...data,
          ],
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#f5f5f5' : null
          },
        },
      },
    ]
    setDadosEstatisticas(table)
  }

  function gerarTabela2(dados, length) {
    const data = [[{ text: length, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' }]]

    const table = [
      {
        table: {
          headerRows: 1,
          widths: [179],
          body: [
            [
              {
                text: 'Total membros na equipe',
                style: 'tableHeader',
                fontSize: 9,
                alignment: 'center',
              },
            ],
            ...data,
          ],
          margin: [250, 0, 0, 0],
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#f5f5f5' : null
          },
        },
      },
    ]
    setDadosResultado(table)
  }

  const handleClickVisuEquipe = async (data) => {
    setVisibleForm(true)
    setCallOpen(true)
    setUpdateUpdateEquipe(data)
  }

  const handlesPesquisa = (data) => {
    setLoadingTable(true)
    const dados = rows
    const nome: any = data.target.value.toLowerCase()

    setTimeout(() => {
      const response = dados.filter((info) => info.usuario_nome.toLowerCase().indexOf(nome) !== -1)
      setRowsRelative(response)
      setLoadingTable(false)
    }, 500)
  }
  const handleTrashEquipe = async (data) => {
    const responsePro: AxiosResponse<TypesEquipe> = await getEquipe({
      cod: data,
    })
    const nomePro = responsePro.data.itens[0].usuario_nome

    try {
      Modal.confirm({
        title: 'Excluir',
        icon: <IconAlert type='confirm' size={6} />,
        content: `Deseja realmente remover o membro ${nomePro} da equipe?`,
        okText: 'Confirmar',
        cancelText: 'Cancelar',
        cancelButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: async () => {
          const responseDel = await delEquipe(data)

          if (responseDel.status === 200) {
            Notification({
              message: 'Membro excluído com sucesso',
              type: 'success',
            })

            listarEquipe()
          }
        },
      })
    } catch (error) {
      if (error.response) {
        const { data } = error.response

        Notification({
          type: 'error',
          message: 'Erro',
          descricao: data.error,
        })
      }
    }
  }

  const handleVisibleForm = () => {
    setVisibleForm(true)
    setUpdateUpdateEquipe(0)
  }

  const handleIncludeEquipe = async (email) => {
    if (email != '') {
      message.loading('Verificando...')
      setEmailStatus(false)
      const resp = await getConsultaEmail({ email })

      setEmailFound(resp.data.itens)
      setEmailStatus(true)
      message.destroy()
    } else {
      Notification({
        message: 'Preencha o campo de pesquisa antes de buscar!',
        type: 'error',
      })
    }
  }

  const handleAddEquipe = async (codUser) => {
    try {
      message.loading('Aguarde')
      const resp = await postAddEquipe({ cod_usuario: codUser })

      if (resp.status === 200) {
        Notification({
          message: 'Usuário inserido na equipe',
          type: 'success',
        })
        listarEquipe()
        setModalEmail(false)
        setEmail('')
        message.destroy()
      }
    } catch (error) {
      message.destroy()

      if (error.response) {
        const { data } = error.response
        if (!data?.error[0]?.field) {
          Notification({
            type: 'error',
            message: 'Erro',
            descricao: data.error,
          })
        } else {
          data.error.forEach((data) => {
            Notification({
              type: 'error',
              message: 'Erro',
              descricao: data.msg[0],
            })
          })
        }
      }
    }
  }

  useEffect(() => {
    listarEquipe()
  }, [])

  function obterPerfilAcessoCliente(cod_perfil_cliente: number): string {
    
    let perfilEncontrado = listarPerfils?.find((item) => item?.cod === cod_perfil_cliente)
    return perfilEncontrado?.nome ?? ''
  }

  return (
    <Container>
      <ViewPage
        rotina={4}
        onPermission={setPermissions}
        title='Equipe'
        btnClick={() => setModalEmail(true)}
        inputChange={handlesPesquisa}
        search
        rotinaExtra={[66, 77, 69]}
        onPermissionsExtra={setPermissionsExtra}
        btnsExta={
          <div>
            {localStorage.getItem('tipo-acesso') === 'conta' ? (
              <GeneratePdfButton
                dados={dadosEstatisticas}
                title={'Relatório de equipe'}
                disable={equipeGeral.length <= 0}
                totais={dadosResultado}
              />
            ) : null}
          </div>
        }
        content={
          // <FormEquipe
          //   permissions={permissions}
          //   acessoPermissoes={permissionsExtra}
          //   emailNew={email}
          //   visible={visibleForm}
          //   setOnClose={setVisibleForm}
          //   update={updateUpdateEquipe}
          //   setUpdate={setUpdateUpdateEquipe}
          //   atualizar={listarEquipe}
          //   equipeGeral={equipeGeral}
          //   callFunctionPropriedades={callOpen}
          //   setCloseCallFunction={setCallOpen}
          //   // handleSerachPropriedades={}
          // />
          <NovaEquipe
            abrir={visibleForm}
            permissons={permissions}
            onClose={setVisibleForm}
            atualizar={listarEquipe}
            emailNew={emailNovo}
            update={updateUpdateEquipe}
            equipeGeral={equipeGeral}
          ></NovaEquipe>
        }
      >
        <Table
          rowKey='cod'
          loading={loadingTable}
          scroll={{ y: 'calc(90vh - 275px)', x: 'auto' }}
          footer={() => (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'right',
              }}
            >
              <b>Total geral: {rows.length}</b>
            </div>
          )}
          bordered
          size='small'
          pagination={pagination}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                handleClickVisuEquipe(record.cod)
              },
            }
          }}
          dataSource={rowsRelative}
          columns={[
            {
              dataIndex: 'usuario_nome',
              key: 'Foto',
              title: 'Foto',
              width: '49px',
              render: (foto: string) => {
                
                let img
                if (foto === '') {
                  img = null
                } else {
                  img = foto
                }
                
                const nome = foto.split(' ')
                return (
                  <Avatar src={img}>
                    <b>
                      {nome[0]?.substring(0, 1).toUpperCase()}
                      {nome[1]?.substring(0, 1).toUpperCase()}
                    </b>
                  </Avatar>
                )
              },
            },
            {
              dataIndex: 'usuario_nome',
              title: 'Nome',
              render: (nome) => <div style={{ minWidth: '150px' }}>{nome}</div>,
            },
            ...(localConfig().codCliente == ''
              ? [
                  {
                    dataIndex: 'nome_perfil_conta',
                    title: 'Perfil consultoria',
                    render: (data) => <div style={{ minWidth: '150px' }}>{data}</div>,
                    ...filterTable(true, true, 'nome_perfil_conta', rowsRelative),
                  },
                  {
                    dataIndex: 'nome_perfil_cliente_geral',
                    title: 'Perfil cliente geral',
                    render: (data) => <div style={{ minWidth: '175px' }}>{data}</div>,
                    ...filterTable(true, true, 'nome_perfil_cliente_geral', rowsRelative),
                  },
                ]
              : [
                  {
                    dataIndex: 'cod_perfil_cliente',
                    title: 'Perfil de acesso',
                    render: (data) => (
                      <div style={{ minWidth: '150px' }}>{obterPerfilAcessoCliente(data)}</div>
                    ),
                    ...filterTable(true, true, 'cod_perfil_cliente', rowsRelative),
                  },
                ]),

            {
              title: 'Ações',
              align: 'center',
              dataIndex: 'cod',
              key: 'acao',
              width: '90px',
              render: (data) => (
                <Space>
                  <Button
                    shape='circle'
                    hidden={permissions?.alteracao === 0 && permissions?.acesso === 0}
                    onClick={() => handleClickVisuEquipe(data)}
                    icon={<FontA icon={permissions?.acesso === 1 ? solid('eye') : solid('pen')} />}
                    size='middle'
                  />
                  <Button
                    shape='circle'
                    hidden={permissions?.exclusao === 0}
                    onClick={() => handleTrashEquipe(data)}
                    className='trash-button'
                    type='primary'
                    icon={<FontA icon={solid('trash')} />}
                    size='middle'
                  />
                </Space>
              ),
            },
          ]}
        />

        <Modal
          open={modalEmail}
          onCancel={() => {
            setModalEmail(false)
            setEmailStatus(false)
            setEmail('')
            form.resetFields(['email'])
          }}
          title='Insira um email válido'
          footer={false}
        >
          <Form form={form}>
            <Form.Item
              name='email'
              rules={[
                {
                  type: 'email',
                  message: 'Email inválido',
                },
              ]}
            >
              <div style={{ display: 'flex' }}>
                <Input
                  style={{ width: '95%' }}
                  onChange={(e) => {
                    setEmail(e.target.value), setEmailNovo(e.target.value)
                  }}
                  value={email}
                />
                <Button
                  style={{
                    backgroundColor: '#5DA57D',
                    marginLeft: '5px',
                    width: '5%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={() => handleIncludeEquipe(email)}
                >
                  <img style={{ height: '70%' }} src={lupaSearch} />
                </Button>
              </div>
            </Form.Item>
            <Col>
              {emailStatus && email ? (
                emailFound.length > 0 ? (
                  emailFound[0]?.cod_equipe === null ? (
                    <Alert
                      type='success'
                      message='Usuário encontrado:'
                      description={
                        <Space direction='vertical'>
                          <Space direction='vertical'>
                            <b>{emailFound[0].nome}</b>
                          </Space>
                          Deseja incluir na equipe?{' '}
                        </Space>
                      }
                      showIcon
                      action={
                        <Space>
                          <Button onClick={() => handleAddEquipe(emailFound[0].cod)} type='primary'>
                            Sim
                          </Button>
                          <Button
                            onClick={() => {
                              setModalEmail(false)
                              setEmailStatus(false)
                              setEmail('')
                            }}
                            type='primary'
                            danger
                          >
                            Não
                          </Button>
                        </Space>
                      }
                    />
                  ) : (
                    <Alert
                      type='error'
                      message='Usuário já pertence a equipe!'
                      showIcon
                      description=' '
                      action={
                        <Space>
                          <Button
                            onClick={() => {
                              setModalEmail(false)
                              setEmailStatus(false)
                              setEmail('')
                            }}
                            type='primary'
                            danger
                          >
                            Sair
                          </Button>
                        </Space>
                      }
                    />
                  )
                ) : (
                  <Alert
                    type='info'
                    description=' '
                    message='Email não encontrado na base de dados ttivos, deseja incluir na equipe?'
                    showIcon
                    action={
                      <Space>
                        <Button
                          type='primary'
                          onClick={() => {
                            setModalEmail(false)
                            handleVisibleForm()
                            setEmailStatus(false)
                            setEmail('')
                          }}
                        >
                          Sim
                        </Button>
                        <Button
                          onClick={() => {
                            setModalEmail(false)
                            setEmailStatus(false)
                            setEmail('')
                          }}
                          type='primary'
                          danger
                        >
                          Não
                        </Button>
                      </Space>
                    }
                  />
                )
              ) : null}
            </Col>
          </Form>
        </Modal>
      </ViewPage>
    </Container>
  )
}

export default Equipe
