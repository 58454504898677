import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Empty, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faGear, faGears } from "@fortawesome/free-solid-svg-icons";

//APP
import { AddEngrenagem, verificaSeEngrenagemJaExiste, ordenar } from "./meta";
import { TypeListaEngrenagem } from "../../../../../..";
import { apenasNumeros, leadZero } from "../../../../../../../../../utils";

//COMPONENTS
import Tabs from "../../../../../../../../../Components/Tabs";
import Label from "../../../../../../../../../Components/Label";
import Input from "../../../../../../../../../Components/CustomUi/input";
import ButtonCustom from "../../../../../../../../../Components/ButtonCustom";
import { Box } from "../../../../../../../../../Components/CustomUi/box";
import { Render } from "../../../../../../../../../Components/CustomUi/render";
import { Inline } from "../../../../../../../../../Components/CustomUi/inline";
import { TitleH4 } from "../../../../../../../../../Components/CustomUi/titleH4";
import { RowItem } from "../../../../../../../../../Components/CustomUi/table/rowItem";
import { RowTable } from "../../../../../../../../../Components/CustomUi/table/rowTable";
import { BodyTable } from "../../../../../../../../../Components/CustomUi/table/bodyTable";
import { LineDivider } from "../../../../../../../../../Components/CustomUi/LineDivider";
import { PopOverEditar } from "./components/editar";
import { PopOverDeletar } from "./components/deletar";

//STYLES
const ErrorSpan = styled.span`
    font-weight: 500;
    color: red;
    margin-top: -10px;
    font-size: 13px;
    line-height: 13px;
`;

//PROPS
interface Props {
    titulo: 'Recâmbio' | 'Lateral de entrada' | 'Lateral de Saída (1)' | 'Lateral de Saída (2)';
    lista_engrenagens: TypeListaEngrenagem[];
    lista_engrenagens_completa: TypeListaEngrenagem[];
    setLista_engrenagens: React.Dispatch<React.SetStateAction<TypeListaEngrenagem[]>>;
    categoria: 'Semente' | 'Fertilizante' | 'Geral';
    cod_patrimonio: number;
    necessario_add_movida: boolean;
    setNessecario_add_Movida: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ColunaEngrenagens: React.FC<Props> = ({ titulo, lista_engrenagens, lista_engrenagens_completa, 
    setLista_engrenagens, categoria, cod_patrimonio, necessario_add_movida, setNessecario_add_Movida }) => {

    //REF
    const refInputDentes = useRef(null);

    //STATES
    const [tipo_engrenagem, setTipo_engrenagem] = useState<'Motriz' | 'Movida'>('Motriz');
    const [quantidade_dentes, setQuantidade_dentes] = useState<string>('');
    const [open_editar, setOpen_editar] = useState<boolean>(false);
    const [open_deletar, setOpen_deletar] = useState<boolean>(false);
    const [item_selecionado, setItem_selecionado] = useState<number>(null);
    const [engrenagem_ja_existe, setEngrenagem_ja_existe] = useState<boolean>(false);

    useEffect(() => {
        const lista_motriz = lista_engrenagens?.filter(item => item.nome_tipo === 'Motriz') || [];
        const lista_movida = lista_engrenagens?.filter(item => item.nome_tipo === 'Movida') || [];
    
        setNessecario_add_Movida(lista_motriz.length > 0 && lista_movida.length === 0);
    }, [lista_engrenagens]);
    
    useEffect(() => {
        verificaSeEngrenagemJaExiste(lista_engrenagens, tipo_engrenagem, Number(quantidade_dentes), setEngrenagem_ja_existe)
    }, [quantidade_dentes, tipo_engrenagem])

    function isDisabled() {
        return (quantidade_dentes==='' || isNaN(Number(quantidade_dentes)) || engrenagem_ja_existe)
    }
    
    return (
        <Box style={{maxWidth: 257}}>
            <TitleH4>
                {titulo} 
            </TitleH4>
            
            <Render condition={necessario_add_movida}>
                <Tooltip title="Ao adicionar uma engrenagem motriz, é obrigatório informar também pelo menos uma engrenagem movida.">
                    <ErrorSpan>Necessário informar ao menos uma engrenagem Movida</ErrorSpan>
                </Tooltip>
            </Render>
               
            <Tabs
                tabs={[{ descricao: 'Motriz', icone: faGear }, { descricao: 'Movida', icone: faGears }]}
                indexInicial={tipo_engrenagem === 'Motriz' ? 0 : 1}
                selecionado={tipo_engrenagem}
                trackWidth={108}
                onChange={setTipo_engrenagem}
                style={{marginTop: -8}}
            />

            <Inline>
                <form style={{all: 'inherit'}} onSubmit={(e)=> {
                    e.preventDefault();
                    AddEngrenagem(
                        titulo,
                        lista_engrenagens,
                        setLista_engrenagens,
                        categoria,
                        tipo_engrenagem,
                        Number(quantidade_dentes),
                        isDisabled()
                    );
                    setQuantidade_dentes(isDisabled() ? quantidade_dentes : '');
                    (refInputDentes.current).focus()
                }}>
                    <Input 
                        placeholder="Qtde de dentes"
                        value={apenasNumeros(quantidade_dentes)}
                        onChange={setQuantidade_dentes}
                        icon={tipo_engrenagem==='Motriz' ? faGear : faGears}
                        width={170}
                        allowClear={false}
                        error={engrenagem_ja_existe}
                        errorMessage="Quantidade já cadastrada"
                        refProp={refInputDentes}
                    />
                    <ButtonCustom 
                        icon={<FontAwesomeIcon icon={faAdd} />}
                        isCircle
                        onClick={()=> { AddEngrenagem(
                                titulo,
                                lista_engrenagens,
                                setLista_engrenagens,
                                categoria,
                                tipo_engrenagem,
                                Number(quantidade_dentes)
                            );
                            setQuantidade_dentes(''); 
                            (refInputDentes.current).focus()
                        }}
                        disabled={isDisabled()}
                    />
                </form>
            </Inline>

            <LineDivider />

            <Label fontSize={13}>
                Quantidades de dentes:
            </Label>

            <BodyTable
                style={{
                    overflowY: 'scroll',
                    maxHeight: 170
                }}
            >
                {(ordenar(lista_engrenagens) ?? []).filter(item=>item.nome_tipo===tipo_engrenagem).reverse().map(({ quantidade_dentes, is_new, cod }, index)=> {
                    return (
                        <RowTable key={index} style={{height: 40}}>
                            <RowItem gap={10}>
                                <FontAwesomeIcon icon={tipo_engrenagem==='Motriz' ? faGear : faGears} style={{opacity: 0.3}} /> {is_new ? <b>{leadZero(quantidade_dentes)}</b> : <>{leadZero(quantidade_dentes)}</>}
                            </RowItem>
                            <RowItem>
                                
                                <PopOverEditar 
                                    cod_patrimonio={cod_patrimonio}
                                    cod_item={cod}
                                    open_editar={open_editar}
                                    setOpen_editar={setOpen_editar}
                                    item_selecionado={item_selecionado}
                                    setItem_selecionado={setItem_selecionado}
                                    tipo_engrenagem={tipo_engrenagem}
                                    quantidade_atual={String(quantidade_dentes)}
                                    lista_engrenagens={lista_engrenagens}
                                    setLista_engrenagens={setLista_engrenagens}
                                    lista_engrenagens_completa={lista_engrenagens_completa}
                                    is_new={is_new}
                                />
                                <PopOverDeletar
                                    cod_item={cod}
                                    open_deletar={open_deletar}
                                    setOpen_deletar={setOpen_deletar}
                                    item_selecionado={item_selecionado}
                                    setItem_selecionado={setItem_selecionado}
                                    lista_engrenagens={lista_engrenagens}
                                    lista_engrenagens_completa={lista_engrenagens_completa}
                                    setLista_engrenagens={setLista_engrenagens}
                                    quantidade_atual={String(quantidade_dentes)}
                                    is_new={is_new}
                                />
                            </RowItem>
                        </RowTable>
                    )
                })}

                <Render condition={(lista_engrenagens.filter(item=>item.nome_tipo===tipo_engrenagem)).length === 0}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<>Nenhuma engrenagem ({tipo_engrenagem}) foi informada!</>} />
                </Render>
            </BodyTable>
        </Box>
    )
}