import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { GoogleMap, InfoBox, Polygon, Marker } from '@react-google-maps/api'
import { Button, Card, Form, Input, message, Modal, RefSelectProps, Switch } from 'antd'

//STYLES
import { Container, ContainerMap } from './styled'

//APP
import { useLogic } from '../../../context/useLogic'
import { useAccess } from '../../../context/useAccess'
import { paleta_cores } from './meta'
import { formatarValor, gerarKey } from '../../../utils'
import { getPropriedade, useGetPontosObs } from '../../../services/Propriedades'
import { ItensPerUserLogged } from '../../../services/login/permissaoUsuarioLoado'
import { centerMap, getBounds, tryError } from '../../../services/Afins'
import { getTalhao, getTalhaoPorSafra, TalhaoItens } from '../../../services/Talhao'
import {
  delTalhaoCultivavel,
  getKmlTalhaoCultivavel,
  getTalhaoCultivavel,
  postObservacaoTalhao,
  TalhaoCultivaveisItens,
} from '../../../services/TalhaoSafra'

//COMPONENTS
import ViewPage from '../../../Components/ViewPage'
import DesenhaTalhao from '../../../Components/desenhoTalhao'
import LinhaPlanejada from '../../../Components/linhaPlanejada'
import FormTalhaoSafra from '../../../Components/talhaoSafra/novo-index'
import ImporteKmlTalhao from '../../../Components/importKmlTalhao'
import { TitleH1 } from '../../../Components/CustomUi/titleH1'
import { IconAlert } from '../../../Components/iconsAlert'
import { Notification } from '../../../Components/notification'
import { Painel1Propriedade } from './components/painel'
import { TabelaListaTalhoes } from './components/tabelaListaTalhoes'
import { gerarBaixarlKmlPolygon } from '../../../Components/gerarKml'
import { PainelTodasPropriedades } from './components/painelTodasPropriedades'
import { Render } from '../../../Components/CustomUi/render'
import { GavetaPontoObs } from './components/painelPontosObs'

interface TypesMapa {
  zoom: number
  center: {
    lat: number
    lng: number
  }
  style?: { [key: string]: string }
}

interface TypesVisuPropri {
  name?: string
  nome_proprietario?: string
  cod?: number
  cor?: string
  areaPropriedade?: number
  talhoes?: TalhaoItens[]
}

const TalhaoSafra: React.FC = () => {
  const history = useHistory()
  const { checkSafra, safraSelecionada } = useLogic()
  const { atualizarArea, setAtualizarArea } = useAccess()

  const [form] = Form.useForm()

  const refSelect = useRef<RefSelectProps>()
  refSelect.current

  //STATES
  const [dataTalhao, setDataTalhao] = useState([])
  const [observacao, setObservacao] = useState('')
  const [dataPropriedade, setDataPropriedade] = useState([])
  const [delListTalhao, setDelListTalhao] = useState([])
  const [codTalhaoSelec, setCodTalhaoSelec] = useState([])
  const [talhoesCultivaveis, setTalhoesCultivaveis] = useState<TalhaoCultivaveisItens[]>([])
  const [polygonTalhao, setPolygonTalhao] = useState([])
  const [polygon, setPolygon] = useState<google.maps.Polygon>()
  const [map, setMap] = useState<google.maps.Map>()
  const [loadingVisuPropriedades, setLoadingVisuPropriedades] = useState(false)

  const talhaoSelecionado: TalhaoCultivaveisItens = {} as TalhaoCultivaveisItens
  const [loadingTable, setLoadingTable] = useState(false)
  const [visibleForm, setVisibleForm] = useState(false)
  const [modalTalhao, setModalTalhao] = useState(false)
  const [infoBoxNameTalhao, setInfoBoxNameTalhao] = useState(true)
  const [selectTalhoes, setSelectTalhoes] = useState(false)
  const [infoBoxAreaTalhao, setInfoBoxAreaTalhao] = useState(true)

  const infoWindow = new window.google.maps.InfoWindow()
  const [openMapLine, setOpenMapLine] = useState(false)
  const [visualizarPropriedade, setVisualizarPropriedade] = useState(false)
  const [openModalObservacao, setOpenModalObservacao] = useState(false)
  const [udateTalhao, setUpdateTalhao] = useState(0)
  const [codPro, setCodPro] = useState<number>(0)
  const [loadingTalhaoCultivo, setLoadingTalhaoCultivo] = useState(false)
  const [getVisibleDesenhaTalalhao, setVisibleDesenhaTalalhao] = useState(false)
  const [sizePropriedade, setSizePropriedade] = useState(0)

  const [permissions, setPermissions] = useState<ItensPerUserLogged>()

  const [dataPolygon, setDataPolygon] = useState([])
  const [dataTalhoesPolygon, setDataTalhoesPolygon] = useState([])

  const [updateSelect, setUpdateSelect] = useState<any>()

  const [nomePropriedade, setNomePropriedade] = useState<string>()

  //TODAS PROPRIEDADES
  const [dataVisuPropridades, setDataVisuPropridades] = useState<TypesVisuPropri[]>([])
  const [visualizarPropriedadesCliente, setVisualizarPropriedadesCliente] = useState(false)
  const [tempPolygon, setTempPolygon] = useState<any>()
  const [exibir_area_talhao, setExibir_area_talhao] = useState<boolean>(false)
  const [exibir_nome_talhao, setExibir_nome_talhao] = useState<boolean>(false)
  const [distinguir_cor, setDistinguir_cor] = useState<boolean>(false)
  const [talhao_selecionado, setTalhao_selecionado] = useState<number | null>(null)
  const [dadosEstatisticas, setDadosEstatisticas] = useState([])
  const [dadosResultado, setDadosResultado] = useState([])
  const [markerDefault, setMarkerDefault] = useState<
    {
      cod?: number
      nome?: string
      cod_talhao: number
      latitude: number
      longitude: number
      alerta: string
      observacao: []
    }[]
  >([])
  const [marker, setMarker] = useState<google.maps.Marker[]>([])
  const [open, setOpen] = useState(false)
  const [obsPontos, setObsPontos] = useState([])
  const [codObs, setCodObs] = useState(null)
  const [loading, setLoading] = useState(false)
  const [ocultar_pontos_obs, setOcultar_pontos_obs] = useState<boolean>(false)

  const [propsMap, setPropsMapa] = useState<TypesMapa>({
    zoom: 2,
    center: {
      lat: 0,
      lng: 0,
    },
    style: { width: '100%', height: '88vh' },
  })
  const [propsMapTodasPropriedades, setPropsMapaTodasPropriedades] = useState<TypesMapa>({
    zoom: 2,
    center: {
      lat: 0,
      lng: 0,
    },
    style: { width: '100%', height: '88vh' },
  })

  const codCliente = localStorage.getItem('cod-cliente')
  const codSafra = localStorage.getItem('codSafra')

  const { data_pontos_obs, refetch_pontos_obs } = useGetPontosObs({
    codConta: 1,
    codCliente: codCliente,
    codPropriedade: codPro,
    codSafra: codSafra,
    embed: 'observacao',
  })

  const url = useRef(null)

  const handleClickVisuTalhao = (codPro, codTalhao) => {
    setVisibleForm(true)
    setUpdateTalhao(codTalhao)
    setCodPro(codPro)
  }

  const handleVisibleForm = () => {
    setVisibleForm(true)
    setUpdateTalhao(0)
  }

  function fecharGavetaPontos() {
    setOpen(false)
    setLoading(false)
  }

  let tempMarker: google.maps.Marker[] = []
  const handleOnloadMarker = (e: google.maps.Marker, i) => {
    if (i === 0) {
      tempMarker = []
    }
    tempMarker = [...tempMarker, e]
    if (markerDefault.length === i + 1) {
      setMarker(tempMarker)
    }
  }

  function abrirGavetaPontos(obs) {
    setOpen(true)
    setLoading(true)
    const dadosObs = [
      {
        obs: obs?.observacao,
        nome_ponto: obs?.nome,
        alerta: obs?.alerta,
      },
    ]
    setObsPontos(dadosObs)
   
    setCodObs(obs?.observacao[0]?.cod_ponto_obs)
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }

  /* Função é chamada na hora que clica no talhão */
  const handleClickPolygon = (e: google.maps.PolyMouseEvent, index) => {
    const position = e.latLng.toJSON()
    const talhao = talhoesCultivaveis.find((data, i) => i === index)

    const content = `<h5><b>Nome</b>: ${talhao.nome} <br /> <b>Área</b>: ${talhao.area}/ha <br /> `

    const dados = JSON.parse(JSON.stringify(dataPolygon))

    dados.forEach((data, i) => {
      if (i === index) {
        data.cor = 'orange'
      } else {
        data.cor = 'cyan'
      }
    })

    // <b>Lat:</b> ${position.lat.toFixed(6)} <b>Lng:</b> ${position.lng.toFixed(6)}</h5>
    if (infoWindow) {
      infoWindow.close()
    }

    infoWindow.setContent(content)
    infoWindow.setPosition(position)

    infoWindow.open({
      anchor: polygon,
      map,
      shouldFocus: true,
    })
    setDataPolygon(dados)
  }

  const handleClickPolygonTodasProp = (
    e: google.maps.PolyMouseEvent,
    index,
    cliente = null,
    indexT = null
  ) => {
    if (tempPolygon) {
      tempPolygon.close()
    }
    const position = e.latLng.toJSON()
    let content
    if (cliente === null) {
      const talhao = talhoesCultivaveis.find((data, i) => i === index)

      content = `<h5><b>Nome</b>: ${talhao.nome} <br /> <b>Área</b>: ${talhao.area}/ha <br /> `

      const dados = JSON.parse(JSON.stringify(dataPolygon))

      dados.forEach((data, i) => {
        if (i === index) {
          data.cor = 'orange'
        } else {
          data.cor = form.getFieldValue('safra') !== 0 ? 'yellow' : 'cyan'
        }
      })

      setDataPolygon(dados)
    } else {
      const listTalhoesVis = [...dataVisuPropridades]

      listTalhoesVis.forEach((info, i) => {
        info.talhoes.forEach((record, ii) => {
          if (indexT === ii && index === i) {
            record.cor = 'orange'
          } else {
            record.cor = 'cyan'
          }
        })
      })
      content = `<h5}>
                    <b>Propriedade</b>: ${cliente.name} <br /> 
                    <b>Área da propriedade</b>: ${cliente.areaPropriedade}/ha <br /> 
                    <br />
                    <b>Nome do talhão</b>: ${cliente.nome_talhao} <br />
                    <b>Área do talhão</b>: ${cliente.area}/ha <br />`
      setDataVisuPropridades([...listTalhoesVis])
    }
    // <b>Lat:</b> ${position.lat.toFixed(6)} <b>Lng:</b> ${position.lng.toFixed(6)}</h5>

    infoWindow.setContent(content)
    infoWindow.setPosition(position)
    infoWindow.open({
      anchor: polygon,
      map,
      shouldFocus: true,
    })
    setTempPolygon(infoWindow)
  }

  const handleChangeNameTalhao = (data) => {
    setInfoBoxNameTalhao(data)
  }
  const handleSelectTodosTalhoes = (data) => {
    setSelectTalhoes(data)
  }

  const handleChangeAreaTalhao = (data) => {
    setInfoBoxAreaTalhao(data)
  }

  const listarPropriedades = async () => {
    setLoadingTable(true)
    const response = await getPropriedade('')
    const dados = response.data.itens
    setDataPropriedade(dados)
    if (dados.length === 1) {
      setUpdateSelect(dados[0].cod)
      listTalhao(dados[0].cod, undefined)
    } else {
      setUpdateSelect(null)
      setCodPro(0)
    }
    setLoadingTable(false)
  }

  const listTalhao = async (data, event) => {
    if (data) {
      setUpdateSelect(data)
      setLoadingTable(true)
      const response = await getTalhaoCultivavel({ codPropriedade: data })
      setDataTalhao(response?.data.itens)
      setLoadingTable(false)
      somarValores(response.data.itens, response.data.itens.length)
      gerarTabela(response.data.itens)
      setCodPro(data ?? 0)
      const dataPro = dataPropriedade.find((record) => record.cod === data)
      setNomePropriedade(dataPro?.nome)
      form.setFieldsValue({ propriedade: data })
    } else {
      setDataTalhao([])
      form.setFieldsValue([])
      setUpdateSelect(data)
      setCodPro(0)
    }
  }

  function somarValores(dados, totais) {
    const resultado = dados.reduce(
      (soma, item) => {
        //@ts-ignore
        return {
          //@ts-ignore

          area: soma.area + parseFloat(item.area),
        }
      },
      {
        area: 0,
      }
    )

    const resultadoFinal = [
      {
        totalizado_area: formatarValor(resultado?.area),
      },
    ]

    gerarTabela2(resultadoFinal, totais)
  }

  function gerarTabela(dados) {
    const data = dados?.map((item) => {
      return [
        { text: item?.nome, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },

        {
          text: formatarValor(item?.area),
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'center',
        },
      ]
    })

    const table = [
      {
        table: {
          headerRows: 1,
          widths: ['*', '*'],
          body: [
            [
              { text: 'Talhão', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Área/ha', style: 'tableHeader', fontSize: 9, alignment: 'center' },
            ],
            ...data,
          ],
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#f5f5f5' : null
          },
        },
      },
    ]
    setDadosEstatisticas(table)
  }

  function gerarTabela2(dados, length) {
    const data = dados?.map((item) => {
      return [
        { text: length, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
        { text: item?.totalizado_area, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
      ]
    })

    const table = [
      {
        table: {
          headerRows: 1,
          widths: ['*', '*'], // Definindo larguras das colunas
          body: [
            [
              {
                text: 'Total de talhões',
                style: 'tableHeader',
                fontSize: 9,
                alignment: 'center',
              },
              { text: 'Total de Área', style: 'tableHeader', fontSize: 9, alignment: 'center' },
            ],
            ...data,
          ],
          margin: [0, 50, 0, 0],
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#f5f5f5' : null
          },
        },
      },
    ]
    setDadosResultado(table)
  }

  const onLoadMap = (dataMap: google.maps.Map) => {
    setMap(dataMap)
  }

  const onLoadPolygon = (poly: google.maps.Polygon) => {
    setPolygon(poly)
  }

  const handleTrashTalhao = async (codPro, codTalhao) => {
    const nomePro = dataTalhao.filter((item) => item.cod === codTalhao)

    try {
      Modal.confirm({
        title: 'Excluir',
        icon: <IconAlert type='confirm' size={6} />,
        content: `Deseja realmente remover o Talhao ${nomePro[0]?.nome}?`,
        okText: 'Confirmar',
        cancelText: 'Cancelar',
        cancelButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: async () => {
          try {
            const responseDel = await delTalhaoCultivavel(codPro, codTalhao)

            // console.log('responseDel:', responseDel)
            // console.log('status:', responseDel.status)

            if (responseDel.status === 200) {
              Notification({
                message: 'Talhao Excluido',
                type: 'success',
              })
              setAtualizarArea(!atualizarArea)
              listTalhao(codPro, '')
            }
          } catch (error) {
            console.log('error: ', error)
            tryError(error)
          }
        },
      })
    } catch (error) {
      if (error.response) {
        const { data } = error.response

        Notification({
          type: 'error',
          message: 'Erro',
          descricao: data.error,
        })
      }
    }
  }

  const handleSelectTableRow = (cod) => {
    setCodTalhaoSelec(cod)
    setDelListTalhao(cod)
  }

  // Função para verificar se todos os registros foram selecionados
  const isAllRowsSelected = () => {
    // Supondo que você tenha uma fonte de dados chamada dataTalhao com uma chave única chamada 'cod'
    const allRowKeys = dataTalhao.map((row) => row.cod)

    delListTalhao.length === allRowKeys.length &&
      delListTalhao.every((key) => allRowKeys.includes(key))

    // Verifique se as listas de chaves selecionadas e todas as chaves da linha são iguais
    return true
  }

  const handleRemoveAllTalhoes = async () => {
    setLoadingTable(true)
    for (let i = 0; i < delListTalhao.length; i += 1) {
      const data = delListTalhao[i]

      await delTalhaoCultivavel(codPro, data)

      if (delListTalhao.length - 1 === i) {
        listTalhao(codPro, '')
        setDelListTalhao([])
        setLoadingTable(false)
      }
    }
  }

  const handleAddObservacao = async (values) => {
    setLoadingTable(true)
    try {
      message.loading('Aguarde...')

      const allRowKeys = dataTalhao.map((row) => row.cod)

      const observacao = {
        observacao: values.observacao,
        talhoes: selectTalhoes == false ? codTalhaoSelec : allRowKeys,
        todos: selectTalhoes,
      }

      if (observacao.talhoes.length == 0) {
        message.destroy()
        Notification({
          message: 'Por favor selecione pelo menos um talhão!',
          type: 'error',
        })
      } else {
        const response = await postObservacaoTalhao(codPro, observacao)

        if (response.status === 200 && selectTalhoes == true) {
          message.destroy()
          Notification({
            message: 'Observação cadastrada para todos os talhões da safra.',
            type: 'success',
          })
        } else {
          if (observacao.talhoes.length == 1) {
            message.destroy()
            Notification({
              message: 'Observação cadastrada com sucesso',
              type: 'success',
            })
          } else {
            message.destroy()
            Notification({
              message: 'Observação cadastrada para apenas os talhões da página atual.',
              type: 'success',
            })
          }
        }
      }

      message.destroy()
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingTable(false)
    }

    form.resetFields()
    // Fechar o modal
    setOpenModalObservacao(false)
  }

  const handleClikBaixarKml = async (arquivo, nome, data, coordenadas) => {
    try {
      const response = await getKmlTalhaoCultivavel(data, arquivo)

      const blob = new Blob([response.data], {
        type: 'application/octet-stream',
      })
      url.current.href = URL.createObjectURL(blob)
      url.current.download = `${nome}.kml`
      url.current.click()
    } catch {
      console.log(
        'O download do arquivo KML falhou. Por conta disso um novo arquivo KML será gerado localmente com base nas coordenadas do poligono, e depois baixado normalmente!'
      )
      gerarBaixarlKmlPolygon(coordenadas, nome)
    }
  }

  const handleClickDesenhaTalhao = () => {
    setVisibleDesenhaTalalhao(true)
  }

  const handleImportTalhao = async () => {
    setLoadingTalhaoCultivo(true)
    const response = await getTalhao(codPro, '')

    const dados = JSON.parse(JSON.stringify(response.data.itens))
    let newArray = []
    let newTalhoes = []
    dados.forEach((data, i) => {
      let newCoord = []

      data.kml.coordenadas.forEach((record) => {
        newCoord = [
          ...newCoord,
          {
            lat: parseFloat(record.latitude),
            lng: parseFloat(record.longitude),
          },
        ]

        return newCoord
      })
      newArray = [...newArray, newCoord]

      newTalhoes = [
        ...newTalhoes,
        {
          talhao: data.nome,
          coord: newArray[i],
          alert: false,
          ativo: true,
          area: data.area,
        },
      ]

      return { newArray, newTalhoes }
    })

    const centro = getBounds(newArray).getCenter()

    setPropsMapa({
      ...propsMap,
      center: {
        lat: centro.lat(),
        lng: centro.lng(),
      },
      zoom: 11,
    })

    setPolygonTalhao(newArray)
    setDataTalhoesPolygon(newTalhoes)

    setLoadingTalhaoCultivo(false)
    setModalTalhao(true)
  }

  const handleVisualizarPropri = async () => {
    message.loading('Carregando...', 99999)
    const response = await getTalhaoCultivavel({ codPropriedade: codPro })

    const dados = response.data.itens
    setTalhoesCultivaveis(dados)

    if (dados.length > 0) {
      let talhoes = []

      for (const item_element of dados) {
        const element = item_element
        let temp = []

        for (const item_coordenada of element.kml.coordenadas) {
          const coord = item_coordenada

          item_coordenada.lng = coord.longitude
          item_coordenada.lat = coord.latitude

          temp = [...temp, { lat: parseFloat(coord.lat), lng: parseFloat(coord.lng) }]
        }
        talhoes = [...talhoes, temp]
      }

      const centro = centerMap(talhoes[0])

      function zoomBaseadoNaArea() {
        if (sizePropriedade > 900) {
          return 11
        }
        if (sizePropriedade >= 700 && sizePropriedade < 900) {
          return 12
        }
        if (sizePropriedade >= 100 && sizePropriedade < 700) {
          return 13
        }
        if (sizePropriedade < 100) {
          return 14
        }

        return 11
      }

      setPropsMapa({
        ...propsMap,
        center: {
          lat: centro.lat(),
          lng: centro.lng(),
        },
        zoom: zoomBaseadoNaArea(),
      })

      let sumArea = 0
      for (const item_sum of dados) {
        const sum = item_sum
        sumArea += Number(sum.area)
      }

      setSizePropriedade(Number(sumArea.toFixed(2)))

      setDataPolygon(talhoes)
      setVisualizarPropriedade(true)
      message.destroy()
    } else {
      Notification({
        message: 'Propriedade não possui talhão',
        type: 'info',
      })
      message.destroy()
    }
  }

  const handleClickVisualizarTodasPropriedades = async () => {
    let talhoes = []

    setLoadingVisuPropriedades(true)

    for (const item of dataPropriedade) {
      const propri = item

      const resp = await getTalhaoPorSafra(propri.cod, '')

      if (resp?.data?.itens === undefined || resp?.data?.itens === null) {
        message.error('Falha ao carregar mapa de todas propriedades!')
        setLoadingVisuPropriedades(false)
        return null
      }
      // console.log(propri, resp.data.itens)
      if (resp.data.itens.length > 0) {
        resp.data.itens.forEach((data) => {
          data.cor = 'cyan'
        })
        talhoes = [
          ...talhoes,
          {
            cod: propri.cod,
            areaPropriedade: propri.area,
            name: propri.nome,
            talhoes: resp.data.itens,
            nome_proprietario: propri.cliente_nome,
          },
        ]
      }
    }

    let centeRs: google.maps.LatLngLiteral[] = []

    if (talhoes.length > 0) {
      talhoes[0].talhoes[0].kml.coordenadas.forEach((data) => {
        centeRs = [...centeRs, { lng: data.longitude, lat: data.latitude }]

        return centeRs
      })

      const center: google.maps.LatLngLiteral = centerMap(centeRs).toJSON()
      const tamanho_propriedades: number =
        talhoes.reduce((acumulador, item) => acumulador + Number(item.areaPropriedade), 0) ?? 0

      function zoomBaseadoNaArea() {
        if (tamanho_propriedades > 900) {
          return 12
        }
        if (tamanho_propriedades >= 700 && tamanho_propriedades < 900) {
          return 14
        }
        if (tamanho_propriedades >= 100 && tamanho_propriedades < 700) {
          return 15
        }
        if (tamanho_propriedades < 100) {
          return 16
        }

        return 11
      }

      // setPropsMapa({ center, zoom: ((talhoes?.[0]?.talhoes)?.length ?? 0) <= 3 ? 14 : 13 })
      setPropsMapaTodasPropriedades({ center, zoom: zoomBaseadoNaArea() })
    } else {
      Notification({ message: 'Cliente não possui talhões', type: 'info' })
      setLoadingVisuPropriedades(false)
      return
    }
    // console.log('talhoes:', talhoes)
    setDataVisuPropridades(talhoes)
    setVisualizarPropriedadesCliente(true)
    setLoadingVisuPropriedades(false)
  }

  useEffect(() => {
    listarPropriedades()
  }, [safraSelecionada])

  useEffect(() => {
    listarPropriedades()

    // refSelect.current.focus()

    form?.setFieldsValue({
      safra: JSON?.parse(localStorage?.getItem('codSafra')),
    })

    const idDaSafra = checkSafra('talhao/safra')

    if (!idDaSafra) {
      history.push('/home')
    }
  }, [])

  useEffect(() => {
    if (codPro > 0) {
      refetch_pontos_obs()
    }
  }, [codPro])

  useEffect(() => {
    if (data_pontos_obs.length > 0) {
      let pontosObs = data_pontos_obs.map((p) => {
        return {
          cod: p.cod,
          latitude: Number(p.latitude),
          longitude: Number(p.longitude),
          nome: p.titulo,
          alerta: p.tipo_nome,
          observacao: p.observacoes,
          cod_talhao: p.cod_talhao,
        }
      })
 

      setMarkerDefault(JSON.parse(JSON.stringify(pontosObs)))
    }
  }, [data_pontos_obs])

  /**Define a cor do talhão
   *
   * Se o talhão estiver selecionado retorna a cor `orange`
   *
   * Se a flag `distinguir_cor` for `true`, retorna uma cor única presente no array `paleta_cores[]`
   *
   * Se não estiver em nenhuma das consições anteriores, retorna a cor inicial `cyan`
   */
  function corAtualTalhao(cod_talhao: number, cor_inicial: string, index: number): string {
    if (talhao_selecionado === cod_talhao) {
      return 'orange'
    }
    return distinguir_cor ? paleta_cores?.[index] : cor_inicial
  }

  const alertIcon = {
    url:
      'data:image/svg+xml;charset=UTF-8,' +
      encodeURIComponent(`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48" height="48">
        <!-- Base do marcador -->
        <path d="M24 0C13.5 0 5 8.5 5 19.1c0 10.8 13.9 26.4 17.7 31.2.7.9 2 .9 2.7 0C29.1 45.5 43 29.9 43 19.1 43 8.5 34.5 0 24 0z" fill="#db1b1b" />
        <!-- Círculo interno -->
        <circle cx="24" cy="19" r="10" fill="#FFFFFF" />
        <!-- Exclamação -->
        <rect x="23" y="12" width="2" height="10" fill="#db1b1b" />
        <circle cx="24" cy="26" r="1.5" fill="#db1b1b" />
      </svg>
    `),
    scaledSize: new google.maps.Size(48, 48),
    anchor: new google.maps.Point(24, 48),
  }

  const infoIcon = {
    url:
      'data:image/svg+xml;charset=UTF-8,' +
      encodeURIComponent(`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48" height="48">
        <!-- Base do marcador -->
        <path d="M24 0C13.5 0 5 8.5 5 19.1c0 10.8 13.9 26.4 17.7 31.2.7.9 2 .9 2.7 0C29.1 45.5 43 29.9 43 19.1 43 8.5 34.5 0 24 0z" fill="#2196F3" />
        <!-- Círculo interno -->
        <circle cx="24" cy="19" r="10" fill="#FFFFFF" />
        <!-- Letra "i" -->
        <rect x="23" y="14" width="2" height="8" fill="#2196F3" />
        <circle cx="24" cy="25" r="1.5" fill="#2196F3" />
      </svg>
    `),
    scaledSize: new google.maps.Size(48, 48),
    anchor: new google.maps.Point(24, 48),
  }

  // console.log('dataTalhao: ', dataTalhao)

  return (
    <Container>
      <ViewPage
        title='Talhões da safra'
        btnClick={null}
        disableNew={codPro === 0}
        newHide
        rotina={29}
        onPermission={setPermissions}
        hiddeTitle
        cardBodyStyle={{ padding: 15 }}
        cardMainStyle={{ height: '87.5vh' }}
        content={
          <FormTalhaoSafra
            onClose={setVisibleForm}
            dataTalhao={dataTalhao}
            permissions={permissions}
            visible={visibleForm}
            update={udateTalhao}
            setUpdate={setUpdateTalhao}
            codPro={codPro}
            atualizar={listTalhao}
          />
        }
      >
        <TitleH1>Talhões da safra</TitleH1>

        <TabelaListaTalhoes
          disabled_action_buttons={codPro === 0}
          loading_action_buttons={loadingTalhaoCultivo}
          permisao_inclusao={permissions?.inclusao === 0}
          carregando_lista_talhao={loadingTable}
          novo_onClick={handleVisibleForm}
          importar_onClick={handleImportTalhao}
          desenhar_onClick={handleClickDesenhaTalhao}
          ver_propriedade_onClick={handleVisualizarPropri}
          ver_todas_propriedade_onClick={handleClickVisualizarTodasPropriedades}
          ver_todas_propriedades_loading={loadingVisuPropriedades}
          ver_todas_propriedades_disabled={loadingVisuPropriedades}
          pulverizacao_onClick={() => setOpenMapLine(true)}
          pulverizacao_hidden={process.env.NODE_ENV === 'production'}
          dadosEstatisticas={dadosEstatisticas}
          dadosResultado={dadosResultado}
          dataTalhao={dataTalhao}
          nomePropriedade={nomePropriedade}
          list_propriedades={dataPropriedade}
          obterListaTalhoes={listTalhao}
          list_talhoes={dataTalhao}
          permissions={permissions}
          handleClickVisuTalhao={handleClickVisuTalhao}
          handleClikBaixarKml={handleClikBaixarKml}
          handleTrashTalhao={handleTrashTalhao}
          atualizarLista={listTalhao}
        />
      </ViewPage>

      <Modal
        closeIcon
        style={{ top: 20, minWidth: '95%' }}
        open={openMapLine}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
      >
        <LinhaPlanejada talhao={talhaoSelecionado} onClose={setOpenMapLine} />
      </Modal>

      <Modal
        style={{ top: 20, minWidth: '95%' }}
        onCancel={() => setVisibleDesenhaTalalhao(false)}
        open={getVisibleDesenhaTalalhao}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ danger: true, type: 'primary' }}
        destroyOnClose
      >
        <DesenhaTalhao
          update={udateTalhao}
          propriedade={codPro}
          atualizar={listTalhao}
          cultivavel
        />
      </Modal>

      <Modal
        open={visualizarPropriedade}
        onCancel={() => setVisualizarPropriedade(false)}
        style={{ top: '10px', minWidth: '97%', height: '8vh' }}
        closable={false}
        footer={false}
        destroyOnClose
      >
        <ContainerMap>
          <Painel1Propriedade
            exibir_area_talhao={exibir_area_talhao}
            setExibir_area_talhao={setExibir_area_talhao}
            exibir_nome_talhao={exibir_nome_talhao}
            setExibir_nome_talhao={setExibir_nome_talhao}
            onClose={() => {
              setVisualizarPropriedade(false)
              setTalhao_selecionado(null)
            }}
            dados_propriedades={talhoesCultivaveis}
            distinguir_cor={distinguir_cor}
            setDistinguir_cor={setDistinguir_cor}
            tamanhoPropriedade={sizePropriedade}
            isCadastroPropriedade={false}
            ocultar_pontos_obs={ocultar_pontos_obs}
            setOcultar_pontos_obs={setOcultar_pontos_obs}
          />

          {/* @ts-ignore */}
          <GoogleMap
            mapContainerClassName='floating'
            onLoad={onLoadMap}
            zoom={propsMap.zoom}
            mapContainerStyle={{
              width: propsMap?.style?.width ?? '94vw',
              height: '93vh',
            }}
            center={propsMap.center}
            options={{
              streetViewControl: false,
              fullscreenControl: false,
              mapTypeControl: false,
              mapTypeId: 'hybrid',
            }}
          >
            {dataPolygon.map((data, i) => {
              const talhao = talhoesCultivaveis.find((record, ii) => ii === i)

              const center = centerMap(data)
              return (
                <>
                  <Polygon
                    onClick={(e) => {
                      handleClickPolygon(e, i)
                      setTalhao_selecionado(talhao?.cod)
                    }}
                    onLoad={onLoadPolygon}
                    options={{
                      // fillColor:'cyan',
                      // strokeColor: 'cyan',

                      // fillColor: distinguir_cor ? paleta_cores?.[i] : 'cyan',
                      // strokeColor: distinguir_cor ? paleta_cores?.[i] : 'cyan',

                      fillColor: corAtualTalhao(talhao?.cod, 'cyan', i),
                      strokeColor: corAtualTalhao(talhao?.cod, 'cyan', i),
                    }}
                    paths={data}
                  />
                  {/* @ts-ignore */}
                  <InfoBox
                    options={{
                      closeBoxURL: '',
                      enableEventPropagation: true,
                    }}
                    position={center}
                  >
                    <div
                      style={{
                        overflow: 'hidden',
                        width: '400px',
                        textShadow: '2px 2px 3px black',
                      }}
                    >
                      <h4>
                        <b
                          hidden={!exibir_nome_talhao}
                          style={{ overflow: 'hidden', color: 'white' }}
                        >
                          {talhao?.nome}
                        </b>
                        <br />
                        <b
                          hidden={!exibir_area_talhao}
                          style={{ overflow: 'hidden', color: 'white' }}
                        >
                          {` ${talhao?.area} ha`}
                        </b>
                      </h4>
                    </div>
                  </InfoBox>
                </>
              )
            })}

            <Render condition={ocultar_pontos_obs === false}>
              {markerDefault.map((item, i) => (
                // @ts-ignore
                <Marker
                key={gerarKey(i)}
                onLoad={(e) => handleOnloadMarker(e, i)}
                position={{ lat: item.latitude, lng: item.longitude }}
                onClick={() => 
                  abrirGavetaPontos(item)
          
        }
                icon={item?.alerta === 'Alerta' ? alertIcon : infoIcon}
                />
              ))}
            </Render>

            <GavetaPontoObs
              open={open}
              setOpen={setOpen}
              fecharGaveta={fecharGavetaPontos}
              pontosObs={obsPontos}
              loading={loading}
              codObs={codObs}
            />
          </GoogleMap>
        </ContainerMap>
      </Modal>

      <Modal
        open={openModalObservacao}
        onCancel={() => setOpenModalObservacao(false)}
        footer={[
          <Button key={0} type='primary' danger onClick={() => setOpenModalObservacao(false)}>
            Fechar
          </Button>,
          <Button key={1} type='primary' onClick={() => form.submit()}>
            Salvar
          </Button>,
        ]}
      >
        <Card className='floating-safra' size='small'>
          <Form form={form} layout='vertical' onFinish={handleAddObservacao}>
            <Form.Item
              rules={[
                {
                  required: true,
                },
              ]}
              label='Observação'
              name='observacao'
            >
              <Input onChange={({ target }) => setObservacao(target.value)} value={observacao} />
            </Form.Item>
            <Form.Item label='Deseja aplicar para todos talhões da safra?'>
              <Switch
                onChange={(data) => handleSelectTodosTalhoes(data)}
                unCheckedChildren='Não'
                checkedChildren='Sim'
              />
            </Form.Item>
          </Form>
        </Card>
      </Modal>

      <Form>
        <Form.Item hidden name='propriedade'>
          <Input accept='.kml' hidden type='file' />
        </Form.Item>
      </Form>

      <Modal
        closeIcon
        style={{ top: 20, minWidth: '95%' }}
        open={modalTalhao}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        destroyOnClose
      >
        <ImporteKmlTalhao
          safra
          listTalhoesRecord={dataTalhao}
          mapSet={propsMap}
          propriedade={codPro}
          nameTalhao='Safra'
          paths={polygonTalhao}
          dataPolygon={dataTalhoesPolygon}
          onClose={() => {
            setModalTalhao(false)
            listTalhao(codPro, '')
          }}
        />
      </Modal>

      <Modal
        open={visualizarPropriedadesCliente}
        onCancel={() => setVisualizarPropriedadesCliente(false)}
        style={{ top: '10px', minWidth: '97%', height: '80vh' }}
        closable={false}
        footer={false}
        destroyOnClose
      >
        <PainelTodasPropriedades
          exibir_area_talhao={exibir_area_talhao}
          setExibir_area_talhao={setExibir_area_talhao}
          exibir_nome_talhao={exibir_nome_talhao}
          setExibir_nome_talhao={setExibir_nome_talhao}
          onClose={() => {
            setVisualizarPropriedadesCliente(false)
            setTalhao_selecionado(null)
          }}
          dados_propriedades={dataVisuPropridades}
          distinguir_cor={distinguir_cor}
          setDistinguir_cor={setDistinguir_cor}
        />

        {/* @ts-ignore */}
        <GoogleMap
          mapContainerClassName='floating'
          zoom={propsMapTodasPropriedades.zoom}
          mapContainerStyle={{
            width: '100%',
            height: '93vh',
          }}
          center={propsMapTodasPropriedades.center}
          options={{
            zoomControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            mapTypeId: 'hybrid',
          }}
        >
          {dataVisuPropridades.map((data, index) => {
            let poly = []

            for (const item_talhao of data.talhoes) {
              const talhoes = item_talhao

              let tempCoord: google.maps.LatLng[] = []

              for (const item_coordenada_ii of talhoes.kml.coordenadas) {
                const coord = item_coordenada_ii

                tempCoord = [
                  ...tempCoord,
                  new google.maps.LatLng({
                    lat: coord.latitude,
                    lng: coord.longitude,
                  }),
                ]
              }

              poly = [
                ...poly,
                {
                  cod_talhao: talhoes.cod,
                  cor: talhoes.cor,
                  nome_talhao: talhoes.nome,
                  area: talhoes.area,

                  coord: tempCoord,
                },
              ]
            }

            return poly.map((info, ii) => {
              const final = { ...data, ...info }
              const center = centerMap(info?.coord)

              return (
                <>
                  <Polygon
                    onClick={(e) => {
                      handleClickPolygonTodasProp(e, index, final, ii)
                      setTalhao_selecionado(info.cod_talhao)
                    }}
                    onLoad={onLoadPolygon}
                    options={{
                      fillColor: corAtualTalhao(info.cod_talhao, info.cor, index),
                      strokeColor: corAtualTalhao(info.cod_talhao, info.cor, index),
                    }}
                    paths={info.coord}
                  />

                  {/* @ts-ignore */}
                  <InfoBox
                    options={{
                      closeBoxURL: '',
                      enableEventPropagation: true,
                    }}
                    position={center}
                  >
                    <div
                      style={{
                        overflow: 'hidden',
                        width: '120px',
                        textShadow: '2px 2px 3px black',
                        // marginTop: -20
                      }}
                    >
                      <h4>
                        <b
                          hidden={!exibir_nome_talhao}
                          style={{ overflow: 'hidden', color: 'white' }}
                        >
                          {info?.nome_talhao}
                        </b>
                        <br />
                        <b
                          hidden={!exibir_area_talhao}
                          style={{ overflow: 'hidden', color: 'white' }}
                        >
                          {` ${info?.area} ha`}
                        </b>
                      </h4>
                    </div>
                  </InfoBox>
                </>
              )
            })
          })}
        </GoogleMap>
      </Modal>
    </Container>
  )
}

export default TalhaoSafra
