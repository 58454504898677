import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Drawer, Modal, Tag } from 'antd'
import { useEffect, useState } from 'react'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import {
  faCirclePlus,
  faEdit,
  faEye,
  faFilter,
  faList,
  faTrash,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'

//STYLE
import { LogsPage } from './style'
import { NavegacaoDrawer } from '../../app/recomendacao/novoCompatibilidadeAtivo/components/gavetaCadastrar/styled'

//APP
import { removerAcentos } from '../../../utils'
import { ItemRequest, useGetLogs } from '../../../services/logs'

//COMPONENTS
import Tabs from '../../../Components/Tabs'
import Input from '../../../Components/CustomUi/input'
import Carregando from '../../../Components/CustomUi/loading'
import ButtonCustom from '../../../Components/ButtonCustom'
import PageContainer from '../../../Components/pageContainer'
import { Box } from '../../../Components/CustomUi/box'
import { Render } from '../../../Components/CustomUi/render'
import { Inline } from '../../../Components/CustomUi/inline'
import { TitleH1 } from '../../../Components/CustomUi/titleH1'
import { TitleH2 } from '../../../Components/CustomUi/titleH2'
import { TitleH3 } from '../../../Components/CustomUi/titleH3'
import { RowItem } from '../../../Components/CustomUi/table/rowItem'
import { RowTable } from '../../../Components/CustomUi/table/rowTable'
import { BodyTable } from '../../../Components/CustomUi/table/bodyTable'
import { TagMethod } from './components/tagMethod'
import { TagStatus } from './components/tagStatusCode'
import { HeaderTable } from '../../../Components/CustomUi/table/headerTable'
import { HeaderDrawer } from '../../../Components/CustomUi/headerGaveta'
import { FilterRequest } from './components/drawerDetalhes/components/filter'
import { DetalhesRequisicao } from './components/drawerDetalhes'

export const Logs: React.FC = () => {
  //STATES
  const [current_tab, setCurrent_tab] = useState<'POST' | 'PUT' | 'DELETE' | 'Todos'>('Todos')
  const [search_endPoint, setSearch_endPoint] = useState('')
  const [open_drawer, setOpen_drawer] = useState<boolean>(false)
  const [request_selected, setRequest_selected] = useState<ItemRequest | null>(null)
  const [fake_loading, setFake_loading] = useState<boolean>(false)
  //STATES - FILTROS
  const [open_modal, setOpen_modal] = useState<boolean>(false)
  const [endpoint, setEndpoint] = useState<string>('')
  const [user_email, setUser_email] = useState<string>('')
  const [date, setDate] = useState<string>('')
  const [pagination, setPagination] = useState<number>(15)
  const [page, setPage] = useState<number>(1)

  //API
  const { data_logs, refetch_logs, carregando_logs } = useGetLogs({
    method: current_tab === 'Todos' ? null : current_tab,
    cod: null,
    date: date,
    emailUsuario: user_email,
    endpoint: endpoint,
    pagination: pagination,
    page: page,
  })

  useEffect(() => {
    setPage(1)
    setTimeout(() => {
      refetch_logs()
    }, 200)
  }, [current_tab])

  useEffect(() => {
    refetch_logs()
  }, [pagination])
  useEffect(() => {
    refetch_logs()
  }, [page])

  function reload() {
    setTimeout(() => {
      refetch_logs()
      setFake_loading(false)
    }, 500)
  }

  function isFiltering() {
    let d_date = date !== null && date !== ''
    let d_endpoint = endpoint !== null && endpoint !== ''
    let d_user = user_email !== null && user_email !== ''

    return d_date || d_endpoint || d_user
  }

  function buscarEndPoint(buscar_nome: string) {
    const palavraSemAcentos = removerAcentos(buscar_nome)
    return function (item) {
      const nomeSemAcentos = removerAcentos(String(item?.descricao?.uri))
      return nomeSemAcentos.toLowerCase().includes(palavraSemAcentos.toLowerCase()) || !buscar_nome
    }
  }

  return (
    <LogsPage>
      <PageContainer>
        <TitleH1>Logs de requisição</TitleH1>

        <Box padding={15} gap={10}>
          <Inline>
            <Tabs
              tabs={[
                { descricao: 'Todos', icone: faList },
                { descricao: 'POST', icone: faCirclePlus },
                { descricao: 'PUT', icone: faEdit },
                { descricao: 'DELETE', icone: faTrash },
              ]}
              onChange={setCurrent_tab}
              selecionado={current_tab}
              width={465}
              trackWidth={113}
            />

            <Input
              placeholder='Buscar endpoint'
              value={search_endPoint}
              onChange={setSearch_endPoint}
            />

            <ButtonCustom
              onClick={() => {
                setOpen_modal(true)
              }}
              icon={<FontAwesomeIcon icon={faFilter} />}
              style={{ height: 39 }}
              type='secondary'
            >
              Filtrar
            </ButtonCustom>
          </Inline>

          <Render condition={isFiltering()}>
            <Inline alignItems='center'>
              <TitleH3>Filtrando por: </TitleH3>
              {date !== null && date !== '' && (
                <Tag
                  closeIcon
                  onClose={() => {
                    setDate(null)
                    setFake_loading(true)
                    reload()
                  }}
                >
                  Data: {date}
                </Tag>
              )}
              {endpoint !== null && endpoint !== '' && (
                <Tag
                  closeIcon
                  onClose={() => {
                    setEndpoint(null)
                    setFake_loading(true)
                    reload()
                  }}
                >
                  Endpoint: {endpoint}
                </Tag>
              )}
              {user_email !== null && user_email !== '' && (
                <Tag
                  closeIcon
                  onClose={() => {
                    setUser_email(null)
                    setFake_loading(true)
                    reload()
                  }}
                >
                  Usuário: {user_email}
                </Tag>
              )}
            </Inline>
          </Render>

          <HeaderTable
            itens={[
              { id: 1, valor: 'Método', width: 100 },
              { id: 2, valor: 'URI', width: 'calc(100% - 650px)' },
              { id: 3, valor: 'Autor', width: 230 },
              { id: 4, valor: 'Horário', width: 180 },
              { id: 5, valor: 'Ações', width: 140 },
            ]}
          />

          {/* <BodyTable style={{marginTop:-10, height: 'auto'}}> */}
          <BodyTable
            paginate={data_logs?.total}
            onChangeItensPage={setPagination}
            onChangePage={setPage}
            current_page={page}
            setCurrent_page={setPage}
            style={{
              marginTop: -10,
              height: (data_logs?.itens ?? []).length < 8 ? 'auto' : 'calc(100vh - 380px)',
              position: 'relative',
              overflowX: 'hidden',
            }}
          >
            <Render condition={!carregando_logs && !fake_loading}>
              {(data_logs?.itens ?? [])
                .filter(buscarEndPoint(search_endPoint))
                .map((item, index) => {
                  return (
                    <RowTable
                      key={item.cod ?? index}
                      onDoubleClick={() => {
                        setRequest_selected(item)
                        setOpen_drawer(true)
                      }}
                    >
                      <RowItem width={99.5}>
                        <TagMethod method={item?.descricao?.httpMethod} />
                      </RowItem>
                      <RowItem
                        width={
                          (data_logs?.itens ?? []).length > 7
                            ? 'calc(100% - 644px)'
                            : 'calc(100% - 648px)'
                        }
                        fontSize={13}
                      >
                        {String(item?.descricao?.response?.httpCode) === '200' ||
                        String(item?.descricao?.response?.httpCode) === '201' ? (
                          <Tag
                            icon={<CheckCircleOutlined />}
                            color='success'
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          />
                        ) : (
                          <Tag
                            icon={<CloseCircleOutlined />}
                            color='error'
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          />
                        )}

                        {item.descricao.uri}
                      </RowItem>
                      <RowItem width={230} nowrap fontSize={13}>
                        {item?.usuario_nome}
                      </RowItem>
                      <RowItem width={180} nowrap fontSize={13}>
                        {item.create_time}
                      </RowItem>
                      <RowItem width={135}>
                        <ButtonCustom
                          onClick={() => {
                            setRequest_selected(item)
                            setOpen_drawer(true)
                          }}
                          icon={<FontAwesomeIcon icon={faEye} />}
                        >
                          Detalhes
                        </ButtonCustom>
                      </RowItem>
                    </RowTable>
                  )
                })}
            </Render>

            <Render condition={carregando_logs || fake_loading}>
              <Carregando
                animation
                justifyContent='center'
                legenda='Carregando lista de requisições'
                size={'large'}
                height={350}
              />
            </Render>
          </BodyTable>
        </Box>
      </PageContainer>

      <Drawer
        width={800}
        open={open_drawer}
        onClose={() => {
          setOpen_drawer(false)
        }}
        closable={false}
        bodyStyle={{ padding: 0 }}
        headerStyle={{ padding: '10px 10px' }}
        title={
          <NavegacaoDrawer>
            <ButtonCustom
              danger
              icon={<FontAwesomeIcon icon={faXmark} />}
              type='tertiary'
              onClick={() => setOpen_drawer(false)}
              style={{ marginLeft: -10 }}
            >
              Fechar
            </ButtonCustom>
          </NavegacaoDrawer>
        }
      >
        <HeaderDrawer style={{ justifyContent: 'space-between' }}>
          Detalhes da requisição
          <TagMethod
            method={request_selected?.descricao?.httpMethod}
            style={{ position: 'absolute', right: 80, top: 10 }}
          />
          <TagStatus
            status={
              String(request_selected?.descricao?.response?.httpCode) === '200' ||
              String(request_selected?.descricao?.response?.httpCode) === '201'
                ? 'success'
                : 'fail'
            }
            style={{ position: 'absolute', right: 10, top: 10 }}
            value={String(request_selected?.descricao?.response?.httpCode)}
          />
        </HeaderDrawer>

        <DetalhesRequisicao request_selected={request_selected} />
      </Drawer>

      <Modal
        open={open_modal}
        onCancel={() => {
          setOpen_modal(false)
        }}
        title='Filtrar requisições'
        footer={false}
      >
        <FilterRequest
          setEndpoint={setEndpoint}
          setUser_email={setUser_email}
          setDate={setDate}
          onClose={() => {
            setOpen_modal(false)
          }}
          refetch={refetch_logs}
        />
      </Modal>
    </LogsPage>
  )
}
