import React, { useState } from 'react'
import { AreaCard, ContainerCards } from './styles'
import { Avatar } from 'antd'
import bgObs from '../../../../../../../assets/img/bgObs.png'
import dayjs from 'dayjs'
import ButtonCustom from '../../../../../../../Components/ButtonCustom'
import TextArea from '../../../../../../../Components/CustomUi/textarea'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faFileCirclePlus, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { Render } from '../../../../../../../Components/CustomUi/render'
import Carregando from '../../../../../../../Components/CustomUi/loading'
import { SlidesFullScreen } from '../../../../../../../Components/CustomUi/slidesFullScreen'
interface PropsCardsObs {
  observacao: any[]
  loading: boolean
}

export const CardsObservacao: React.FC<PropsCardsObs> = ({ observacao, loading }) => {
  const [openSlides, setOpenSlides] = useState<boolean>(false)
  const [slide_inicial, setSlide_inicial] = useState<number>(null)

  function filtrarMedia() {
    return observacao[0]?.obs
      ?.filter((rec) => rec?.url_arquivo)
      .map((item) => {
        return {
          media: item?.url_arquivo,
          type: 'image',
          description: item?.mensagem || '',
        }
      })
  }

  function buscarIndexObsFiltrada(arquivo: string) {
    const obsFiltrar = observacao[0]?.obs.filter((rec) => rec?.url_arquivo).findIndex(pessoa => pessoa.arquivo.trim().toLowerCase() === arquivo.trim().toLowerCase());
   setSlide_inicial(obsFiltrar)
  }

  return (
    <>
      <Render condition={loading}>
        <Carregando
          animation
          legenda='Carregando observação...'
          justifyContent='center'
          height={'100%'}
        />
      </Render>
      <Render condition={loading === false}>
        <ContainerCards background={bgObs}>
          <div className='area__container__cards'>
            {observacao[0]?.obs?.map((item, index) => {
              return (
                <AreaCard key={index}>
                  <div className='card__left'>
                    <div className='header__card'>
                      <h3>{item?.usuario[0]?.nome.toLowerCase()}</h3>
                    </div>
                    <div className='img__mensagem'>
                      <Render condition={item?.url_arquivo !== undefined}>
                        <div className='image-container'>
                          <img src={item?.url_arquivo} alt='' />
                          <div
                            className='overlay'
                            onClick={() => {
                              setOpenSlides(true);
                              buscarIndexObsFiltrada(item?.arquivo)
                            }}
                          >
                            <i className='fa fa-eye'>
                              <FontAwesomeIcon fontSize={14} icon={faEye} />
                            </i>
                          </div>
                        </div>
                      </Render>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '100%',
                          width: item?.url_arquivo !== undefined ? 'calc( 100% - 130px )' : '100%',
                          justifyContent: 'space-between',
                          gap: '10px',
                        }}
                      >
                        <p style={{ margin: 0, padding: 0, fontSize: '12px' }}>{item?.mensagem}</p>
                        <p style={{ margin: 0, padding: 0, fontSize: '10px', textAlign: 'right' }}>
                          {dayjs(item?.data_hora).format('DD/MM/YYYY HH:mm')}
                        </p>
                      </div>
                    </div>
                  </div>
                </AreaCard>
              )
            })}
          </div>
          <Render condition={openSlides}>
            <SlidesFullScreen
              slides={filtrarMedia()}
              onClose={() => setOpenSlides(false)}
              slide_inicial={slide_inicial}
            />
          </Render>
        </ContainerCards>
      </Render>
    </>
  )
}
