import React, { useEffect, useState } from 'react'
import { CampoPrincipal, ContainerBio, TabelaBiotecnologia } from './styled'
import { Button, Modal, Space, Table, Tag } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPen, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import Icons from '../../../../Components/Icons'
import Icon from '@ant-design/icons/lib/components/Icon'
import { pagination } from '../../../../Components/Default'
import { AxiosResponse } from 'axios'
import { delBioTecnologia, getBiotecnologia } from '../../../../services/cadastrarBiotecnologia'
import FormBiotecnologia from './components'
import { IconAlert } from '../../../../Components/iconsAlert'
import { getCulturas } from '../../../../services/cadastrarCultura'

const CadastroBio: React.FC = () => {
  const [dataBio, setDataBio] = useState([])
  const [dataBioTemp, setDataBioTemp] = useState([])
  const [cods, setCods] = useState([])
  const [culturas, setCulturas] = useState([])
  const [open, setOpen] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [update, setUpdate] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [tableKey, setTableKey] = useState(Date.now())

  const handleGetBio = async (page, pagina) => {
    const resp: AxiosResponse = await getBiotecnologia(
      {
        embed: 'culturas',
        page: page,
        pagination: pagina,
      },
      cods
    )
    setDataBio(resp.data.itens)
    setDataBioTemp((state) => resp.data.itens)
    setTotal(resp.data.total)
    setLoadingTable(false)
  }

  async function getCultura() {
    const resp = await getCulturas({ pagination: -1 })
    setCulturas(resp.data.itens)
  }

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current)
    setPageSize(pagination.pageSize)
  }

  const handleEditBio = (cod) => {
    setUpdate(cod)
    setOpen(true)
  }

  const handleSearch = (data) => {
    setLoadingTable(true)
    const dados = dataBio
    const nome: any = data.target.value.toLowerCase()

    setTimeout(() => {
      const response = dados.filter((info) => info.nome.toLowerCase().indexOf(nome) !== -1)
      setDataBioTemp(response)
      setLoadingTable(false)
    }, 500)
  }

  const handleCategoryFilterChange = (filters) => {
    const numericFilters = Object.keys(filters).map((key) => filters[key])
    setCods(numericFilters[0])
  }

  async function deletarBio(cod, nome) {
    Modal.confirm({
      title: `Deseja remover a biotecnologia ${nome}?`,
      icon: <IconAlert type='confirm' size={6} />,
      okText: 'Sim',
      cancelText: 'Não',
      cancelButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: async () => {
        const response = await delBioTecnologia(cod)

        handleGetBio(currentPage, pageSize)
      },
    })
  }

  const filtrarCulturas = () => {
    return culturas.map((culturas) => ({
      text: culturas.nome,
      value: culturas.cod,
    }))
  }

  useEffect(() => {
    if (open === false) {
      handleGetBio(currentPage, pageSize)
      setUpdate(0)
      setLoadingTable(true)
    }
  }, [open])

  useEffect(() => {
    handleGetBio(currentPage, pageSize)
  }, [currentPage, pageSize])

  useEffect(() => {
    handleGetBio(1, 10)
    getCultura()
  }, [])

  useEffect(() => {
    if (cods !== null) {
      handleGetBio(1, -1)
      setLoadingTable(true)
    } else {
      handleGetBio(1, 10)
      setLoadingTable(true)
    }
  }, [cods])

  return (
    <ContainerBio>
      <CampoPrincipal>
        <div className='header__cadastrar'>
          <h2 className='title__header'>Cadastrar biotecnologia</h2>
          <div className='camp__add'>
            <Button
              className='btn__register'
              icon={<FontAwesomeIcon icon={faAdd} />}
              onClick={() => setOpen(true)}
            >
              Cadastrar
            </Button>
            <div className='camp__search'>
              <Icons style={{ width: '10%' }} icon={faSearch}></Icons>
              <input
                onChange={handleSearch}
                className='input__search'
                placeholder='Buscar biotecnologia'
              ></input>
            </div>
          </div>
        </div>
        <TabelaBiotecnologia>
          <Table
            key={tableKey}
            rowKey='cod'
            loading={loadingTable}
            scroll={{ y: 'calc(90vh - 275px)' }}
            size='small'
            dataSource={dataBioTemp}
            pagination={{
              current: currentPage,
              total: total,
              pageSize: pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100'],
            }}
            onChange={(pagination, filters) => {
              handleTableChange(pagination)
              handleCategoryFilterChange(filters)
            }}
            columns={[
              {
                key: 1,
                title: 'Nome',
                dataIndex: 'nome',
                width: '200px',
                sorter: (a, b) => a.nome.localeCompare(b.nome),
              },
              {
                key: 2,
                title: 'Nome resumido',
                dataIndex: 'sigla',
                width: '150px',
                sorter: (a, b) => a.nome.localeCompare(b.nome),
              },
              {
                key: 3,
                title: 'Descrição',
                width: '550px',
                dataIndex: 'descricao',
              },
              {
                key: 4,
                title: 'Culturas',
                dataIndex: 'culturas',
                width: '150px',
                render: (culturas) => (
                  <>
                    {culturas.map((cultura) => (
                      <Tag color='green' key={cultura}>
                        {cultura.cultura_nome}
                      </Tag>
                    ))}
                  </>
                ),
                filters: filtrarCulturas(),
              },
              {
                key: 5,
                width: 90,
                title: 'Ações',
                align: 'center',
                dataIndex: 'cod',
                render: (data, record) => (
                  <Space>
                    <Button
                      shape='circle'
                      type='default'
                      onClick={() => handleEditBio(data)}
                      icon={<Icon component={() => <Icons icon={faPen} />} />}
                    />
                    <Button
                      shape='circle'
                      type='primary'
                      danger
                      onClick={() => deletarBio(data, record.nome)}
                      icon={<Icon component={() => <Icons icon={faTrash} />} />}
                    />
                  </Space>
                ),
              },
            ]}
            footer={() => (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'right',
                }}
              >
                <b>Total geral: {total}</b>
              </div>
            )}
          />
        </TabelaBiotecnologia>
        <FormBiotecnologia
          open={open}
          update={update}
          onClose={() => setOpen(false)}
          dadosBio={dataBio}
        />
      </CampoPrincipal>
    </ContainerBio>
  )
}

export default CadastroBio
