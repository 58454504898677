import { clearCache } from "clear-cache";
import { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { ArrowLeftOutlined, LockOutlined, MailOutlined, SyncOutlined } from '@ant-design/icons'
import {
  App,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  List,
  Modal,
  Row,
  Switch,
  Typography,
  notification,
} from 'antd'

import { AxiosResponse } from 'axios'

import { Notification } from '../../../Components/notification'
import { api } from '../../../services/api'

import { Container } from './style'

import logoTtivos from '../../../assets/img/logo-ttivos-png.png'
import logoTest from '../../../assets/img/teste_logo.png'

import logoAdm from '../../../assets/img/logo-admin.png'

import { useLogic } from '../../../context/useLogic'

import Img from '../../../assets/img/novoBG.jpg'
import ImgAdmin from '../../../assets/img/bgAdmin.jpg'
import ImgLab from '../../../assets/img/bgLab.jpg'
import { useAccess } from '../../../context/useAccess'
import { tryError } from '../../../services/Afins'
import { getConsultoria } from '../../../services/Cliente'
import { TypeLogin } from '../../../services/login'
import { PopupUpdate } from './components/popup'
import { useGetUltimaVersao } from '../../../services/versao/get'
import { Start } from './components/start'
import secureLocalStorage from 'react-secure-storage'

export default function SignIn() {
  const { host } = window.location
  const {
    setItens,
    setClienteTemAcesso,
    setSafraSelecionada,
    setRota,
    setDataConta,
    setDataCliente,
    setCodCliente,
    setTypeAccess,
    setAcesso,
  } = useLogic()

  const { message } = App.useApp()

  const { onLogin, setLoggedInAs } = useAccess()

  const [form] = Form.useForm()

  const history = useHistory()

  const location = useLocation()

  const [forgotPass, setForgotPass] = useState(true)
  const [signIn, setSignIn] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [dataConta, setDataContas] = useState<any>()
  const [getRemember, setRemember] = useState(false);
  const [username, setUsername] = useState<string>('');

  const [loadingEntrar, setLoadingEntrar] = useState<boolean>(false)
  const [adminMode, setAdminMode] = useState<boolean>(
    window.location.host === process.env.REACT_APP_LINK_ADM
  )
  const [labMode, setLabMode] = useState<boolean>(
    window.location.host === process.env.REACT_APP_LINK_LAB
  )

  //API
  const { data_versao, refetch_versao } = useGetUltimaVersao();

  useEffect(() => {
    refetch_versao()
  }, [])
  
  const acessoDireto = async (dados, acessoConta) => {
    const { data } = dados

    const { itens } = data

    setLoadingEntrar(false)
    // @ts-ignore
    setItens(itens)

    setClienteTemAcesso(itens)
    if (acessoConta?.contas?.length > 0) {
      if (itens.cod_laboratorio !== null) {
        localStorage.setItem('Itens', JSON.stringify(data.itens))

        const dadosConta = acessoConta.contas[0]

        localStorage.setItem('conta', JSON.stringify(dadosConta.cod))
        localStorage.setItem('tipo-acesso', adminMode ? 'admin' : dadosConta.acesso)
        localStorage.setItem('cod-cliente', '')
        localStorage.setItem('nome-cliente', dadosConta.nome)
        setAcesso('conta')
        setDataConta([dadosConta])
        setLoggedInAs({
          type: 'CONTA',
          codCliente: null,
          codConta: dadosConta.cod,
        })

        history.push(adminMode ? '/admin' : '/home')
      } else {
        localStorage.setItem('Itens', JSON.stringify(data.itens))
        localStorage.setItem('tipo-acesso', 'lab')

        history.push('/lab')
        setAcesso('lab')
      }
    } else if (acessoConta?.clientes?.length > 0) {
      if (itens.cod_laboratorio !== null) {
        localStorage.setItem('Itens', JSON.stringify(data.itens))

        const dadosCliente = acessoConta?.clientes[0]
        const resp = await getConsultoria(dadosCliente.cod_conta)

        setDataConta(resp.data.itens)

        localStorage.setItem('conta', JSON.stringify(dadosCliente.cod_conta))
        localStorage.setItem('tipo-acesso', adminMode ? 'admin' : 'cliente')
        localStorage.setItem('cod-cliente', dadosCliente.cod)
        localStorage.setItem('nome-cliente', dadosCliente.nome)
        setAcesso('cliente')
        setDataCliente([dadosCliente])
        setLoggedInAs({
          type: 'CLIENTE',
          codCliente: dadosCliente.cod,
          codConta: dadosCliente.cod_conta,
        })

        history.push(adminMode ? '/admin' : '/home')
      } else {
        localStorage.setItem('Itens', JSON.stringify(data.itens))
        localStorage.setItem('tipo-acesso', 'lab')

        history.push('/lab')
        setAcesso('lab')
      }
    } else {
      localStorage.setItem('Itens', JSON.stringify(data.itens))
      localStorage.setItem('tipo-acesso', 'lab')

      history.push('/lab')
      setAcesso('lab')
    }
  }

  const onFinish = async (data) => {

    // /**(Temporário) é apenas para remover email e senha sem criptografia que estava salva antes da atualização
    //    * atualização 02/10/2024
    //    * remover após 17/10/2024
    //    */
    // localStorage.removeItem('passRemember');
    // localStorage.removeItem('emailRemember');

    let email_saved = localStorage.getItem('emailRemember');
    let pass_saved = localStorage.getItem('passRemember');

    if (email_saved!==undefined) {
      secureLocalStorage.setItem('emailRemember', email_saved);
    }
    if (pass_saved!==undefined) {
      secureLocalStorage.setItem('passRemember', pass_saved);
    }
   
    setLoadingEntrar(true)
    try {
      message.loading('Aguarde...', 9999)
      const response: AxiosResponse<any | TypeLogin> = await onLogin(
        data.email,
        data.senha,
        false,
        labMode
      )

      
      if (response.status === 200) {
        const { acessoConta } = response.data.itens[0]
        secureLocalStorage.setItem('acesso-admin', response.data.itens[0]?.acesso_admin)
        setDataContas(acessoConta)
        setUsername(response?.data?.itens?.[0]?.nome ?? '')
        message.destroy()

        const contContaCliente = acessoConta?.clientes?.length + acessoConta?.contas?.length

        if (contContaCliente > 1) {
          if (adminMode) {
            acessoDireto(response, acessoConta)
          } else {
            // message.info('Bem-vindo! Ao TTivos gestão rural')

            showModal()

            const { data } = response
            setLoadingEntrar(false)
            // @ts-ignore
            setItens(data.itens)
            setClienteTemAcesso(data.itens)
            localStorage.setItem('Itens', JSON.stringify(data.itens))
          }
        } else {
          acessoDireto(response, acessoConta)
        }
      }
    } catch (e) {
      tryError(e)
      message.destroy()
      setLoadingEntrar(false)
    }
  }

  const handleSubmitReset = async (data) => {
    data.preventDefault()

    // @ts-ignore
    const email = document.getElementById('emailexiste').value
    const request = {
      email,
      urlRedefinir: `${process.env.REACT_APP_LINK}/auth/reset`,
    }
    setLoading(true)

    try {
      const response = await api.post('/login/recuperar/solicitar', request)

      if (response.status === 200) {
        setLoading(false)
        Notification({
          message: `Redefinição de senha Enviada para o Email ${request.email}`,
          type: 'success',
        })
      }
      setTimeout(() => {
        history.push('/auth')
      }, 2000)
    } catch (error) {
      if (error.response) {
        setLoading(false)
        Notification({
          message: error.response.data.error,
          type: 'error',
        })
      }
    }
  }

  const handleClickForgotPass = (e) => {
    e.preventDefault()
    setForgotPass(!forgotPass)
    setSignIn(!signIn)
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleChangePass = (data) => {
    if (getRemember) {
      localStorage.setItem('passRemember', data.target.value)
      secureLocalStorage.setItem('passRemember', data.target.value)
    } else {
      localStorage.removeItem('passRemember');
      secureLocalStorage.removeItem('passRemember');
    }
  }

  const handleChangeEmail = (data) => {
    form.setFieldsValue({ email: data.target.value.toLowerCase().trim() })

    if (getRemember) {
      localStorage.setItem('emailRemember', data.target.value)
      secureLocalStorage.setItem('emailRemember', data.target.value)
    } else {
      localStorage.removeItem('emailRemember')
      secureLocalStorage.removeItem('emailRemember')
    }
  }

  const handleChangeAdm = (data) => {
    setAdminMode(data)
  }

  function handleChangeLab(data) {
    setLabMode(data)
  }

  const handleChekProduction = () => {
    const linkApi = process.env.REACT_APP_LINK_API
    if (host === 'ttivos.app') {
      if (linkApi === 'https://api.ttivos.app') {
        return false
      }
      return true
    }

    if (host === 'test.ttivos.app') {
      if (linkApi === 'https://test-api.ttivos.app') {
        return false
      }
      return true
    }

    return false
  }
  /* Function armazena dados no localStorage */
  const handleRemember = (data) => {
    setRemember(data.target.checked)
    localStorage.setItem('remember', JSON.stringify(data.target.checked))
    const pass = form.getFieldValue('senha')
    const email = form.getFieldValue('email')

    if (data.target.checked) {
      localStorage.setItem('passRemember', pass)
      secureLocalStorage.setItem('passRemember', pass)

      localStorage.setItem('emailRemember', email)
      secureLocalStorage.setItem('emailRemember', email)
    } else {
      localStorage.removeItem('passRemember');
      secureLocalStorage.removeItem('passRemember');

      localStorage.removeItem('emailRemember')
      secureLocalStorage.removeItem('emailRemember')
    }
  }

  const handleChangeImg = () => {
    if (labMode) {
      return ImgLab
    }

    return adminMode ? ImgAdmin : Img
  }

  useEffect(() => {
    localStorage.removeItem('conta')
    localStorage.removeItem('nome-cliente')
    localStorage.removeItem('Itens')
    localStorage.removeItem('cod-cliente')
    localStorage.removeItem('tipo-acesso')
    localStorage.removeItem('codSafra')

    setSafraSelecionada(null)
    setRota(null)

    const remember = JSON.parse(localStorage.getItem('remember'))

    if (remember) {
      const email = localStorage.getItem('emailRemember')
      const senha = localStorage.getItem('passRemember')
      setRemember(true)
      form.setFieldsValue({
        email,
        senha,
      })
    } else {
      setRemember(false)
    }
    if (history.location.pathname === '/auth/login/redefinir') {
      setForgotPass(false)
    }
    notification.destroy()
    Modal.destroyAll()
    message.destroy()
  }, [])

  function exibirAvisoAtualizacao() {
    let last_update = localStorage.getItem('last_handle_update');
    let is_old_version = process.env.REACT_APP_VERSION < data_versao;

    function isFiveMinutesLater(storedTime: string): boolean {

      if (last_update===undefined) {
        return true;
      }

      const storedDate: Date = new Date(storedTime);
      const now: Date = new Date();
  
      const diffInMs: number = now.getTime() - storedDate.getTime();
      const fiveMinutesInMs: number = 5 * 60 * 1000;
      return diffInMs >= fiveMinutesInMs;
    }

    return isFiveMinutesLater(last_update) && is_old_version;
    
  }

  useEffect(() => {
    let last_auto_update = localStorage.getItem('last_auto_update');
    let stored_version = localStorage.getItem('handle_version_updated');
    let is_old_version = process.env.REACT_APP_VERSION < data_versao;
    let is_version_already_updated = data_versao === stored_version;

    /**Verifica se está com versão antiga mesmo após já ter sido atualizado */
    if (is_old_version && is_version_already_updated) {

      localStorage.setItem('last_auto_update', String(new Date()));
      localStorage.setItem('handle_version_updated', String(data_versao))

      function is30MinutesLater(storedTime: string): boolean {

        /**Se não encontrar a informação de quando foi atualizado, irá atualizar por garantia */
        if (last_auto_update===undefined) {
          return true;
        }
  
        const storedDate: Date = new Date(storedTime);
        const now: Date = new Date();
    
        const diffInMs: number = now.getTime() - storedDate.getTime();
        const fiveMinutesInMs: number = 30 * 60 * 1000; // 30 minutos
        return diffInMs >= fiveMinutesInMs;
      }

      /**Verifica se não foi atualizado autmáticamente nos ultimos 30 minutos, prevenindo loop*/
      if (is30MinutesLater(last_auto_update)) {
        
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.getRegistrations().then((registrations) => {
            for (let registration of registrations) {
              registration.unregister();  // Desativa o service worker
            }
            clearCache(false);
            window.location.href = window.location.origin + window.location.pathname + '?updated=' + new Date().getTime();
          });
        } else {
            clearCache(false);
            window.location.href = window.location.origin + window.location.pathname + '?updated=' + new Date().getTime();
        }

      }

      
    }
    

  }, [data_versao])
  

  return (
    <Container bg={handleChangeImg()}>
      <Input name='login' style={{ display: 'none' }} />
      <Input name='senha' style={{ display: 'none' }} />
      <Modal
        title='Selecione uma das opções para iniciar'
        open={false}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <List itemLayout='horizontal'>
          {dataConta?.clientes.map((data) => {
            return (
              <Link
                key={`${data.cod}${data.nome}`}
                className='link-conta-select'
                onClick={() => {
                  localStorage.setItem('tipo-acesso', data.acesso)
                  localStorage.setItem('nome-cliente', data.nome)
                  if (data.acesso === 'conta') {
                    localStorage.setItem('conta', data.cod)
                    localStorage.setItem('cod-cliente', '')
                    setAcesso('conta')
                    setLoggedInAs({
                      type: 'CONTA',
                      codCliente: null,
                      codConta: data.cod,
                    })

                    setCodCliente(null)
                  } else {
                    localStorage.setItem('conta', data.cod_conta)
                    localStorage.setItem('cod-cliente', data.cod)
                    setCodCliente(data.cod)
                    setAcesso('cliente')
                    setLoggedInAs({
                      type: 'CLIENTE',
                      codCliente: data.cod,
                      codConta: null,
                    })
                  }

                  history.push('/app/home')
                  message.destroy()
                }}
                to='/home'
              >
                <List.Item>
                  <List.Item.Meta title={data.nome} description='Cliente' />
                </List.Item>
              </Link>
            )
          })}
          {dataConta?.contas.map((data) => {
            return data.temAcesso ? (
              <Link
                key={data.cod}
                className='link-conta-select'
                onClick={() => {
                  setDataConta([data])
                  localStorage.setItem('tipo-acesso', data.acesso)
                  localStorage.setItem('nome-cliente', data.nome)
                  if (data.acesso === 'conta') {
                    localStorage.setItem('conta', data.cod)
                    localStorage.setItem('cod-cliente', '')
                    setTypeAccess('conta')
                    setAcesso('conta')
                    setLoggedInAs({
                      type: 'CONTA',
                      codCliente: null,
                      codConta: data.cod,
                    })
                  } else {
                    localStorage.setItem('conta', data.cod_conta)
                    localStorage.setItem('cod-cliente', data.cod)
                    setTypeAccess('cliente')
                    setAcesso('cliente')
                    setLoggedInAs({
                      type: 'CLIENTE',
                      codCliente: data.cod,
                      codConta: null,
                    })
                  }
                  history.push('/app/home')
                  message.destroy()
                }}
                to='/home'
              >
                <List.Item key={data.cod}>
                  <List.Item.Meta key={data.cod} title={data.nome} description='Consultoria' />
                </List.Item>
              </Link>
            ) : null
          })}
        </List>
      </Modal>
      <div hidden={!handleChekProduction()} className='login'>
        <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
          <img width='80%' src={logoAdm} alt='Logo SysAGRO' />
          <h1>Em manutenção</h1>
        </Row>
      </div>
      <div hidden={handleChekProduction()} className='login'>
        <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
          {window.location.origin === 'https://test.ttivos.app' ||
          window.location.origin === 'http://localhost:3000' ? (
            <img width='95%' src={logoTest} alt='Logo SysAGRO' />
          ) : (
            <img width='80%' src={adminMode ? logoAdm : logoTtivos} alt='Logo Ttivos' />
          )}
        </Row>
        <br />
        <Form
          autoComplete='off'
          form={form}
          requiredMark={false}
          hidden={!forgotPass}
          style={{ minWidth: '100%' }}
          layout='vertical'
          name='normal_login'
          className='login-form'
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            label='Email'
            name='email'
            rules={[
              {
                type: 'email',
                required: true,
                message: 'Campo email Invalido!',
              },
            ]}
          >
            <Input
              onChange={handleChangeEmail}
              autoComplete='off'
              size='large'
              prefix={<MailOutlined />}
            />
          </Form.Item>
          <Form.Item
            label='Senha'
            name='senha'
            rules={[{ required: true, message: 'Senha Obrigatorio!' }]}
          >
            <Input.Password
              autoComplete='off'
              onChange={handleChangePass}
              size='large'
              prefix={<LockOutlined />}
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name='remember' noStyle>
              <Checkbox checked={getRemember} onChange={handleRemember}>
                Lembrar email e senha
              </Checkbox>
            </Form.Item>
          </Form.Item>
          <Form.Item label='Modo Admin' hidden={process.env.NODE_ENV === 'production'}>
            <Switch onChange={handleChangeAdm} checkedChildren='Sim' unCheckedChildren='Não' />
          </Form.Item>
          <Form.Item label='Modo Lab' hidden={process.env.NODE_ENV === 'production'}>
            <Switch onChange={handleChangeLab} checkedChildren='Sim' unCheckedChildren='Não' />
          </Form.Item>

          <a onClick={handleClickForgotPass} className='login-form-forgot' href=''>
            Esqueceu a Senha?
          </a>

          <Form.Item noStyle>
            <Button
              loading={loadingEntrar}
              size='large'
              block
              type='primary'
              htmlType='submit'
              className='login-form-button'
            >
              Entrar
            </Button>
          </Form.Item>
        </Form>
        <Row gutter={[8, 8]} hidden={forgotPass}>
          <p style={{ fontSize: '12px', textAlign: 'center' }}>
            Informe seu email cadastrado no sistema, clique em &quot;Redefinir senha&quot;, que
            enviaremos um email com um link de redefinição de senha.
          </p>
          <form onSubmit={handleSubmitReset} style={{ minWidth: '100%' }}>
            <Col span={24}>
              <Input
                size='large'
                prefix={<MailOutlined />}
                placeholder='Seu Email'
                type='email'
                name='forgot'
                id='emailexiste'
              />
            </Col>
            <br />
            <Col span={24}>
              <Button type='primary' size='large' block htmlType='submit'>
                {loading ? <SyncOutlined spin /> : 'Redefinir Senha'}
              </Button>
            </Col>
            <Col>
              <Link to='' onClick={handleClickForgotPass}>
                <ArrowLeftOutlined /> Voltar para Login
              </Link>
            </Col>
            <br />
          </form>
        </Row>
        <Typography style={{ textAlign: 'right', opacity: '0.8' }}>
          v{process.env.REACT_APP_VERSION}
        </Typography>
      </div>
      
      {exibirAvisoAtualizacao() &&(
        <PopupUpdate new_version={data_versao ?? '777'} />
      )}
      

      <Modal
        open={isModalVisible}
        onCancel={()=>{setIsModalVisible(false)}}
        destroyOnClose
        footer={false}
        width={600}
        style={{marginTop: -40}}
      >
        <Start 
          list_itens={[...(dataConta?.clientes ?? []), ...(dataConta?.contas ?? [])]}
          setAcesso={setAcesso}
          setCodCliente={setCodCliente}
          setLoggedInAs={setLoggedInAs}
          setDataConta={setDataConta}
          setTypeAccess={setTypeAccess}
          username={username}
        />
      </Modal>

    </Container>
  )
}
