import { createGlobalStyle, keyframes } from 'styled-components'

import MarkerIcon from './assets/svg/markerSelect.svg'

const jumping = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
`

const GlobalStyled = createGlobalStyle`

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', 'Montserrat', arial, sans-serif;
}


:root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
  
  main, .ant-table-body, .ant-layout-sider, .ant-drawer-body {
    overflow-y: auto;
    overflow-x: auto;
    scrollbar-width: thin;
  }

  body, html {
    height: 100vh;
  }

  html {
    --antd-wave-shadow-color: #8cbfa4;
    font-family: 'Poppins', 'Montserrat', arial, sans-serif !important;
  }

  aside {
    width: 15%;
    height: 99vh;
  }

  main {
    height: 90vh;
    overflow-y: auto;
    scrollbar-width: none;
    overflow-x: auto;
  }


  input[type="checkbox"]:focus-visible, input[type="checkbox"]:focus-within {
    border-color: #8cbfa4 !important;
    box-shadow: #8cbfa4 !important;
  }


  input:focus {
    border-color: #8cbfa4 !important;

    outline: 0;
  }

  input:focus-visible {
    border-color: #8cbfa4 !important;
    box-shadow: #8cbfa4 !important;
  }

  input:hover, input[type="checkbox"]:hover {
    border-color: #8cbfa4 !important;
  }

  .loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #4caf50;
}

  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  -webkit-overflow-scrolling: touch

  ;

  ::-webkit-scrollbar-track {
    background: #f4f4f4;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #b5b5b3;
    border-radius: 20px;;
  }


  font-smooth: inherit

  ;
  font-smooth: initial

  ;
  font-smooth: revert

  ;
  font-smooth: unset

  ;
  button .primaryButton {
    background-color: #5da57d;
  }

  code {
    margin: 0 1px;
    padding: 0.2em 0.4em;
    font-size: .9em;
    background: #f2f4f5;
    border: 1px solid rgba(0, 0, 0, .06);
    border-radius: 3px;
  }

  .ant-btn-default {
    color: #fff;
    background: #5eaeff;
    border-color: #5eaeff;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  }

  .ant-btn-warning {
    all: unset;
    color: #fff;
    height: 24px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #f0ad4e;
    border-color: #eea236;
    border-radius: 4px;
    box-shadow: 0 2px 0 rgba(11, 99, 36, 0.16);
    transition: 0.5s;

    &.ant-shape-circle {
      width: 22px;
      height: 32px;
      border-radius: 50%;
    }

    &:hover {
      color: #fff;
      background-color: #ec971f;
      border-color: #d58512;
    }

    &:active {
      color: #fff;
      background-color: #ec971f;
      background-image: none;
      border-color: #d58512;
    }
  }

  /* .ant-btn-default:hover, .ant-btn-default:focus {
    background-color: #4aa5ff;
    border-color: #4aa5ff;
    color: #ffffff;
  } */

  :where(.css-dev-only-do-not-override-uw9qmb).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #fff;
    border-color: #5eaeff;
    opacity: 0.8;
  }


  .ant-drawer-close {
    display: none;
  }


  .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector:active {
    border: 1px solid #5da57d;
  }

  .ant-layout-content {
    max-width: 99.5%;
    padding-left: 5px;
    padding-top: 5px;
  }

  .ant-pagination-item-active {
    border-color: #5da57d;
    color: #5da57d;

  }

  .ant-pagination-item-active a {
    color: #5da57d;
  }

  .ant-pagination-item-active:hover,
  .ant-pagination-item:hover {
    border-color: #417659;
    color: #417659;
  }

  .ant-pagination-item-active a:hover,
  .ant-pagination-item a:hover {
    color: #417659;
  }

  .trash-button {
    border-color: #ca5e51;
    background-color: #e46050;
  }

  .trash-button:hover {
    border-color: #c82333 !important;
    background-color: #c82333 !important;
    color: #ffffff !important;
  }

  .trash-button:focus {
    border-color: #c82333 !important;
    background-color: #c82333 !important;
    color: #ffffff;
  }

  .success-button {
    border-color: #5da57d;
    background-color: #5da57d;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, 
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, 
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: #5da57d;
}

  .success-button:hover {
    border-color: #5bb975;
    background-color: #5bb975;
    color: #ffffff !important;
  }

  .success-button:focus {
    border-color: #5bb975;
    background-color: #5bb975;

    color: #ffffff !important;
  }

  .primary-button {
    border-color: #007bff;
    background-color: #007bff;
  }

  .primary-button:hover {
    border-color: #0069d9;
    background-color: #0069d9;
  }

  .primary-button:focus {
    border-color: #0069d9;
    background-color: #0069d9;
  }

  .info-button {
    border-color: #17a2b8;
    background-color: #17a2b8;
  }

  .info-button:hover {
    border-color: #138496;
    background-color: #138496 !important;
  }

  .iconbutton {
    border-radius: 50%;
  }

  .info-button:focus {
    border-color: #138496;
    background-color: #138496;
  }

  .primary-button {
    border-color: #107381;
    background-color: #107381;
  }

  .primary-button:hover {
    border-color: #0c545f;
    background-color: #0c545f;
  }

  .primary-button:focus {
    border-color: #0c545f;
    background-color: #0c545f;
  }

  .warning-button {
    color: #252525;
    border-color: #bfbb04;
    background-color: #bfbb04;
  }

  .warning-button:hover {
    color: #252525;
    border-color: #a7a303;
    background-color: #a7a303;
  }

  .warning-button:focus {
    color: #252525;
    border-color: #a7a303;
    background-color: #a7a303;
  }

  .ant-spin-dot-item:nth-child(1) {
    background-color: green;
  }

  .ant-spin-dot-item:nth-child(2) {
    background-color: #36a800;
  }

  .ant-spin-dot-item:nth-child(3) {
    background-color: #46d700;
  }

  .ant-spin-dot-item:nth-child(4) {
    background-color: #94ff60;
  }

  .ant-menu-item span > span {
    position: relative;
    top: 3px !important;
  }

  .ant-menu-submenu-title span:nth-child(2) {
    position: relative;
    top: 3px !important;
  }

  .ant-menu-submenu-title .ant-menu-submenu-arrow {
    top: 25px !important;
  }

  .ant-form-vertical .ant-form-item-label {
    padding: 0;
  }

  .ant-layout-header {
    padding: 0;
    height: 76px;
  }

  li.ant-list-item {
    transition: 0.2s;
    border-radius: 5px;
  }

  li.ant-list-item:hover {
    padding-left: 3px;
    background: #d0d0d0 !important;
  }

 .ant-tab-green {
   .ant-tabs-nav-wrap {
    background-color: #5da57d;
  }

  .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
    background-color: #5da57d;
    color: #ffffff;
    border: none;
    margin: 10px 10px 0 10px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #5da57d !important;

  }

  .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #ffffff;
  }

  .ant-tabs-content {
    margin-left: 15px;
    margin-right: 15px;
  }
 }

  .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
    margin-left: 0;
  }

  .timeline-Arrow-icon {
    font-size: 16px;
  }

  [data-theme='compact'] .timeline-clock-icon {
    font-size: 14px;
  }

  .upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-right: 8px;
  }


  .upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
    float: right;
  }

  .capitalized {
    text-transform: capitalize;
  }

  .ant-table-filter-dropdown-btns {
    button:first-child {
      span {
        font-size: 0;
      }

      span::after {
        content: "Limpar" !important;
        font-size: 15px;
      }
    }
  }


  .ant-slider-handle, .ant-slider-track {
    border-color: #8cbfa4;
  }

  .ant-slider-handle, .ant-slider-track {
    border-color: #8cbfa4;
  }


  .ant-slider-handle:hover {
    border-color: #5da57d;
  }

  .ant-slider .ant-slider-track {
    background-color: #8cbfa4;
  }

  .ant-slider:hover .ant-slider-track {
    background-color: #5da57d;
  }

  .ant-slider:focus .ant-slider-track {
    background-color: #5da57d;
  }

  .ant-slider:active .ant-slider-track {
    background-color: #5da57d;
  }

  .table-row-light {
    background-color: #ffffff;
  }

  .table-row-dark {
    background-color: #fbfbfb;
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #69cd76;
  }

  .ant-btn-dangerous {
    border: 1px solid #ff4d4f !important
  }

  .ant-btn-dangerous:hover {
    border: 1px solid #FF7875 !important
  }

  .ant-btn-dangerous:disabled {
    border: 1px solid #d9d9d9 !important
  }

  .popover-content-user {
    min-width: 150px;

    ul {
      list-style: none;
      padding: 0;
      display: inline;

      li {
        padding: 8px;

        a {
          text-decoration: none;
          color: #484848;

          span {
            padding-left: 8px;
          }
        }

        a:hover {
          color: #5da57d;
        }
      }
    }
  }

  .ant-modal-wrap {
    overflow: hidden;
  }

  .ant-popover-message > .anticon {
    color: #17a2b8;
  }

  a[href^="http://maps.google.com/maps"] {
    display: none !important
  }

  a[href^="https://maps.google.com/maps"] {
    display: none !important
  }

  a[href^="https://leafletjs.com"] {
    display: none !important
  }

  .gmnoprint a, .gmnoprint span, .gm-style-cc {
    display: none;
  }

  .gmnoprint div {
    background: none !important;
  }

  ::selection {
    background: #208e52;
    color: #fff;
  }

  ::-moz-selection {
    background: #208e52;
    color: #fff;
  }

  tr.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
  }

  tr.row-dragging td {
    padding: 16px;
  }

  tr.row-dragging .drag-visible {
    visibility: visible;
  }

  .ant-select.readOnly{
	  pointer-events: none;
  }

  .ant-input-search-button {
    background-color: #5da57d;
    border: #5da57d;

    .anticon-search {
      color: #fff;
    }

    &:hover {
      background-color: #559974;
      border: #559974;
    }
  }

  .input-no-label {
    padding-top: 32px !important
  }

  @keyframes blinkingFade {
    from {
      margin-top: -50px;
    }
  }

  .L-blinking {
    animation: blinkingFade 0.5s infinite alternate;
  }

  .L-hide-marker {
    opacity: 0;
    transition: 0.5s;
  }

  .L-show-marker {
    opacity: 1;
    transition: 0.5s;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    align-items: center !important;
  }

  .zoom-map {
    margin-right: 50px;
  }

  .drawer-map .ant-drawer-content {
    overflow: hidden !important;
  }


  .ant-card .ant-card-bordered .ant-card-small{
    resize: horizontal !important;
  }
  
  td.descriptions-title {
    padding-bottom: 0 !important;
  }
  
  .nc-acima {
    background-color: rgba(245, 200, 39, 1);
    font-weight: bold;
  }

  .aly-text-ping {
    margin-bottom: 12px;
  }
  .aly-text-ping.media {
    margin-bottom: 0%;
    margin-top: 13px;
  }
  .media-text-ping {
    margin-top: 10px
  }

  :where(.css-dev-only-do-not-override-sk7ap8).ant-checkbox-group {
    flex-direction: column !important;
  }

  .ant-notification-notice-warning {
    border-left: 6px solid #E8BA00;
  }
  .ant-notification-notice-success {
    border-left: 6px solid #42D651;
  }
  .ant-notification-notice-error {
    border-left: 6px solid #FF5757;
  }
  .ant-notification-notice-info {
    border-left: 6px solid #4B97E3;
  }

  .leaflet-tooltip-bottom, .leaflet-tooltip-top {
    background-color: transparent;
    border: none !important;
    font-weight: 600;
    box-shadow: none;
  }

  .leaflet-tooltip-bottom:before, .leaflet-tooltip-top:before {
    display: none;
  }

  .transfer-responsive {
    display: flex;
    flex-direction: column;

    .ant-transfer-operation {
      flex-direction: row;
      button {
        transform: rotate(90deg);
      }
    }
  }

.jumping-pin {
  transition: 0.5;
  animation: ${jumping} 0.702s infinite;
}

.map-style {
  background-color: #fff;
  border-radius: 10px;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.12), 0 3px 12px rgba(0, 0, 0, 0.24);
  }

.icon-div-box {
  background-color: transparent;
}

.icon-div-leaflet {
  width: 30px;
  height: 40px;
  background-image: url(${MarkerIcon});
  background-color: transparent;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  b {
    margin-bottom: 10px;
    font-size: 14px;
  }
}

.ck-powered-by-balloon {
  display: none !important;
}

.ant-menu-title-content {
  padding-top: none important;
}

`

export default GlobalStyled
