import React, { useEffect, useRef, useState } from 'react'

import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  InputRef,
  Modal,
  Row,
  Select,
  Space,
  message,
} from 'antd'

import dayjs from 'dayjs'
import { getSafra, getSafraEstatisticas, postSafra, putSafra } from '../../services/Safra'

import { tiposGerais } from '../../services/Afins'
import { ItensPerUserLogged } from '../../services/login/permissaoUsuarioLoado'
import DatePicker from '../DatePiker'
import { Notification } from '../notification'
import { filter } from 'lodash'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { IconAlert } from '../iconsAlert'
import { CampoEstatística, Container } from './styled'
import moment from 'moment'
import DiasDesdeData from '../DiasDesdeData'
import { getPropriedade } from '../../services/Propriedades'
import { useAccess } from '../../context/useAccess'

const { Option } = Select

interface TypesLaboratorio {
  update: number
  visible: boolean
  setOnClose: (data: boolean) => void
  setUpdate: (data: number) => void
  setCodSafra: (data: number) => void
  atualizar: () => void
  permissions: ItensPerUserLogged
  listSafra: any
  codSafra: number
}

const Safra: React.FC<TypesLaboratorio> = (props) => {
  const {
    update,
    visible,
    setOnClose,
    setUpdate,
    setCodSafra,
    atualizar,
    codSafra,
    listSafra,
    permissions,
  } = props

  const inputFoco = useRef<InputRef>()
  const { speak } = useAccess()

  const [form] = Form.useForm()

  const [getCultura, setCultura] = useState([])
  const [culturaSecundaria, setCulturaSecundaria] = useState([])
  const [logUser, setLogUser] = useState(false)
  const [usuarioDeRegistro, setUsuarioDeRegistro] = useState('')
  const [dataDeRegistro, setDataDeRegistro] = useState('')
  const [criadoUpdate, setCriadoUpdate] = useState<number>()
  const [dadosEstatisticas, setDadosEstatisticas] = useState([])
  const [desabilitar, setDesabilitar] = useState(true)

  const ativarLeitor = localStorage.getItem('leitorVirtual')

  const hancleDrawerClose = () => {
    setOnClose(false)
    setUpdate(0)
    setDataDeRegistro('')
    setUsuarioDeRegistro('')
    form.resetFields()
    setDadosEstatisticas([])
    setCulturaSecundaria([])
    setDesabilitar(true)
  }

  const handleChangeServico = async (data) => {
    message.loading('Aguarde...')
    const response = await getSafra({ cod: data })
    const dados = response.data.itens[0]
    const dadosNome = response.data.itens.find((item) => item.cod === data)
    dados.data_inicio = dayjs(dados.data_inicio)
    dados.data_final = dayjs(dados.data_final)
    filterCulturaSecundaria(dadosNome)
    form.setFieldsValue(dados)
    message.destroy()
  }

  const listTotalPropriedade = async (cod) => {
    const response = await getSafraEstatisticas()
    const filter = response.data.itens.filter((item) => item.cod === cod)
    setDadosEstatisticas(filter)
  }

  const listTiposGerais = async () => {
    const response = await tiposGerais()

    setCultura(response)
  }

  function limparString(str) {
    // Substitui números e caracteres especiais por espaços em branco
    const stringLimpa = str?.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ\s]/g, '')

    // Remove espaços extras e converte para minúsculas
    const stringFinal = stringLimpa?.trim().toLowerCase()

    return stringFinal
  }

  function detectarPalavrasEspecificas(texto, palavras) {
    const regex = new RegExp(`\\b(${palavras.join('|')})\\b`, 'gi')
    const resultado = texto?.match(regex)
    return resultado ? resultado[0] : null
  }

  function filterCulturaSecundaria(data) {
    const nome = update > 0 ? data?.nome : data?.target?.value
    const cult = getCultura.map((item) => item?.nome)
    const final = detectarPalavrasEspecificas(nome, cult)
    const filterNome = getCultura.filter((rec) => rec?.nome?.toLowerCase() !== final?.toLowerCase())
    setCulturaSecundaria(filterNome)
  }

  const handleFormChange = () => {
    if (update <= 0) {
      const values = form.getFieldsValue()
      const { cod_cultura_secundaria, ...fieldsToValidate } = values
      const todosPreenchidos = Object.values(fieldsToValidate).every((value) => value)
      setDesabilitar(!todosPreenchidos)
    } else {
      setDesabilitar(false)
    }
  }

  const onFinish = async (data) => {
    data.data_inicio = data?.data_inicio?.format('DD/MM/YYYY')
    data.data_final = data?.data_final?.format('DD/MM/YYYY')

    const handleCriarSafra = async () => {
      message.loading('Aguarde...')

      const response = await postSafra(
        data,
        form.resetFields,
        atualizar,
        message.destroy,
        desabilitar
      )
      setDesabilitar(true)
      Modal.destroyAll()
    }

    const cult = getCultura?.filter((item) => item?.cod === data?.cod_cultura)

    const { nome } = cult?.[0]

    const nomeSafra = data?.nome

    const nomeSafraFinal = limparString(nomeSafra)

    // Cria uma expressão regular dinâmica com a palavra contida em 'nome'
    const regex = new RegExp(`\\b${nome?.toLowerCase()}\\b`)

    // Encontra a correspondência usando a expressão regular
    const match = nomeSafraFinal?.toLowerCase().match(regex)

    if (update === 0) {
      // message.loading('Aguarde...')

      if (!match) {
        Modal.confirm({
          title:
            'Parece que o nome que foi dado à safra não corresponde a cultura informada. Deseja continuar assim mesmo ou corrigir a cultura?',
          icon: <IconAlert type='warning' size={6} />,
          type: 'warning',
          okCancel: false,

          footer: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                style={{
                  marginRight: 8,

                  background: '#81b395',
                  borderColor: '#81b395',
                  color: '#fff',
                }}
                onClick={() => handleCriarSafra()}
              >
                Continuar
              </Button>
              <Button type='primary' onClick={() => Modal.destroyAll()} style={{ marginLeft: 8 }}>
                Corrigir
              </Button>
            </div>
          ),
        })
      } else {
        handleCriarSafra()
      }
    } else {
      const handleAtualizarSafra = async () => {
        message.loading('Aguarde...')
        const response = await putSafra(update, data, atualizar, ativarLeitor)

        if (response != undefined) {
          Notification({
            message: `${response}`,
            type: 'error',
            duration: 5,
          })
          speak(`${response}`, ativarLeitor)
        }

        Modal.destroyAll()
      }

      if (!match) {
        Modal.confirm({
          title:
            'Parece que o nome que foi dado à safra não corresponde a cultura informada. Deseja continuar assim mesmo ou corrigir a cultura?',
          icon: <IconAlert type='warning' size={6} />,
          type: 'warning',
          okCancel: false,

          footer: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                style={{
                  marginRight: 8,

                  background: '#81b395',
                  borderColor: '#81b395',
                  color: '#fff',
                }}
                onClick={() => handleAtualizarSafra()}
              >
                Continuar
              </Button>
              <Button type='primary' onClick={() => Modal.destroyAll()} style={{ marginLeft: 8 }}>
                Corrigir
              </Button>
            </div>
          ),
        })
      } else {
        handleAtualizarSafra()
      }
    }
  }

  useEffect(() => {
    if (update > 0) {
      handleChangeServico(update)
      listTotalPropriedade(update)
      setDesabilitar(false)
    } else {
      form.resetFields()
      inputFoco.current?.focus({
        cursor: 'start',
      })
    }
  }, [update])

  useEffect(() => {
    listTiposGerais()
  }, [])

  useEffect(() => {
    if (update === 0) {
      setLogUser(false)
      setUsuarioDeRegistro('')
      setDataDeRegistro('')
    }
  }, [update])

  useEffect(() => {
    if (visible) {
      const safraCheck = listSafra.filter((item) => item.cod === codSafra)
      if (update > 0 && safraCheck[0].create_user) {
        setUsuarioDeRegistro(safraCheck[0].create_user)
        setDataDeRegistro(safraCheck[0].create_time)
        setLogUser(true)
        setCriadoUpdate(0)
      }

      if (update > 0 && safraCheck[0].update_user != null) {
        setUsuarioDeRegistro(safraCheck[0].update_user)
        setDataDeRegistro(safraCheck[0].update_time)
        setCriadoUpdate(1)
        setLogUser(true)
      }
    }
  }, [visible])

  return (
    <Container>
      <Form form={form} layout='vertical' onFinish={onFinish} onValuesChange={handleFormChange}>
        <Drawer
          title={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <>{update ? 'Atualizar Safra' : 'Cadastrar Safra'}</>

              <small style={{ opacity: '0.5', fontSize: '10px' }}>
                {logUser === true ? (
                  <>
                    {' '}
                    {criadoUpdate > 0 ? 'Atualizado por:' : 'Criado por:'}{' '}
                    {`${usuarioDeRegistro} ${dayjs(dataDeRegistro).format('DD/MM/YYYY HH:mm:ss')}`}
                  </>
                ) : null}
              </small>
            </div>
          }
          style={{ position: 'absolute' }}
          placement='left'
          width='350px'
          onClose={hancleDrawerClose}
          open={visible}
          closeIcon={false}
          getContainer={false}
          footer={
            <div
              style={{
                textAlign: 'left',
              }}
            >
              <Button
                hidden={permissions?.alteracao === 0 && permissions?.inclusao === 0}
                type='primary'
                htmlType='submit'
                disabled={desabilitar}
              >
                {update ? 'Atualizar Safra' : 'Salvar'}
              </Button>
              {'   '}
              <Button danger type='primary' onClick={hancleDrawerClose} style={{ marginRight: 8 }}>
                Fechar
              </Button>
            </div>
          }
        >
          <Col span={24}>
            <Row gutter={[8, 8]}>
              <Col flex='1 1 200px'>
                <Form.Item label='Nome' name='nome'>
                  <Input
                    onChange={(e) => filterCulturaSecundaria(e)}
                    readOnly={permissions?.acesso === 1}
                    ref={inputFoco}
                  />
                </Form.Item>
              </Col>
              <div style={{ width: '100%' }}>
                <Col flex='1 1 150px'>
                  <Form.Item label='Cultura' name='cod_cultura'>
                    <Select
                      className={permissions?.acesso === 1 ? 'readOnly' : ''}
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        option.children
                          ? option?.children
                              ?.toString()
                              ?.toLowerCase()
                              ?.indexOf(input?.toLowerCase()) >= 0
                          : false
                      }
                    >
                      {getCultura.map((data) => (
                        <Option key={data.cod} value={data.cod}>
                          {data.nome}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col flex='1 1 150px'>
                  <Form.Item label='Cultura secundaria' name='cod_cultura_secundaria'>
                    <Select
                      className={permissions?.acesso === 1 ? 'readOnly' : ''}
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        option.children
                          ? option?.children
                              ?.toString()
                              ?.toLowerCase()
                              ?.indexOf(input?.toLowerCase()) >= 0
                          : false
                      }
                    >
                      {culturaSecundaria.map((data) => (
                        <Option key={data.cod} value={data.cod}>
                          {data.nome}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </div>
              <Col flex='0 1 175px'>
                <Form.Item label='Data Início' name='data_inicio'>
                  <DatePicker
                    inputReadOnly={permissions?.acesso === 1}
                    popupStyle={{
                      display: permissions?.acesso === 1 ? 'none' : '',
                    }}
                    allowClear={false}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col flex='0 1 175px'>
                <Form.Item label='Data Fim' name='data_final'>
                  <DatePicker
                    inputReadOnly={permissions?.acesso === 1}
                    popupStyle={{
                      display: permissions?.acesso === 1 ? 'none' : '',
                    }}
                    allowClear={false}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col flex='0 1 100%'>
                {update ? (
                  <Form.Item label='Detalhes'>
                    <CampoEstatística>
                      <div>
                        {dadosEstatisticas.map((item) => {
                          return (
                            <>
                              <p>Total de propriedades: {item.total_propriedades}</p>
                              <p>Total de talhões: {item.total_talhoes}</p>
                              <p>Área total: {item.total_area_talhoes} ha</p>
                            </>
                          )
                        })}
                      </div>
                    </CampoEstatística>{' '}
                  </Form.Item>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Col>

          {/* Hiddenn */}

          <Form.Item initialValue='1' hidden name='status'>
            <Input readOnly={permissions?.acesso === 1} />
          </Form.Item>
        </Drawer>
      </Form>
    </Container>
  )
}

export default Safra
