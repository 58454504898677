import styled from 'styled-components'

export const Container = styled.div`
  .ant-layout-content {
    overflow: hidden;
  }
  .ant-drawer-body {
    overflow: hidden;
  }
`

export const ContainerEquipe = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

`
export const HeaderFechar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
`

export const FormContainer = styled.div`
  width: 100%;
  height: 100%;

  .table-keys {
    display: flex;
    gap: 10px;
    background-color: #eee;
    border-radius: 5px;
    padding: 10px;
  }

  .membro-equipe {
    display: flex;
    width: 15%;
    gap: 10px;
    border-radius: 5px;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
  }

  .dados-complementares {
    display: flex;
    width: 20%;
    gap: 10px;
    border-radius: 5px;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
  }

  .documentos {
    display: flex;
    gap: 10px;
    width: 13%;
    padding: 10px;
    border-radius: 5px;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
  }

  .saude {
    display: flex;
    width: 12%;
    gap: 10px;
    padding: 10px;
    border-radius: 5px;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
  }

  .acessos-permissoes {
    display: flex;
    gap: 10px;
    width: 18%;
    border-radius: 5px;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
  }

  .acessos-propriedade {
    display: flex;
    gap: 10px;
    width: 18%;
    border-radius: 5px;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
  }

  .form-inputs {
    display: flex;
    padding-top: 10px;
  }

  .campo-foto {
    width: 23%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

  }

  .nome-usuario {
    margin: 0;
    padding: 0;
    color: #5da57d;
    font-size: 11px;
    font-weight: 600;
  }

  .foto-profile {
    width: 200px;
    display: flex;
    justify-content: center !important;
    align-items: center;
    height: 200px;

    /* Estilização do contêiner de upload */
    .ant-upload.ant-upload-select-picture-card,
    .ant-upload-list-picture-card-container {
      width: 200px !important;
      height: 200px !important;
      display: flex;
    }

    /* Estilização do item de upload */
    .ant-upload-list-item {
      width: 200px !important;
      height: 200px !important;
      
    }

    /* Garantir que a imagem se ajuste ao contêiner */
    .ant-upload-list-item-thumbnail {
      width: 100%;
      height: 100%;
    }

    .ant-upload-list-item-thumbnail img {
      object-fit: cover; /* Mantém a proporção da imagem, cobrindo todo o espaço */
      width: 100%;
      height: 100%;
    }

    ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
      width: 100%;
    }

    .ant-upload.ant-upload-select-picture-card,
    .ant-upload-list-picture-card-container {
      width: 100%;
      max-width: 100%;
      min-height: 200px;
    }

    .ant-upload {
      width: 200px !important;
      height: 200px !important;
    }

    .ant-upload-list-item-done {
      width: 207px !important;
      height: 200px !important;
    }

    /* Estilização do ícone e texto descritivo */
    .desc-foto {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .foto-upload {
    width: 100% !important;
    height: 200px;
    display: flex !important;
    flex-direction: column !important;
  }

  .icone-upload {
    width: 100%;
    font-size: 22px;
    color: #5da57d;
  }

  .desc-foto-p {
    font-size: 12px;
    color: #000;
    opacity: 0.6;
    margin: 0;
    padding: 0;
  }

  .campo-inputs {
    width: 100%;
    height: 100%;
    padding: 20px;
  }

  

  .btn-salvar-form {
    width: 100%;
    display: flex;
    padding: 20px;
    justify-content: flex-end;
  }
`
