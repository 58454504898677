import { Avatar, Button, Drawer, Modal, Space, Switch, Table, TableColumnsType, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon as FontA } from '@fortawesome/react-fontawesome'
import CadCliente from '../../../Components/cliente'
import Contratos from '../../../Components/contratos'
import { Notification } from '../../../Components/notification'
import RepresentanteLegal from '../../../Components/representantelegal'
import { useLogic } from '../../../context/useLogic'
import { ClienteItensTypes, delCliente, getCliente, putCliente } from '../../../services/Cliente'

import ClienteModulo from '../../../Components/ClienteModulos'
import { pagination } from '../../../Components/Default'
import { IconAlert } from '../../../Components/iconsAlert'
import ViewPage from '../../../Components/ViewPage'
import { tryError } from '../../../services/Afins'
import { ItensPerUserLogged } from '../../../services/login/permissaoUsuarioLoado'
import { Container } from './style'
import DiasDesdeData from '../../../Components/DiasDesdeData'
import dayjs from 'dayjs'
import GeneratePdfButton from '../../../Components/relatorio-component'
import { formatarValor } from '../../../utils'

const { TabPane } = Tabs

const Cliente: React.FC = () => {
  const { dataCliente, setDataCliente } = useLogic()

  const [permission, setPermission] = useState<ItensPerUserLogged>()

  const [visible, setVisible] = useState(false)
  const [saveCad, setSaveCad] = useState('')
  const [loginTable, setLoginTable] = useState(false)
  const [disableTab, setDisableTab] = useState(false)
  const [hideStatus, setHideStatus] = useState(false)
  const [loadingStatus, setLoadingStatus] = useState(null)
  const [idCliente, seIdCliente] = useState(0)
  const [tabSelected, setTabSelected] = useState('1')
  const [statusCliente, setStatusCliente] = useState(1)
  const [dataTableRelative, setDataTableRelative] = useState<ClienteItensTypes[]>([])
  const [typeSubmit, setTypeSubmit] = useState({
    type: '',
    submit: false,
    tab: '1',
  })
  const [clienteSelect, setClienteSelect] = useState(0)
  const [tabSelecionada, setTabSelecionada] = useState<string>('1')
  const [clienteName, setClienteName] = useState()
  const [logUser, setLogUser] = useState(true)
  const [usuarioQueCriou, setUsuarioQueCriou] = useState('')
  const [usuarioQueAlterou, setUsuarioQueAlterou] = useState('')
  const [dataCriacao, setDataCriacao] = useState('')
  const [horarioDeCriacao, setHorarioDeCriacao] = useState('')
  const [horarioDeAtualizacao, setHorarioDeAtualizacao] = useState('')
  const [dataAlteracao, setDataAlteracao] = useState('')
  const [diasDesdeCriacao, setDiasDesdeCriacao] = useState(null)
  const [dadosEstatisticas, setDadosEstatisticas] = useState([])
  const [dadosResultado, setDadosResultado] = useState([])
  const [nomePropriedade, setNomePropriedade] = useState('')

  const handleDataFromCreation = (dias) => {
    setDiasDesdeCriacao(dias)
  }

  const onShow = () => {
    setVisible(true)
  }

  const onclose = () => {
    setVisible(false)
    handleTabSelect('1')
  }
  const handleChangeSwitchOcultarInativo = (e) => {
    refresh(true, e)
    setHideStatus(e)
  }

  const handleChangeSwitchStatus = async (cod, index, status: number) => {
    try {
      setLoadingStatus(index)
      await putCliente(cod, {
        status: status === 1 ? 0 : 1,
      })

      refresh()
    } catch (error) {
      tryError(error)
      setLoadingStatus(null)
    }
  }

  const colums: TableColumnsType<ClienteItensTypes> = [
    {
      title: (
        <Space style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
          Ocultar inativos{' '}
          <Switch
            onChange={handleChangeSwitchOcultarInativo}
            size='default'
            checkedChildren='Sim'
            unCheckedChildren='Não'
          />
        </Space>
      ),
      align: 'right',
      children: [
        {
          title: 'Ativo',
          dataIndex: 'status',
          width: 70,
          align: 'center',
          key: 'status',
          render: (status, record, i) => {
            return (
              <Switch
                loading={loadingStatus === i}
                size='small'
                onChange={() => handleChangeSwitchStatus(record.cod, i, status)}
                checked={status === 1}
                checkedChildren='Sim'
                unCheckedChildren='Não'
              />
            )
          },
        },
        {
          title: 'Logo',
          dataIndex: 'logo',
          key: 'logo',
          width: 53,
          render: (logo, data) => {
            const nome = data.nome.split(' ')

            return (
              <Avatar src={logo}>
                <b>
                  {nome?.[0]?.substring(0, 1).toUpperCase()}
                  {nome?.[1]?.substring(0, 1).toUpperCase()}
                </b>
              </Avatar>
            )
          },
        },
        {
          title: 'Nome',
          dataIndex: 'nome',
          key: 'nome',
        },
        {
          title: 'Razão Social',
          dataIndex: 'razao_social',
          key: 'razao_social',
        },
        {
          title: 'Ações',
          dataIndex: 'cod',
          align: 'center',
          width: '90px',
          key: 'acao',
          render: (data, dados) => (
            <Space>
              <Button
                shape='circle'
                hidden={permission?.alteracao === 0 && permission?.acesso === 0}
                onClick={() => handleClickCliente(data, dados)}
                icon={<FontA icon={permission?.acesso === 1 ? solid('eye') : solid('pen')} />}
                size='middle'
              />
              <Button
                shape='circle'
                onClick={() => handleTrashCliente(data)}
                hidden={permission?.exclusao === 0}
                className='trash-button'
                type='primary'
                icon={<FontA icon={solid('trash')} />}
                size='middle'
              />
            </Space>
          ),
        },
      ],
    },
  ]

  const handleTrashCliente = async (codCli) => {
    const conta = localStorage.getItem('conta')
    const response = await getCliente(codCli, '', '', 'estatisticas')
    const itens = response.data.itens[0]

    const { nome, cod } = itens
    Modal.confirm({
      title: 'Excluir',
      icon: <IconAlert type='confirm' size={6} />,
      content: `Deseja realmente remover o Cliente ${nome}?`,
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      cancelButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: async () => {
        const responseDel = await delCliente(conta, cod)

        if (responseDel.status === 200) {
          Notification({
            message: 'Cliente Excluido',
            type: 'success',
          })
          refresh()
        }
      },
    })
  }

  const handleClickNewCliente = () => {
    onShow()
    setDisableTab(true)
    setSaveCad('Salvar')
    seIdCliente(0)
    setTypeSubmit({ type: 'POST', submit: false, tab: '1' })
  }

  const handleClickCliente = (data, dados) => {
    if (dados.tipo === 'F') {
      setDisableTab(true)
    } else {
      setDisableTab(false)
    }

    setStatusCliente(dados.status)

    setClienteSelect(data)

    onShow()
    seIdCliente(data)
    setTypeSubmit({ type: 'PUT', submit: false, tab: '1' })
    setSaveCad('Atualizar Cliente')
  }

  const handlesPesquisa = (data) => {
    setLoginTable(true)
    const dados = dataCliente
    const nome: any = data.target.value.toLowerCase()

    setTimeout(() => {
      const response = dados.filter((info) => info.nome.toLowerCase().indexOf(nome) !== -1)
      setDataTableRelative(response)
      setLoginTable(false)
    }, 500)
  }

  const handleSubmitClient = () => {
    setTypeSubmit({ type: typeSubmit.type, submit: true, tab: tabSelecionada })

    return typeSubmit
  }

  const refresh = async (loading = false, hide = false) => {
    if (loading) {
      setLoginTable(true)
    }
    const dataClientes = await getCliente('', '', '', 'estatisticas')
    const dados = dataClientes.data.itens
    const filterAtivo = dados.filter((rec) => rec.status !== 0)
    const filterInativo = dados.filter((rec) => rec.status === 0)

    const resultadoAtivo = filterAtivo.reduce(
      (soma, item) => {
        //@ts-ignore
        return {
          //@ts-ignore

          area: soma.area + parseFloat(item?.estatisticas?.total_area_ativa),
        }
      },
      {
        area: 0,
      }
    )
    const resultadoInativo = filterInativo.reduce(
      (soma, item) => {
        //@ts-ignore
        return {
          //@ts-ignore

          area: soma.area + parseFloat(item?.estatisticas?.total_area_ativa),
        }
      },
      {
        area: 0,
      }
    )

    const resultadoFinal = [
      {
        totalizado_area_ativo: formatarValor(resultadoAtivo?.area),
        totalizado_area_inativo: formatarValor(resultadoInativo?.area),
      },
    ]

    setDataCliente(hide ? filterAtivo : dados)
    setDataTableRelative(hide ? filterAtivo : dados)
    gerarTabela(hide ? filterAtivo : dados)
    gerarTabela2(resultadoFinal, filterAtivo.length, filterInativo.length)
    setLoginTable(false)
    setLoadingStatus(null)
  }

  function gerarTabela(dados) {
    const data = dados?.map((item) => {
      return [
        { text: item?.nome, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
        {
          text: item?.tipo === 'J' ? item?.razao_social : '',
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'center',
        },
        {
          text: dayjs(item?.nascimento).format('DD/MM/YYYY'),
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'center',
        },
        {
          text: formatarValor(parseFloat(item?.estatisticas?.total_area_ativa)),
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'right',
        },
        {
          text: item?.estatisticas?.total_propriedades_ativas,
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'center',
        },
        {
          text: item?.status === 1 ? 'Ativo' : 'Inativo',
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'center',
        },
      ]
    })

    const table = [
      {
        table: {
          headerRows: 1,
          widths: ['*', '*', '*', '*', '*', '*'],
          body: [
            [
              { text: 'Nome', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Razão social', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              {
                text: 'Data nascimento / fundação',
                style: 'tableHeader',
                fontSize: 9,
                alignment: 'center',
              },
              { text: 'Área total', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Propriedades', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Status', style: 'tableHeader', fontSize: 9, alignment: 'center' },
            ],
            ...data,
          ],
          dontBreakRows: true,
          keepWithHeaderRows: 1,
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            if (rowIndex === 0) {
              return null;
            }
            const status = node.table.body[rowIndex][5].text;
    
            return status === 'Inativo' ? '#ececec' : null
          },
        },
      },
    ]

    setDadosEstatisticas(table)
  }

  function gerarTabela2(dados, lengthAti, lengthInat) {
    const data = dados?.map((item) => {
      return [
        { text: lengthAti, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
        { text: lengthInat, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
        {
          text: item?.totalizado_area_ativo,
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'center',
        },
        {
          text: item?.totalizado_area_inativo,
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'center',
        },
        
      ]
    })

    const dataAtivo = dados?.map((item) => {
      return [
        { text: lengthAti, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
        {
          text: item?.totalizado_area_ativo,
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'center',
        },
        
      ]
    })

    const table = [
      {
        table: {
          headerRows: 1,
          widths: ['*','*','*', '*'],
          body: [
            [
              {
                text: 'Total de clientes ativo',
                style: 'tableHeader',
                fontSize: 9,
                alignment: 'center',
              },
              {
                text: 'Total de clientes inativo',
                style: 'tableHeader',
                fontSize: 9,
                alignment: 'center',
              },
              {
                text: 'Total de área de clientes ativos',
                style: 'tableHeader',
                fontSize: 9,
                alignment: 'center',
              },
              {
                text: 'Total de área de clientes inativos',
                style: 'tableHeader',
                fontSize: 9,
                alignment: 'center',
              },
            ],
            ...data,
          ],
          dontBreakRows: false,
          keepWithHeaderRows: 1,
          margin: [0, 50, 0, 0],
          alignment: 'center',
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#fafafa' : null
          },
        },
      },
    ]

    const tableAtivo = [
      {
        table: {
          headerRows: 1,
          widths: ['*','*'],
          body: [
            [
              {
                text: 'Total de clientes ativo',
                style: 'tableHeader',
                fontSize: 9,
                alignment: 'center',
              },
              {
                text: 'Total de área de clientes ativos',
                style: 'tableHeader',
                fontSize: 9,
                alignment: 'center',
              },
            ],
            ...dataAtivo,
          ],
          dontBreakRows: false,
          keepWithHeaderRows: 1,
          margin: [0, 50, 0, 0],
          alignment: 'center',
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#fafafa' : null
          },
        },
      },
    ]
    const tableFiltrada = hideStatus ? table : tableAtivo 
    setDadosResultado(tableFiltrada)
  }

  const handleTabSelect = (data) => {
    setTypeSubmit({
      type: '',
      submit: false,
      tab: '1',
    })
    setTabSelecionada(data)
    setTabSelected(data)

    switch (data) {
      case '1':
        setSaveCad(typeSubmit.type === 'PUT' ? 'Atualizar Cliente' : 'Salvar Cliente')
        break
      case '2':
        setSaveCad('Salvar Representante')
        break
      case '3':
        setSaveCad('Salvar Contrato')
        break
      default:
        setSaveCad('Salvar')
        break
    }
  }

  useEffect(() => {
    refresh(true)
  }, [])

  useEffect(() => {
    if (visible) {
      const clienteCheck = dataCliente.filter((item) => item.cod === clienteSelect)

      if (clienteCheck.length > 0) {
        if (clienteCheck[0].create_user) {
          setUsuarioQueCriou(clienteCheck[0].create_user)

          const dateOnlyString = clienteCheck[0].create_time.split(' ')[0]
          const timeOnlyString = clienteCheck[0].create_time.split(' ')[1]

          setDataCriacao(dateOnlyString)
          setHorarioDeCriacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }

        if (clienteCheck[0].update_user || clienteCheck[0].update_user) {
          setUsuarioQueAlterou(clienteCheck[0].update_user)

          const dateOnlyString = clienteCheck[0].update_time.split(' ')[0]
          const timeOnlyString = clienteCheck[0].update_time.split(' ')[1]

          setDataAlteracao(dateOnlyString)
          setHorarioDeAtualizacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }
      }
    } else {
      setUsuarioQueCriou('')
      setUsuarioQueAlterou('')
    }

    if (visible === false) {
      setLogUser(false)
    }
  }, [logUser, visible, usuarioQueAlterou, usuarioQueCriou, horarioDeCriacao])

  return (
    <Container>
      <ViewPage
        title='Clientes'
        rotina={5}
        onPermission={setPermission}
        btnClick={handleClickNewCliente}
        btnsExta={
          <div>
            {localStorage.getItem('tipo-acesso') === 'conta' ? (
              <GeneratePdfButton
                dados={dadosEstatisticas}
                title={'Relatório de clientes'}
                totais={dadosResultado}
                disable={dataTableRelative.length <= 0}
              />
            ) : null}
          </div>
        }
        search
        inputChange={handlesPesquisa}
        content={
          <Drawer
            style={{ position: 'absolute' }}
            bodyStyle={{ padding: 0 }}
            placement='right'
            width='100%'
            onClose={onclose}
            open={visible}
            closeIcon={false}
            getContainer={false}
            footer={
              <div
                style={{
                  textAlign: 'left',
                }}
              >
                <Button
                  hidden={permission?.inclusao === 0 && permission?.alteracao === 0}
                  onClick={handleSubmitClient}
                  type='primary'
                  htmlType='submit'
                >
                  {saveCad}
                </Button>
                {'   '}
                <Button danger type='primary' onClick={onclose} style={{ marginRight: 8 }}>
                  Fechar
                </Button>

                <Space className='log-user'>
                  {logUser && clienteSelect > 0 && usuarioQueAlterou !== '' ? (
                    <>
                      <DiasDesdeData
                        dataCriacao={dataAlteracao}
                        onDataFromCreation={handleDataFromCreation}
                      />
                      <small style={{ opacity: '0.5', fontSize: '10px' }}>
                        atualizado por: {usuarioQueAlterou}{' '}
                        {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                          ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                          : `às ${horarioDeAtualizacao}h`}
                      </small>
                    </>
                  ) : !logUser && clienteSelect > 0 ? (
                    <>
                      {' '}
                      {usuarioQueCriou === '' ? null : (
                        <small style={{ opacity: '0.5', fontSize: '10px' }}>
                          criado por: {usuarioQueCriou}{' '}
                          {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                            ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                            : `às ${horarioDeCriacao}h`}
                        </small>
                      )}
                    </>
                  ) : null}
                </Space>
              </div>
            }
          >
            <Tabs
              className='ant-tab-green'
              activeKey={tabSelected}
              onChange={handleTabSelect}
              size='small'
              type='card'
            >
              <TabPane key={1} tab='Dados do Cliente'>
                {tabSelecionada === '1' ? (
                  <CadCliente
                    permissions={permission}
                    refresh={refresh}
                    typeSubmit={typeSubmit}
                    codCliente={idCliente}
                    onclose={onclose}
                  />
                ) : null}
              </TabPane>
              <TabPane
                key={2}
                disabled={disableTab}
                tab='Representantes Legais/Composição societária'
              >
                {tabSelecionada === '2' ? (
                  <RepresentanteLegal
                    permissions={permission}
                    typeSubmit={typeSubmit}
                    codCliente={idCliente}
                  />
                ) : null}
              </TabPane>
              <TabPane disabled={process.env.NODE_ENV === 'production'} key={3} tab='Contratos'>
                {tabSelecionada === '3' ? <Contratos permissions={permission} /> : null}
              </TabPane>
              <TabPane forceRender tab='Módulos do cliente' tabKey='3'>
                <ClienteModulo statusCliente={statusCliente} codCliente={clienteSelect} />
              </TabPane>
            </Tabs>
          </Drawer>
        }
      >
        <Table
          scroll={{ y: 'calc(90vh - 335px)', x: 'auto' }}
          pagination={pagination}
          footer={() => (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'right',
              }}
            >
              <b>Total geral: {dataCliente.length}</b>
            </div>
          )}
          size='middle'
          bordered
          loading={loginTable}
          columns={colums}
          dataSource={dataTableRelative}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                handleClickCliente(record.cod, record)
              },
            }
          }}
        />
      </ViewPage>
    </Container>
  )
}
export default Cliente